import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrders,
  getOrdersOverTime,
  getProductsReturned,
  getProductsSold,
  getSales,
  getSalesByCustomers,
  getSalesByLocation,
  getSalesByProducts,
  getSalesOverTime,
} from '../../../services/apiFactory';

export const getSalesReport = createAsyncThunk(
  'sales-report/sales',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getSales({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getOrdersReport = createAsyncThunk(
  'sales-report/orders',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getOrders({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getProductsSoldReport = createAsyncThunk(
  'sales-report/products-sold',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getProductsSold({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getProductsReturnedReport = createAsyncThunk(
  'sales-report/products-returned',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getProductsReturned({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getSalesOverTimeReport = createAsyncThunk(
  'sales-report/sales-over-time',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getSalesOverTime({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getOrdersOverTimeReport = createAsyncThunk(
  'sales-report/orders-over-time',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getOrdersOverTime({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getSalesByProductsReport = createAsyncThunk(
  'sales-report/sales-by-products',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getSalesByProducts({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getSalesByCutomersReport = createAsyncThunk(
  'sales-report/sales-by-customers',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getSalesByCustomers({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getSalesByLocationReport = createAsyncThunk(
  'sales-report/sales-by-location',
  async ({ endDate, startDate }: { endDate: string; startDate: string }) => {
    return getSalesByLocation({ startDate, endDate })
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

interface Report {
  status: PromiseStatus;
  value: string | number;
  error: string;
}

interface ChartData {
  count: string;
  [key: string]: string;
}

interface ChartReport {
  status: PromiseStatus;
  value: ChartData[];
  error: string;
}

interface SalesReportState {
  report: {
    sales: Report;
    orders: Report;
    productsSold: Report;
    productReturned: Report;
    salesOverTime: ChartReport;
    ordersOverTime: ChartReport;
    salesByProducts: ChartReport;
    salesByCustomers: ChartReport;
    salesByLocation: ChartReport;
  };
}

const emptyReport: Report = {
  error: '',
  status: 'idle',
  value: 0,
};

const emptyChartReport: ChartReport = {
  error: '',
  status: 'idle',
  value: [],
};

const initialState: SalesReportState = {
  report: {
    sales: emptyReport,
    orders: emptyReport,
    productsSold: emptyReport,
    productReturned: emptyReport,
    salesOverTime: emptyChartReport,
    ordersOverTime: emptyChartReport,
    salesByProducts: emptyChartReport,
    salesByCustomers: emptyChartReport,
    salesByLocation: emptyChartReport,
  },
};

const salesReportSlice = createSlice({
  name: 'salesReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesReport.pending, (state) => {
        state.report.sales.status = 'loading';
      })
      .addCase(getSalesReport.fulfilled, (state, { payload }) => {
        state.report.sales.status = 'succeeded';
        state.report.sales.value = payload?.data?.sales || 0;
      })
      .addCase(getSalesReport.rejected, (state, { error }) => {
        state.report.sales.status = 'failed';
        state.report.sales.error =
          error.message || 'Error getting sales report';
      })
      .addCase(getOrdersReport.pending, (state) => {
        state.report.orders.status = 'loading';
      })
      .addCase(getOrdersReport.fulfilled, (state, { payload }) => {
        state.report.orders.status = 'succeeded';
        state.report.orders.value = payload?.data?.orders || 0;
      })
      .addCase(getOrdersReport.rejected, (state, { error }) => {
        state.report.orders.status = 'failed';
        state.report.orders.error =
          error.message || 'Error getting orders report';
      })
      .addCase(getProductsSoldReport.pending, (state) => {
        state.report.productsSold.status = 'loading';
      })
      .addCase(getProductsSoldReport.fulfilled, (state, { payload }) => {
        state.report.productsSold.status = 'succeeded';
        state.report.productsSold.value = payload?.data?.productsSold || 0;
      })
      .addCase(getProductsSoldReport.rejected, (state, { error }) => {
        state.report.productsSold.status = 'failed';
        state.report.productsSold.error =
          error.message || 'Error getting products sold report';
      })
      .addCase(getProductsReturnedReport.pending, (state) => {
        state.report.productReturned.status = 'loading';
      })
      .addCase(getProductsReturnedReport.fulfilled, (state, { payload }) => {
        state.report.productReturned.status = 'succeeded';
        state.report.productReturned.value = payload?.data?.productReturned
          ? payload?.data?.productReturned.length
          : 0;
      })
      .addCase(getProductsReturnedReport.rejected, (state, { error }) => {
        state.report.productReturned.status = 'failed';
        state.report.productReturned.error =
          error.message || 'Error getting products sold report';
      })
      .addCase(getSalesOverTimeReport.pending, (state) => {
        state.report.salesOverTime.status = 'loading';
      })
      .addCase(getSalesOverTimeReport.fulfilled, (state, { payload }) => {
        state.report.salesOverTime.status = 'succeeded';
        state.report.salesOverTime.value = payload?.data?.salesByMonth || [];
      })
      .addCase(getSalesOverTimeReport.rejected, (state, { error }) => {
        state.report.salesOverTime.status = 'failed';
        state.report.salesOverTime.error =
          error.message || 'Error getting sales over time report';
      })
      .addCase(getOrdersOverTimeReport.pending, (state) => {
        state.report.ordersOverTime.status = 'loading';
      })
      .addCase(getOrdersOverTimeReport.fulfilled, (state, { payload }) => {
        state.report.ordersOverTime.status = 'succeeded';
        state.report.ordersOverTime.value = payload?.data?.ordersByMonth || [];
      })
      .addCase(getOrdersOverTimeReport.rejected, (state, { error }) => {
        state.report.ordersOverTime.status = 'failed';
        state.report.ordersOverTime.error =
          error.message || 'Error getting orders over time report';
      })
      .addCase(getSalesByProductsReport.pending, (state) => {
        state.report.salesByProducts.status = 'loading';
      })
      .addCase(getSalesByProductsReport.fulfilled, (state, { payload }) => {
        state.report.salesByProducts.status = 'succeeded';
        state.report.salesByProducts.value = payload?.data?.salesProduct || [];
      })
      .addCase(getSalesByProductsReport.rejected, (state, { error }) => {
        state.report.salesByProducts.status = 'failed';
        state.report.salesByProducts.error =
          error.message || 'Error getting sales by products report';
      })
      .addCase(getSalesByCutomersReport.pending, (state) => {
        state.report.salesByCustomers.status = 'loading';
      })
      .addCase(getSalesByCutomersReport.fulfilled, (state, { payload }) => {
        state.report.salesByCustomers.status = 'succeeded';
        state.report.salesByCustomers.value = payload?.data || [];
      })
      .addCase(getSalesByCutomersReport.rejected, (state, { error }) => {
        state.report.salesByCustomers.status = 'failed';
        state.report.salesByCustomers.error =
          error.message || 'Error getting sales by customers report';
      })
      .addCase(getSalesByLocationReport.pending, (state) => {
        state.report.salesByLocation.status = 'loading';
      })
      .addCase(getSalesByLocationReport.fulfilled, (state, { payload }) => {
        state.report.salesByLocation.status = 'succeeded';
        state.report.salesByLocation.value = payload?.data || [];
      })
      .addCase(getSalesByLocationReport.rejected, (state, { error }) => {
        state.report.salesByLocation.status = 'failed';
        state.report.salesByLocation.error =
          error.message || 'Error getting sales by location report';
      });
  },
});

export default salesReportSlice.reducer;
