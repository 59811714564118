import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
  getResources,
  addAdmin,
  getOneStaff,
  deleteStoreStaff as DS,
  updateStoreStaff as US,
  getAllRoles,
  getAllAdmins,
  deactivateAdmin,
  deleteAdmin,
} from '../../../services/apiFactory';

export const fetchAllResources = createAsyncThunk(
  'superAdmin/fetchAllResources',
  async () => {
    return getResources()
      .then((res) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const fetchAllAdmins = createAsyncThunk(
  'superAdmin/fetchAllAdmins',
  async () => {
    return getAllAdmins()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const fetchAllRoles = createAsyncThunk(
  'superAdmin/fetchAllRoles',
  async () => {
    return getAllRoles()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const deleteStoreStaff = createAsyncThunk(
  'superAdmin/deleteStoreStaff',
  async (data: { id: number }) => {
    const { id } = data;
    return DS(id)
      .then((res) => {
        const data = res.data;

        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const fetchStaff = createAsyncThunk(
  'superAdmin/fetchStaff',
  async (data: { id: number }) => {
    const { id } = data;
    return getOneStaff(id)
      .then((res) => {
        const data = res.data;

        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const createAdmin = createAsyncThunk(
  'superAdmin/createAdmin',
  async (data: any) => {
    return addAdmin(data)
      .then((res) => {
        const data = res.data;
        toast.success('Admin successfully created');
        return data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        throw err.response.data;
      });
  }
);
export const updateAdmin = createAsyncThunk(
  'superAdmin/updateStoreStaff',
  async (data: { staffid: string; payload: object }) => {
    const { staffid, payload } = data;
    return US(staffid, payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log(err, 'error');
        toast.error('Something went wrong');
        throw err.response.data;
      });
  }
);
export const deactivateSuperAdmin = createAsyncThunk(
  'superAdmin/deactivateSuperAdmin',
  async (data: any) => {
    return deactivateAdmin(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log(err, 'error');
        toast.error('Something went wrong');
        throw err.response.data;
      });
  }
);

export const deleteAdmins = createAsyncThunk(
  'superAdmin/deleteAdmin',
  async (data: any) => {
    const res = await deleteAdmin(data);
    return res.data;
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Resource {
  id: number;
  name: string;
  access: string;
}

export interface SuperAdmin {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  createdAt: string;
  isActive: boolean;
  roleId: number;
}

interface ProfileState {
  status: PromiseStatus;
  resources: Resource[];
  admins: SuperAdmin[];
  admin: any;
  roles: any[];
}

const initialState: ProfileState = {
  status: 'idle',
  resources: [],
  admins: [],
  admin: {},
  roles: [],
};

const superAdminAdminsSlice = createSlice({
  name: 'storeStaff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllResources.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllResources.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.resources = data;
      })
      .addCase(fetchAllResources.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(createAdmin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAdmin.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(createAdmin.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchAllAdmins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllAdmins.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.admins = data;
      })
      .addCase(fetchAllAdmins.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStaff.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        // state.staff = data;
      })
      .addCase(fetchStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(deleteStoreStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStoreStaff.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(deleteStoreStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(updateAdmin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAdmin.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateAdmin.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(deactivateSuperAdmin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deactivateSuperAdmin.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(deactivateSuperAdmin.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(deleteAdmins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAdmins.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(deleteAdmins.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchAllRoles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.roles = data;
      })
      .addCase(fetchAllRoles.rejected, (state, { error }) => {
        state.status = 'failed';
      });
  },
});

export default superAdminAdminsSlice.reducer;
