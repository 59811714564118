import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFixedDiscountProducts } from '../../../services/apiFactory';

export const fetchFixedDiscountedProducts = createAsyncThunk(
  'products/fixedDiscount',
  async () => {
    return getFixedDiscountProducts()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  name: string;
  description: {
    head: string;
  };
  pricing: [
    {
      price: string;
      sellingPrice: string;
    }
  ];
  discountedPrice: string;
  hostedImages: string[];
  rating?: number;
}

interface FixedDiscountProductsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  discountedProducts: Product[];
}

const initialState: FixedDiscountProductsState = {
  status: 'idle',
  error: '',
  discountedProducts: [],
};

const fixedDiscountProductsSlice = createSlice({
  name: 'discountedProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFixedDiscountedProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFixedDiscountedProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload.data) {
          const { data } = payload;
          // Add a discountedPrice property to each product
          // const mappedProducts = data.map(
          //   (product: { pricing: any[] }, index: number) => {
          //     const singleProduct = {
          //       ...product,
          //       pricing: [
          //         {
          //           ...product.pricing[0],
          //           discountedPrice: priceObj?.discountedPrice[index],
          //         },
          //       ],
          //     };
          //     return singleProduct;
          //   }
          // );
          // console.log("mapped Products", mappedProducts);

          state.discountedProducts = data;
        } else {
          state.discountedProducts = [];
        }
      })
      .addCase(fetchFixedDiscountedProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default fixedDiscountProductsSlice.reducer;
