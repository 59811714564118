import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getChildCategory } from '../../../services/apiFactory';

export const getSingleChildCategory = createAsyncThunk(
  'childCategory',
  async (id: number) => {
    return getChildCategory({ id })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface ChildCategoryState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  childCategory: {
    id: number;
    name: string;
    subCategoryId: number;
    categoryId: number;
  };
}

const initialState: ChildCategoryState = {
  status: 'idle',
  error: '',
  childCategory: {
    id: 0,
    name: '',
    subCategoryId: 0,
    categoryId: 0,
  },
};

const childCategorySlice = createSlice({
  name: 'childCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleChildCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleChildCategory.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.childCategory = payload.data;
      })
      .addCase(getSingleChildCategory.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get child category. Try again later.';
      });
  },
});

export default childCategorySlice.reducer;
