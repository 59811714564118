import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerOrders } from '../../../../services/apiFactory';
import { OrderItem } from 'state/features/orders/merchantOrdersSlice';

export const getAllCustomerOrders = createAsyncThunk(
  'allOrders',
  async ({ status }: { status: string }) => {
    return getCustomerOrders({ status })
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Order {
  orderId: string;
  date: string;
  merchantId: number;
  merchantName: string;
  orderStatus: string;
  orderStatusId: number;
  total: string;
  orderItems: OrderItem[];
  shippingFee: string;
}

interface OrdersState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  orders: Order[];
}

const initialState: OrdersState = {
  status: 'idle',
  error: '',
  orders: [],
};

const customerOrdersSlice = createSlice({
  name: 'allCustomerOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomerOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCustomerOrders.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.orders = payload.data;
      })
      .addCase(getAllCustomerOrders.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default customerOrdersSlice.reducer;
