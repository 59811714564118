import { AxiosHost } from './axiosGlobal';

// Get state and code
export const getStates = () => {
  const url = `https://2q4mjoikdf.execute-api.eu-west-1.amazonaws.com/shipping/location`;
  return AxiosHost.get(url);
};
// Create merchant account
export const signUp = (payload) => {
  const url = `/auth/merchant/signup`;
  return AxiosHost.post(url, payload);
};

// CUSTOMER AUTH STARTS

// Customer Sign up
export const customerSignUp = (payload) => {
  const url = `/auth/customer/signup`;
  return AxiosHost.post(url, payload);
};

// Customer Login
export const customerLogin = (payload) => {
  const url = `/auth/customer/login`;
  return AxiosHost.post(url, payload);
};

// Verify Customer Email
export const verifyCustomerEmail = (payload) => {
  const url = `/auth/customer/confirmation/${payload}`;
  return AxiosHost.get(url);
};
// Admin Login
export const adminLogin = (payload) => {
  const url = `/auth/admin/login`;
  return AxiosHost.post(url, payload);
};
//shipping partner login
export const shippingPartnerLogin = (payload) => {
  const url = `/auth/partner/login`;
  return AxiosHost.post(url, payload);
};
//shipping partner signup
export const shippingPartnerSignup = (payload) => {
  const url = `/partner/partner/signup`;
  return AxiosHost.post(url, payload);
};

// CUSTOMER AUTH ENDS

// Get all categories
export const getCategories = () => {
  const url = `/categories/getallcategories`;
  return AxiosHost.get(url);
};
// Get top categories
export const getTopCategories = () => {
  const url = `/categories/getTopCategories`;
  return AxiosHost.get(url);
};
// Get children categories
export const getChildrenCategories = (id) => {
  const url = `/categories/getchildcategories/${id}`;
  return AxiosHost.get(url);
};

// Merchant signin
export const merchantSignIn = (payload) => {
  const url = `/auth/merchant/login`;
  return AxiosHost.post(url, payload);
};

export const merchantForgotPassword = (payload) => {
  const url = `/auth/merchant/forgotpassword`;
  return AxiosHost.post(url, payload);
};
export const customerForgotPassword = (payload) => {
  const url = `/auth/customer/forgotpassword`;
  return AxiosHost.post(url, payload);
};
export const adminForgotPassword = (payload) => {
  const url = `/auth/admin/forgotpassword`;
  return AxiosHost.post(url, payload);
};
export const shippingPartnerForgotPassword = (payload) => {
  const url = `/auth/partner/forgotpassword`;
  return AxiosHost.post(url, payload);
};

// Get User data
export const getUserData = () => {
  const url = `/auth/userdata`;
  return AxiosHost.get(url);
};

// Get Merchant Categoreis
export const getSubCategories = (payload) => {
  const url = `/categories/getonecategory/${payload}`;
  return AxiosHost.get(url);
};

// Merchant on boarding
export const onBoarding = (payload) => {
  const url = `/auth/merchant/onboard`;
  return AxiosHost.put(url, payload);
};

export const getOnBoardingInfo = () => {
  const url = `/auth/userdata/merchant-profile`;
  return AxiosHost.get(url);
};

// Verify Merchant Email
export const verifyEmail = (payload) => {
  const url = `/auth/merchant/confirmation/${payload}`;
  return AxiosHost.get(url);
};

// Merchant Bank
export const addMerchantBank = (payload) => {
  const url = '/bank/add-bank';
  return AxiosHost.post(url, payload);
};

export const updateMerchantBank = (payload) => {
  const url = '/bank/update-bank';
  return AxiosHost.put(url, payload);
};

// Merchant discount
// Get coupons
export const getCoupons = () => {
  const url = `/coupon/getallcoupons`;
  return AxiosHost.get(url);
};

// Create coupons
export const createCoupon = (payload) => {
  const url = `/coupon/create-coupon`;
  return AxiosHost.post(url, payload);
};

// Get one coupon
export const getOneCoupon = (id) => {
  const url = `/coupon/getonecoupon/${id}`;
  return AxiosHost.get(url);
};

// Update one coupon
export const updateOneCoupon = (payload) => {
  const url = `/coupon/updatecoupon/${payload.id}`;
  return AxiosHost.patch(url, payload.couponData);
};

// Merchant Product
// Get Producsts
export const getProducts = () => {
  const url = `/products`;
  return AxiosHost.get(url);
};

export const getProduct = (payload) => {
  const url = `/products/customer/product/${payload.id}`;
  return AxiosHost.get(url);
};

export const getFixedDiscountProducts = () => {
  const url = `/products/fixed-discount`;
  return AxiosHost.get(url);
};

export const getDailyHotDeals = () => {
  const url = `/products/daily-product`;
  return AxiosHost.get(url);
};

export const getRandomProducts = () => {
  const url = `/products/random-products-category`;
  return AxiosHost.get(url);
};

export const addProduct = (payload) => {
  const url = `/products`;
  return AxiosHost.post(url, payload);
};

export const adminAddProduct = (payload) => {
  const url = `/products/admin/add-product`;
  return AxiosHost.post(url, payload);
};

export const getSingleProduct = (id) => {
  const url = `/products/${id}`;
  return AxiosHost.get(url);
};

export const editProduct = (payload, id) => {
  const url = `/products/${id}`;
  return AxiosHost.patch(url, payload);
};

export const getTopMerchantsProduct = () => {
  const url = `/products/customer/marketplace/products`;
  return AxiosHost.get(url);
};

// Merchant Featured Products
export const getMerchantFeaturedProducts = (id) => {
  const url = `/products/getmertchant/marketplace/products/${id}`;
  return AxiosHost.get(url);
};

export const getMerchantStoreFeaturedProducts = (id) => {
  const url = `/products/getmertchant/products/themesapi/${id}`;
  return AxiosHost.get(url);
};

export const getMerchantProducts = (id) => {
  const url = `/products/getmerchant/store/products/${id}`;
  return AxiosHost.get(url);
};
export const addMerchantFeaturedProducts = (payload) => {
  const url = `/products/addmarketplaceproduct`;
  return AxiosHost.post(url, payload);
};
export const deleteMerchantFeaturedProducts = (id) => {
  const url = `/products/deletemerchantplaceproduct/${id}`;
  return AxiosHost.delete(url);
};

// Merchant Store Profile
export const getMerchantStoreProfile = (id) => {
  const url = `/storeprofile/${id}`;
  return AxiosHost.get(url);
};
export const addMerchantStoreProfile = (payload) => {
  const url = '/storeprofile/add-storeProfile';
  return AxiosHost.post(url, payload);
};
export const getResources = () => {
  const url = '/resources/get-all-resource';
  return AxiosHost.get(url);
};
export const getAllRoles = () => {
  const url = '/roles/getallroles';
  return AxiosHost.get(url);
};
export const addStoreStaff = (payload) => {
  const url = '/storestaff/create-staff';
  return AxiosHost.post(url, payload);
};
export const getAllStaff = () => {
  const url = '/storestaff/getall-staff';
  return AxiosHost.get(url);
};
export const getOneStaff = (id) => {
  const url = `/storestaff/getone-staff/${id}`;
  return AxiosHost.get(url);
};
export const deleteStoreStaff = (id) => {
  const url = `/storestaff/delete-staff/${id}`;
  return AxiosHost.delete(url);
};
export const updateStoreStaff = (id, payload) => {
  const url = `/storestaff/update-staff/${id}`;
  return AxiosHost.patch(url, payload);
};

export const addAdmin = (payload) => {
  const url = `/auth/admin/create`;
  return AxiosHost.post(url, payload);
};
export const getAllAdmins = () => {
  const url = `/auth/admin/all-admins`;
  return AxiosHost.get(url);
};
export const deactivateAdmin = (payload) => {
  const url = `/auth/admin/deactivate`;
  return AxiosHost.post(url, payload);
};
export const deleteAdmin = (payload) => {
  const url = `/auth/admin/delete`;
  return AxiosHost.delete(url, { data: payload });
};

// Update Product
export const updateProduct = (id, payload) => {
  const url = `/products/${id}`;
  return AxiosHost.patch(url, payload);
};

export const applyCouponCode = (payload) => {
  const url = `/applycoupon/apply-coupon`;
  return AxiosHost.post(url, payload);
};

export const getAllCouponCode = (payload) => {
  const url = `/coupon/getallcoupons/customers`;
  return AxiosHost.post(url, payload);
};

export const addToCart = (payload) => {
  const url = `/cart/add-cart`;
  return AxiosHost.post(url, payload);
};

export const addShippingAddress = (payload) => {
  const url = `/customershippingaddress/add-customer-address`;
  return AxiosHost.post(url, payload);
};
export const deleteShippingAddress = (id) => {
  const url = `/customershippingaddress/delete-customer-address/${id}`;
  return AxiosHost.delete(url);
};
export const createTransaction = (payload) => {
  const url = `/transactions/customer/transaction`;
  return AxiosHost.post(url, payload);
};

export const getShippingAddresses = (payload) => {
  const url = `/customershippingaddress`;
  return AxiosHost.get(url);
};
export const getCustomerShippingDetails = (id) => {
  const url = `/customershippingaddress/${id}`;
  return AxiosHost.get(url);
};

export const getCustomerSingleOrderShippingDetails = (id) => {
  const url = `/customershippingaddress/dispatcher/${id}`;
  return AxiosHost.get(url);
};

export const getCustomerOrderShippingAddress = (
  customerId,
  deliveryAddressId
) => {
  const url = `/customershippingaddress/admin/get-customer-address?customerId=${customerId}&deliveryAddressId=${deliveryAddressId}`;
  return AxiosHost.get(url);
};

export const getMerchantShippingDetails = (id) => {
  const url = `/merchantshippingaddress/${id}`;
  return AxiosHost.get(url);
};
export const initializeOrderReturns = (id, payload) => {
  const url = `/returns/return-order/${id}`;
  return AxiosHost.post(url, payload);
};
export const initializeOrderReplacement = (id, payload) => {
  const url = `/returns/replace-order/${id}`;
  return AxiosHost.post(url, payload);
};

export const getAllOrderReturns = (query) => {
  const url = `/returns/all-order-returns?refund=${query}`;
  return AxiosHost.get(url);
};
export const updateOrderRefundDecision = (id, payload) => {
  const url = `/returns/update-refund-decision/${id}`;
  return AxiosHost.patch(url, payload);
};
export const updateOrderChargebackDecision = (id, payload) => {
  const url = `/returns/update-chargeback-decision/${id}`;
  return AxiosHost.patch(url, payload);
};
export const setDefaultShippingAddress = (id, payload) => {
  const url = `/customershippingaddress/update-customer-addres/${id}`;
  return AxiosHost.patch(url, payload);
};
export const getCartItems = (payload) => {
  const url = `/cart/get-cart`;
  return AxiosHost.get(url, payload);
};
export const deleteCartItem = (payload) => {
  const url = `/cart/remove-cart`;
  return AxiosHost.delete(url, { data: payload });
};
export const makePayment = (payload) => {
  const url = `/v1/products/payment`;
  return AxiosHost.post(url, payload);
};

export const getMerchantOrders = () => {
  const url = `/ordertracking/merchant/view-orders`;
  return AxiosHost.get(url);
};
export const getMerchantReturnOrders = (id) => {
  const url = `/returns/get-one-order-returns/${id}`;
  return AxiosHost.get(url);
};

export const getOrderStatuses = (query) => {
  const url = `/orders/order-status?orderType=${query}`;
  return AxiosHost.get(url);
};
export const assignOrder = (id) => {
  const url = `/ordertracking/merchant/assigntobitmarte/${id}`;
  return AxiosHost.patch(url);
};
export const updateOrderStatus = (id, payload) => {
  const url = `/ordertracking/update-order/${id}`;
  return AxiosHost.post(url, payload);
};

export const updateDispatcherNumber = (id, payload) => {
  const url = `/ordertracking/merchant/dispatch-phone/${id}`;
  return AxiosHost.patch(url, payload);
};
export const updateReturnOrderStatus = (id, payload) => {
  const url = `/returns/update-return-status/${id}`;
  return AxiosHost.patch(url, payload);
};
export const getMerchantOrder = (id) => {
  const url = `/ordertracking/merchant/view-orders/${id}`;
  return AxiosHost.get(url);
};

export const placeOrder = (payload) => {
  const url = `/ordertracking/create-order`;
  return AxiosHost.post(url, payload);
};

export const createNewCustomerOrder = (payload) => {
  const url = `/ordertracking/simulate-order`;
  return AxiosHost.post(url, payload);
};
export const generateShippingFee = (payload) => {
  const url = `/generateshippingfee/generate-shippingfee`;
  return AxiosHost.post(url, payload);
};

// export const getAdminOrders = () => {
//   const url = `/ordertracking/admin/view-orders`;
//   return AxiosHost.get(url);
// };

export const getAdminOrders = () => {
  const url = `/ordertracking/admin/view-all-orders`;
  return AxiosHost.get(url);
};

export const getAdminBitMarteHubOrders = () => {
  const url = `orderTracking/admin/bitmartehub-orders`;
  return AxiosHost.get(url);
};

export const getAdminTransactions = () => {
  const url = `/transactions/admin/gettransactions`;
  return AxiosHost.get(url);
};
export const getAdminTransaction = (id) => {
  const url = `/transactions/admin/getonetransaction/${id}`;
  return AxiosHost.get(url);
};

export const getMerchantTransactions = () => {
  const url = `/transactions/merchant/transactions`;
  return AxiosHost.get(url);
};
export const getMerchantTransaction = (id) => {
  const url = `/transactions/merchant/gettransaction/${id}`;
  return AxiosHost.get(url);
};

//Get Merchants Store

export const getAllMerchantsStore = () => {
  const url = `/auth/userdata/getallmerchants`;
  return AxiosHost.get(url);
};
export const getSingleMerchantStore = (id) => {
  const url = `/auth/userdata/merchant-store-data/${id}`;
  return AxiosHost.get(url);
};

export const addCustomerAddress = (payload) => {
  const url = `/customershippingaddress/add-customer-address`;
  return AxiosHost.post(url, payload);
};

export const updateCustomerAddress = (id, payload) => {
  const url = `/customershippingaddress/update-customer-address/${id}`;
  return AxiosHost.patch(url, payload);
};

export const setCustomerDefaultShippingAddress = (id) => {
  const url = `/customershippingaddress/make-default--address/${id}`;
  return AxiosHost.patch(url, { default: true });
};

export const getShippingPartners = () => {
  const url = `/partner/partner/all`;
  return AxiosHost.get(url);
};
export const getAminOrder = (id) => {
  const url = `/ordertracking/admin/view-orders/${id}`;
  return AxiosHost.get(url);
};
export const getDispatcherOrder = (id) => {
  const url = `/ordertracking/dispatcher/view-orders/${id}`;
  return AxiosHost.get(url);
};

export const getShippingPartnerOrder = (id) => {
  const url = `/partner/partner/view-on-order/${id}`;
  return AxiosHost.get(url);
};
export const declineShippingPartnerOrder = (id) => {
  const url = `/partner/partner/decline-order/${id}`;
  return AxiosHost.patch(url);
};
export const updateShippingPartnerOrderStatus = (id, payload) => {
  const url = `/ordertracking/update-order/${id}`;
  return AxiosHost.post(url, payload);
};

export const adminAssignOrder = (payload) => {
  const url = `/partner/partner/assignorder`;
  return AxiosHost.post(url, payload);
};

// Shipping partner orders

// get shipping partner orders
export const getShippingPartnerOrders = () => {
  const url = `/partner/partner/view-orders`;
  return AxiosHost.get(url);
};

//Onboard shipping partner
export const onboardShippingPartner = (payload) => {
  const url = `/partner/partner/onboard/`;
  return AxiosHost.post(url, payload);
};

// Get Merchant Products
export const getMerchantSearchProducts = ({ search }) => {
  const url = `/products/?search=${search}`;
  console.log(url);
  return AxiosHost.get(url);
};

// Get customer products
export const getCustomerProducts = ({ offset, limit, search }) => {
  const url = `/products/customer/getallproducts?limit=${limit}&offset=${offset}${
    search ? `&search=${search}` : ''
  }`;
  return AxiosHost.get(url);
};
export const getChildCategoryProducts = ({
  offset,
  limit,
  childCategoryId,
}) => {
  const url = `/products/customer/getallproducts?limit=${limit}&offset=${offset}&childCategoryId=${childCategoryId}`;
  return AxiosHost.get(url);
};
export const getSubCategoryProducts = ({ offset, limit, subCategoryId }) => {
  const url = `/products/customer/getallproducts?limit=${limit}&offset=${offset}&subCategoryId=${subCategoryId}`;
  return AxiosHost.get(url);
};
export const getCategoryProducts = ({ offset, limit, categoryId }) => {
  const url = `/products/customer/getallproducts?limit=${limit}&offset=${offset}&categoryId=${categoryId}`;
  return AxiosHost.get(url);
};

// Get single product details

export const getSingleProductDetails = (id) => {
  const url = `/products/customer/product/${id}`;
  return AxiosHost.get(url);
};
export const getSingleProductReviews = (id) => {
  const url = `/reviews/${id}`;
  return AxiosHost.get(url);
};
export const getMerchantPolicy = (id) => {
  const url = `/merchantPolicy/getstore-policy/${id}`;
  return AxiosHost.get(url);
};

export const getFollowers = (id) => {
  const url = `/followstore/followers/${id}`;
  return AxiosHost.get(url);
};
export const followMerchant = (id) => {
  const url = `/followstore?storeId=${id} `;
  return AxiosHost.post(url);
};
export const getFollowing = (id) => {
  const url = `/followstore?storeId=${id} `;
  return AxiosHost.get(url);
};
// Admin products
export const getAdminProducts = ({ offset, limit, query }) => {
  const url = `/products/admin/getallproducts?search=${query}`;
  return AxiosHost.get(url);
};
export const getPermissions = () => {
  const url = `/auth/permissions`;
  return AxiosHost.get(url);
};

export const getAdminSingleProduct = (id) => {
  const url = `products/admin-getone-product/${id}`;
  return AxiosHost.get(url);
};
export const approvePayment = (id) => {
  const url = `/ordertracking/admin/view-orders/${id}`;
  return AxiosHost.get(url);
};

export const approveProduct = ({ productId, merchantId, payload }) => {
  const url = `/products/approve-product?productId=${productId}&merchantId=${merchantId}`;
  return AxiosHost.post(url, payload);
};

export const getCustomerOrders = ({ status }) => {
  const url = `ordertracking/orders?status=${status}`;
  return AxiosHost.get(url);
};

export const getCustomerOrder = ({ id }) => {
  const url = `ordertracking/orders/${id}`;
  return AxiosHost.get(url);
};

export const getCustomerOrderMerchantPolicy = (storeId) => {
  const url = `merchantPolicy/getstore-policy/${storeId}`;
  return AxiosHost.get(url);
};

export const getCustomerName = () => {
  const url = `auth/userData`;
  return AxiosHost.get(url);
};

export const getCustomerOrderItem = ({ id }) => {
  const url = `products/customer/product/${id}`;
  return AxiosHost.get(url);
};

export const getPricing = () => {
  const url = `subscriptions/all`;
  return AxiosHost.get(url);
};

export const getSubscriptionInfo = () => {
  const url = `subscriptions/merchant/get-subscription`;
  return AxiosHost.get(url);
};
export const getTransactionInvoice = (storeId) => {
  const url = `transactions/merchant/invoices/${storeId}`;
  return AxiosHost.get(url);
};

export const getChildCategory = ({ id }) => {
  const url = `childcategories/${id}`;
  return AxiosHost.get(url);
};

export const getSubCategory = ({ id }) => {
  const url = `categories/getonesubcategory/${id}`;
  return AxiosHost.get(url);
};

export const getAllMerchantPoliciesFunc = (id) => {
  const url = `merchantPolicy/get-store/${id}`;
  console.log(url);
  return AxiosHost.get(url);
};

export const createNewMerchantPolicyFunc = (payload) => {
  const url = `merchantpolicy/add-policy`;
  return AxiosHost.post(url, payload);
};

export const updateMerchantPolicyFunc = (id, payload) => {
  const url = `merchantpolicy/update-policy/${id}`;
  return AxiosHost.patch(url, payload);
};

// Sales Report
export const getProductsSold = ({ startDate, endDate }) => {
  const url = `/sales/product-sold/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getOrders = ({ startDate, endDate }) => {
  const url = `/sales/orders/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getSales = ({ startDate, endDate }) => {
  const url = `/sales/sales/${startDate}/${endDate}`;
  return AxiosHost.get(url);
};

export const getProductsReturned = ({ startDate, endDate }) => {
  const url = `/sales/returned-products/${startDate}/${endDate}`;
  return AxiosHost.get(url);
};

export const getSalesOverTime = ({ startDate, endDate }) => {
  const url = `/sales/sales-overTime/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getOrdersOverTime = ({ startDate, endDate }) => {
  const url = `/sales/order-overTime/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getSalesByProducts = ({ startDate, endDate }) => {
  const url = `/sales/sales-product/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getSalesByCustomers = ({ startDate, endDate }) => {
  const url = `/sales/sales-by-customer/startDate=${startDate}/endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const getSalesByLocation = ({ startDate, endDate }) => {
  const url = `/sales/sales-by-location/?startDate=${startDate}&endDate=${endDate}`;
  return AxiosHost.get(url);
};

export const createMerchantTrackerFunc = (payload) => {
  const url = `/subscriptions/create-merchant-tracker`;
  return AxiosHost.post(url, payload);
};

// SCHEDULER ORDERS
export const getSchedulerOrdersFunc = () => {
  const url = `/ordertracking/admin/view-orders`;
  return AxiosHost.get(url);
};

export const assignOrderToDispatcherFunc = (payload) => {
  const url = `/orderassignment/scheduler/assignorder`;
  return AxiosHost.post(url, payload);
};

export const getAllDispatchersFunc = () => {
  const url = `/auth/userdata/getalldispatchers`;
  return AxiosHost.get(url);
};

export const searchSchedulerOrdersFunc = ({ search }) => {
  const url = `/ordertracking/search-orders?search=${search}`;
  return AxiosHost.get(url);
};

export const searchDispatcherOrdersFunc = ({ search }) => {
  const url = `/ordertracking/dispatcher/view-orders?search=${search}`;
  return AxiosHost.get(url);
};

// DISPATCHER ORDERS
export const getDispatcherOrdersFunc = () => {
  const url = `/ordertracking/dispatcher/view-orders`;
  return AxiosHost.get(url);
};

export const getAllShippingPartnersFunc = () => {
  const url = `/auth/userdata/getallshippingpartners`;
  return AxiosHost.get(url);
};

export const getAllShippingPartnersOnboarded = (status) => {
  const url = `/auth/userdata/getallshippingpartners?onboarded=${status}`;
  return AxiosHost.get(url);
};

export const addNewShippingPartnerFunc = (payload) => {
  const url = `/auth/admin/create-partner`;
  return AxiosHost.post(url, payload);
};

export const assignOrderToPartnerFunc = (payload) => {
  // const url = `/partner/partner/assignorder`;
  const url = `/orderassignment/dispatcher/assignorder`;
  return AxiosHost.post(url, payload);
};

// Admin Stores
export const getUnapprovedStores = () => {
  const url = `/storeapproval/admin/get-stores`;
  return AxiosHost.get(url);
};

export const getApprovedStores = () => {
  const url = `/storeapproval/admin/get-approved-stores`;
  return AxiosHost.get(url);
};

export const getStoreDetails = (id) => {
  const url = `/storeapproval/admin/get-one-store/${id}`;
  return AxiosHost.get(url);
};

export const approveStore = (id) => {
  const url = `/storeapproval/admin/approve-store/${id}`;
  return AxiosHost.patch(url);
};

export const updateStorePaymentCategory = (id, payload) => {
  const url = `storeapproval/admin/merchant-payment-category/${id}`;
  return AxiosHost.patch(url, payload);
};

// Hubs
export const getHubs = () => {
  const url = '/bitmartehub/get-all-hubs';
  return AxiosHost.get(url);
};

export const getBitmarteApprovedHubs = () => {
  const url = '/storeprofile/approved-hub-stores';
  return AxiosHost.get(url);
};

export const getHubApplications = () => {
  const url = '/storeprofile/hub-stores';
  return AxiosHost.get(url);
};

export const approveHubApplication = (payload) => {
  const url = '/storeprofile/approve-hub';
  return AxiosHost.patch(url, payload);
};

// Admin Sales Acivity Report
export const getSalesActivityReport = () => {
  const url = `/financeOperations/sar`;
  return AxiosHost.get(url);
};

// Admin Next Day Settlement Report
export const getNextDaySettlementReport = () => {
  const url = `/financeOperations/ndsr`;
  return AxiosHost.get(url);
};

// CUSTOMER RETURNS ORDER
export const initiateCustomerReturnOrderFunc = (payload) => {
  const url = `/ordertracking/create-return`;
  return AxiosHost.post(url, payload);
};

// GET CUSTOMER STORES UNAUTHENTICATED
export const getAllCustomerStoresUnauthenticatedFunc = () => {
  const url = `store/customer/all-stores?store=subscriptions`;
  return AxiosHost.get(url);
};

// GET MERCHANT PAYMENT REPORT
export const getMerchantPaymentReportFunc = () => {
  const url = `/merchantReports/merchant/get-payments`;
  return AxiosHost.get(url);
};
// OFFICIAL OUTLETS
export const getOfficialOutletsProducts = () => {
  const url = `products/customer/official-outlets`;
  return AxiosHost.get(url);
};

// CREATE SHIPPING PARTNER

// STORE APPROVERS
export const getStoreApprovers = () => {
  const url = `storeapproval/admin/get-hubstore-approvers`;
  return AxiosHost.get(url);
};

export const createStoreApprover = (payload) => {
  const url = `storeapproval/admin/create-approver`;
  return AxiosHost.post(url, payload);
};

export const updateStoreApprover = (payload) => {
  const url = `storeapproval/admin/edit-approver`;
  return AxiosHost.post(url, payload);
};

// ADMINS
export const getAdmins = () => {
  const url = `auth/admin/all-admins`;
  return AxiosHost.get(url);
};

// MERCHANT STORE PROFILE
export const getMerchantStoreProfileDetails = () => {
  const url = `auth/userdata/merchant-profile`;
  return AxiosHost.get(url);
};

// Admin Merchant Payment Reports
export const getAdminMerchantPayments = () => {
  const url = `/merchantReports/payments`;
  return AxiosHost.get(url);
};
export const updateAdminMerchantPayment = (payload) => {
  const url = `/merchantReports/create-report`;
  return AxiosHost.patch(url, payload);
};
export const getAdminMerchantsStats = () => {
  const url = `/merchantReports/admin/get-payment-stat`;
  return AxiosHost.get(url);
};

// CHANGE PASSWORD

export const changePassword = (payload) => {
  const url = `auth/user/change-password`;
  return AxiosHost.post(url, payload);
};

// RESET PASSWORD
export const resetPassword = (userType, token, payload) => {
  const url = `/auth/${userType}/resetpassword/${token}`;
  return AxiosHost.patch(url, payload);
};

// CREATE MERCHANT REVIEW
export const createMerchantReviewFunc = (payload) => {
  const url = `reviews/merchant`;
  return AxiosHost.post(url, payload);
};

// CREATE MERCHANT REVIEW
export const createProductReviewFunc = (payload) => {
  const url = `reviews`;
  return AxiosHost.post(url, payload);
};

// CREATE MERCHANT  TESTIMONIAL
export const createMerchantTestimonialFunc = (payload) => {
  const url = `testimonial`;
  return AxiosHost.post(url, payload);
};

// GET MERCHANT TESTIMONIAL
export const getMerchantTestimonialFunc = () => {
  const url = `testimonial`;
  return AxiosHost.get(url);
};

export const updateTestimonialFunc = (id, payload) => {
  const url = `testimonial/${id}`;
  return AxiosHost.patch(url, payload);
};

export const convertTokenFunc = (orderId, payload) => {
  const url = `/ordertracking/update-token?orderId=${orderId}`;
  return AxiosHost.post(url, payload);
};

export const contactUsFunc = (payload) => {
  const url = `/contact-us/contact-us`;
  return AxiosHost.post(url, payload);
};

// FINANCE REPORTS
export const getONDPRD = () => {
  const url = `/financeOperations/ondpr`;
  return AxiosHost.get(url);
};

export const getODPRD = () => {
  const url = `/financeOperations/odpr`;
  return AxiosHost.get(url);
};

export const getOrderShippingAddress = (id) => {
  const url = `/customershippingaddress/merchant/get-customer-address/${id}`;
  return AxiosHost.get(url);
};
