import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDailyHotDeals } from '../../../services/apiFactory';

export const fetchDailyHotDeals = createAsyncThunk(
  'products/dailyHotDeals',
  async () => {
    return getDailyHotDeals()
      .then((res) => {
        const data = res.data;
        console.log('Hot Deals', data);

        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  productId: number;
  merchantId: number;
  productName: string;
  description: [
    {
      children: [
        {
          text: string;
        }
      ];
    }
  ];
  pricing: [
    {
      price: string;
      costPrice: string;
      sellingPrice: string;
    }
  ];
  discountValue: string;
  fixedCouponId: string;
  endDate: string;
  couponId: number;
  discountType: 'Fixed' | 'Percentage';
  discountedPrice: string;
  hostedImages: string[];
  sku: string;
}

interface DailyHotDealsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  fixedCouponProducts: Product[];
}

const initialState: DailyHotDealsState = {
  status: 'idle',
  error: '',
  fixedCouponProducts: [],
};

const dailyHotDealsSlice = createSlice({
  name: 'dailyHotDeals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyHotDeals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDailyHotDeals.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload.data) {
          const { data } = payload;

          state.fixedCouponProducts = data?.fixedCouponProducts;
        } else {
          state.fixedCouponProducts = [];
        }
      })
      .addCase(fetchDailyHotDeals.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get daily deals. Try again later.';
      });
  },
});

export default dailyHotDealsSlice.reducer;
