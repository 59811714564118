import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { assignOrderToPartnerFunc } from 'services/apiFactory';

export const assignOrderToPartner = createAsyncThunk(
  'assign/orders/to/partner',
  async (payload: object) => {
    return assignOrderToPartnerFunc(payload)
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

const initialState = {
  status: 'idle',
  message: '',
  error: true,
};

const assignOrderToPartnerSlice = createSlice({
  name: 'assignOrderToPartnerData',
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignOrderToPartner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(assignOrderToPartner.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.status = 'succeeded';
          state.message = payload?.message;
          state.error = false;
        } else {
          state.error = true;
          state.status = 'failed';
          state.message = payload;
        }
      })
      .addCase(assignOrderToPartner.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message = 'Check network connection';
        state.error = true;
      });
  },
});

export const { setStatus } = assignOrderToPartnerSlice.actions;
export default assignOrderToPartnerSlice.reducer;
