import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createStoreApprover,
  getStoreApprovers,
  updateStoreApprover,
} from '../../../services/apiFactory';

export const fetchStoreApprovers = createAsyncThunk(
  'store-approvers/get',
  async () => {
    return getStoreApprovers()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const addStoreApprover = createAsyncThunk(
  'store-approvers/create',
  async (data: {
    adminId: number;
    isFirstApprover: boolean;
    isSecondApprover: boolean;
  }) => {
    return createStoreApprover(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const editStoreApprover = createAsyncThunk(
  'store-approvers/edit',
  async (data: { oldAdmin: number; newAdmin: number }) => {
    return updateStoreApprover(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface StoreApprover {
  id: number;
  adminId: number;
  Admin: {
    id: number;
    firstName: string;
    lastName: string;
    role: string;
  };
  isFirstApprover: boolean;
  isSecondApprover: boolean;
  createdAt: string;
  updatedAt: string;
}

interface EditStoreApprover {
  oldAdmin: number;
  newAdmin: number;
}
interface ProfileState {
  status: PromiseStatus;
  message: string;
  approvers: StoreApprover[];
  createApproverStatus: PromiseStatus;
  createApproverMessage: string;
  approversEditable: EditStoreApprover;
}

const initialState: ProfileState = {
  status: 'idle',
  message: '',
  approvers: [],
  createApproverStatus: 'idle',
  createApproverMessage: '',
  approversEditable: {} as EditStoreApprover,
};

const storeApproversSlice = createSlice({
  name: 'storeApprovers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreApprovers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStoreApprovers.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.approvers = payload?.data || [];
        state.message = 'Successfully fethced store approvers.';
      })
      .addCase(fetchStoreApprovers.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(addStoreApprover.pending, (state) => {
        state.createApproverStatus = 'loading';
      })
      .addCase(addStoreApprover.fulfilled, (state) => {
        state.createApproverStatus = 'succeeded';
        state.createApproverMessage = 'Successfully created approver.';
      })
      .addCase(addStoreApprover.rejected, (state, { error }) => {
        state.createApproverStatus = 'failed';
        state.createApproverMessage =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(editStoreApprover.pending, (state) => {
        state.createApproverStatus = 'loading';
      })
      .addCase(editStoreApprover.fulfilled, (state) => {
        state.createApproverStatus = 'succeeded';
        state.createApproverMessage = 'Successfully updated approver.';
      })
      .addCase(editStoreApprover.rejected, (state, { error }) => {
        state.createApproverStatus = 'failed';
        state.createApproverMessage =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export default storeApproversSlice.reducer;
