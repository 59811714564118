import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerProducts } from '../../../services/apiFactory';

export const fetchCustomerProducts = createAsyncThunk(
  'product/customerProducts',
  async ({
    limit,
    offset,
    search,
  }: {
    limit: number;
    offset: number;
    search?: string;
  }) => {
    return getCustomerProducts({ limit, offset, search })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  productId?: number;
  name?: string;
  productName?: string;
  description: {
    head: string;
  };
  pricing: [
    {
      price: string;
      sellingPrice: string;
    }
  ];
  discountedPrice?: string;
  discountValue?: string;
  hostedImages: string[];
  rating?: number;
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  count: number;
  products: Product[];
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  count: 0,
  products: [],
};

const customerProductsSlice = createSlice({
  name: 'customerProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload.data.data) {
          state.products = payload.data.data;
          state.count = payload.data.count;
        } else {
          state.products = [];
          state.count = 0;
        }
      })
      .addCase(fetchCustomerProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default customerProductsSlice.reducer;
