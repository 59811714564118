import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  approveHubApplication,
  getHubApplications,
} from '../../../services/apiFactory';

export const fetchBitmartHubApplications = createAsyncThunk(
  'hubs-application/get',
  async () => {
    return getHubApplications()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const approveBitmartHubApplication = createAsyncThunk(
  'hubs-application/approve',
  async (payload: { storeId: number }) => {
    return approveHubApplication(payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

interface ShippingLocation {
  state: string;
  street: string;
  country: string;
}

export interface HubApplication {
  id: 5;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo?: string;
  storePhoneNumber?: string;
  isRegisteredBusiness: boolean;
  merchant?: {
    id: number;
    email: string;
  };
  shippingLocation?: ShippingLocation[] | ShippingLocation;
  proofOfAddress?: string;
  isPhysicalLocation?: boolean;
  firstApproval: boolean;
  secondApproval: boolean;
  reasonForRejection: string;
  useBitmarteHub: boolean;
  bitmarteHubId: string;
  createdAt: string;
  updatedAt: string;
}

interface ProfileState {
  status: PromiseStatus;
  approvalStatus: PromiseStatus;
  applications: HubApplication[];
  message: string;
  approvalMessage: string;
}

const initialState: ProfileState = {
  status: 'idle',
  approvalStatus: 'idle',
  applications: [],
  message: '',
  approvalMessage: '',
};

const hubsSlice = createSlice({
  name: 'hubApplications',
  initialState,
  reducers: {
    setApprovalMessage: (state, { payload }) => {
      state.approvalMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBitmartHubApplications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBitmartHubApplications.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.applications = payload?.data?.rows || [];
        state.message = 'Successfully fethced bitmarte hubs.';
      })
      .addCase(fetchBitmartHubApplications.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(approveBitmartHubApplication.pending, (state) => {
        state.approvalStatus = 'loading';
      })
      .addCase(approveBitmartHubApplication.fulfilled, (state, { payload }) => {
        console.log({ payload });

        if (payload?.success) {
          state.approvalStatus = 'succeeded';
          state.approvalMessage =
            payload.message || 'Successfully approved bitmarte hub.';
        } else {
          state.approvalStatus = 'failed';
          state.approvalMessage =
            payload.message || 'An Error occured. Please try again later.';
        }

        // Remove the approved store from the list of pending applications
        if (payload?.data?.id) {
          const applications = [...state.applications];
          const approvedApplicationIndex = applications.findIndex(
            (item) => item.id === payload.data.id
          );

          if (approvedApplicationIndex >= 0) {
            applications.splice(approvedApplicationIndex, 1);
            state.applications = applications;
          }
        }
      })
      .addCase(approveBitmartHubApplication.rejected, (state, { error }) => {
        state.approvalStatus = 'failed';
        state.approvalMessage =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export const { setApprovalMessage } = hubsSlice.actions;
export default hubsSlice.reducer;
