import { createSlice } from '@reduxjs/toolkit';
import { shippingApiSlice } from '../api/shippingApiSlice';
import { Zones } from './types';

export const {
  endpoints,
  useReadDiscountsQuery,
  useReadDiscountQuery,
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
} = shippingApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    readDiscounts: builder.query({
      query: () => '/discount',
    }),
    readDiscount: builder.query({
      query: (id: string) => `/discount/${id}`,
    }),
    createDiscount: builder.mutation({
      query: (body: {
        name: string;
        zones: Zones[];
        weights: number[];
        value: number;
      }) => ({
        url: '/discount',
        method: 'POST',
        body,
      }),
    }),
    updateDiscount: builder.mutation({
      query: ({
        body,
        id,
      }: {
        id: string;
        body: {
          name: string;
          zones: Zones[];
          weights: number[];
          value: number;
        };
      }) => ({
        url: `/discount/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { reducer } = createSlice({
  name: 'discount',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(endpoints.readDiscounts.matchFulfilled, () => {
      // TODO
    });
  },
});
