import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBitmarteApprovedHubs } from 'services/apiFactory';

export const fetchBitmarteApprovedHubs = createAsyncThunk(
  'hubs/get',
  async () => {
    return getBitmarteApprovedHubs()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface bitMARTeApprovedProps {
  status: String;
  hubs: {
    count: Number;
    rows: [];
  };
  message: string;
}

const initialState: bitMARTeApprovedProps = {
  status: 'idle',
  hubs: {
    count: 0,
    rows: [],
  },
  message: '',
};

const approvedHubCompaniesSlice = createSlice({
  name: 'approvedHubs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBitmarteApprovedHubs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBitmarteApprovedHubs.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.hubs = payload?.data || [];
        state.message = 'Successfully fetched bitmarte approved hubs.';
      })
      .addCase(fetchBitmarteApprovedHubs.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export default approvedHubCompaniesSlice.reducer;
