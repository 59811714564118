import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDispatchersFunc } from 'services/apiFactory';

export const getAllDispatchers = createAsyncThunk(
  'admin/dispatchers',
  async () => {
    return getAllDispatchersFunc()
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface DispatcherProps {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isActive: boolean;
  roleId: number;
  confirm: boolean;
  notificationEndPoint?: string;
  lastLogin: string;
  phoneNumber: string;
  locations: string[];
  uniqueId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

interface DispatcherPropsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  dispatchersList: DispatcherProps[];
}

const initialState: DispatcherPropsState = {
  status: 'idle',
  error: '',
  dispatchersList: [],
};

const getAllDispatchersSlice = createSlice({
  name: 'getAllDispatchersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDispatchers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllDispatchers.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.dispatchersList = payload.data;
      })
      .addCase(getAllDispatchers.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default getAllDispatchersSlice.reducer;
