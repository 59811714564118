import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetPassword } from 'services/apiFactory';

export const initiateResetPassword = createAsyncThunk(
  'resetPassword',
  async ({
    userType,
    token,
    payload,
  }: {
    userType: string;
    token: any;
    payload: { password: string; confirmPassword: string };
  }) => {
    return await resetPassword(userType, token, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log('errror', err.response.data.message);
        return err;
      });
  }
);

interface ResetPasswordState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string;
  isError: boolean;
}

const initialState: ResetPasswordState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateResetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initiateResetPassword.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload?.message;
        }
      })
      .addCase(initiateResetPassword.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.isError = true;
        state.statusMessage = 'Please check your internet and try again';
      });
  },
});
export const { setStatusMessage } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
