import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMerchantPoliciesFunc } from 'services/apiFactory';

export const getAllMerchantPolicies = createAsyncThunk(
  'allPolicies',
  async (id) => {
    return getAllMerchantPoliciesFunc(id)
      .then((res: any) => {
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Policy {
  id: number;
  title: string;
  description: string;
  merchantId: number;
  storeId: number;
  createdAt: string;
  updatedAt: string;
}

export interface Policies {
  count: number;
  rows: Policy[];
}

interface PoliciesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  policies: Policies;
}

const initialState: PoliciesState = {
  status: 'idle',
  error: '',
  policies: {
    count: 0,
    rows: [],
  },
};

const merchantPolicySlice = createSlice({
  name: 'allMerchantPolicies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchantPolicies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllMerchantPolicies.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.policies = payload.data;
      })
      .addCase(getAllMerchantPolicies.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default merchantPolicySlice.reducer;
