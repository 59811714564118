import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from 'state/features/products/productsSlice';
import {
  addMerchantFeaturedProducts,
  deleteMerchantFeaturedProducts,
  getMerchantFeaturedProducts,
  getMerchantProducts,
  getMerchantStoreFeaturedProducts,
} from '../../../services/apiFactory';

export const fetchMerchantFeaturedProducts = createAsyncThunk(
  'product/merchantFeaturedProducts',
  async ({ id }: { id: number }) => {
    return getMerchantFeaturedProducts(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const fetchMerchantProducts = createAsyncThunk(
  'product/merchantProducts',
  async ({ id }: { id: number }) => {
    return getMerchantProducts(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const fetchMerchantStoreFeaturedProducts = createAsyncThunk(
  'product/merchantStoreFeaturedProducts',
  async ({ id }: { id: number }) => {
    return getMerchantStoreFeaturedProducts(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const addMerchantFeaturedProduct = createAsyncThunk(
  'product/merchantFeaturedProducts/add',
  async (payload: { productId: number }) => {
    return addMerchantFeaturedProducts(payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const deleteMerchantFeaturedProduct = createAsyncThunk(
  'product/merchantFeaturedProducts/delete',
  async ({ id }: { id: number }) => {
    return deleteMerchantFeaturedProducts(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface FeaturedProductsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  products: {
    product: Product;
  }[];
  featuredProducts: Product[];
}
const initialState: FeaturedProductsState = {
  status: 'idle',
  error: '',
  products: [],
  featuredProducts: [],
};

const merchaneFeaturedProductsSlice = createSlice({
  name: 'merchantFeaturedProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantFeaturedProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addMerchantFeaturedProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMerchantFeaturedProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchMerchantFeaturedProducts.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          if (payload?.data?.data) {
            state.products = payload.data.data;
          } else {
            state.products = [];
          }
        }
      )
      .addCase(
        fetchMerchantStoreFeaturedProducts.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          if (payload?.data?.data) {
            state.featuredProducts = payload.data.data;
          }
        }
      )
      .addCase(fetchMerchantProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data?.data) {
          state.products = payload.data.data;
        } else {
          state.products = [];
        }
      })
      .addCase(addMerchantFeaturedProduct.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteMerchantFeaturedProduct.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(fetchMerchantFeaturedProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default merchaneFeaturedProductsSlice.reducer;
