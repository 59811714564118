import { createSlice } from '@reduxjs/toolkit';
import { shippingApiSlice } from '../api/shippingApiSlice';

export const {
  endpoints,
  useReadZonesQuery,
  useReadPricingQuery,
  useUpdatePricingMutation,
  useUpdateZonesMutation,
} = shippingApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateZones: builder.mutation({
      query: (body) => ({
        url: '/shipping',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Zones'],
    }),
    readZones: builder.query({
      query: () => '/shipping',
      providesTags: ['Zones'],
    }),
    readPricing: builder.query({
      query: () => '/pricing',
      providesTags: ['Pricing'],
    }),
    updatePricing: builder.mutation({
      query: (body) => ({
        url: '/pricing',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Pricing'],
    }),
  }),
});

export const { reducer } = createSlice({
  name: 'vehicle',
  initialState: {},
  reducers: {},
});
