// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { store } from '../state/store';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import '../styles/globals.css';
import '../styles/colors.css';
import React from 'react';
// import runOneSignal from 'utils/notificationFuncs/runOneSignal';
import OneSignalReact from 'react-onesignal';
import { useAppDispatch } from 'state/hooks';
import Head from 'next/head';

function MyApp({ Component, pageProps }: AppProps) {
  // React.useEffect(() => {
  //   runOneSignal();
  // })
  // const dispatch = useAppDispatch();

  // async function runOneSignal() {
  //   await OneSignalReact.init({
  //     appId: process.env.NEXT_PUBLIC_APP_ONE_SIGNAL_ID,
  //     allowLocalhostAsSecureOrigin: true,
  //   });
  //   OneSignalReact.showSlidedownPrompt();

  //   OneSignalReact.isPushNotificationsEnabled((data) => {
  //     console.log(data);
  //     OneSignalReact.getUserId((data) => {
  //       dispatch(setUserNotificationId(data))
  //       console.log(data, "user");
  //     });

  //   });
  // }

  // useEffect(() => {
  //   runOneSignal();
  // }, []);

  // const run = async () => {
  //   await OneSignalReact.getUserId()
  // }

  // React.useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/OneSignalSDKWorker.js');
  //     // const data = async() => awaitOneSignalReact.getUserId()
  //     // // run().then(res=>console.log(res))
  //     // data.then((res)=>console.log(res))
  //     // // console.log(data)
  //   }
  // });
  //   React.useEffect(() => {
  //     window.OneSignal = window.OneSignal || [];
  //     OneSignal.push(function () {
  //         OneSignal.init({
  //             appId: process.env.NEXT_PUBLIC_APP_ONE_SIGNAL_ID,
  //             notifyButton: {
  //                 enable: true,
  //             },

  //             allowLocalhostAsSecureOrigin: true,
  //         });
  //     });

  //     return () => {
  //         window.OneSignal = undefined;
  //     };
  // }, []);
  return (
    <Provider store={store}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="apple-touch-icon" href="/assets/img/favicon.png" />
        <link rel="icon" href="/assets/img/favicon.png" />
      </Head>
      <Component {...pageProps} />
      <Toaster position="top-right" />
    </Provider>
  );
}

export default MyApp;
