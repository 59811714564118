import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { onboardShippingPartner } from '../../../services/apiFactory';

export const onboardingPartner = createAsyncThunk(
  'onboardingShipping/Partner',
  async (payload: object) => {
    return onboardShippingPartner(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          console.log(data);
          return data;
        }
      })
      .catch((err) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);
// Current date
export const today = new Date().toLocaleDateString();

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';
interface onboarding {
  company: any;
  bankDetails: any;
  statusMsg: string;
  isError: boolean;
  certification: any;
  isOnboarded: boolean;
  status: PromiseStatus;
}

const initialState: onboarding = {
  status: 'idle',
  isOnboarded: false,
  statusMsg: '',
  isError: false,
  company: {
    companyName: '',
    rcNumber: '',
    // vatRegistrationNumber: '',
    vatNumber: '',
    officeAddress: '',
    postalAddress: '',
    phoneNumber: '',
    email: '',
    contactPerson: {
      name: '',
      phoneNumber: '',
      designanion: '',
    },
    lineOfBusiness: '',
  },
  bankDetails: {
    bankName: '',
    branch: '',
    accountName: '',
    accountNumber: '',
    swiftCodeSortCodeIban: {
      type: '',
      code: '',
    },
  },
  certification: {
    name: '',
    designanion: '',
    signature: '',
    date: today,
    introducedBy: {
      name: '',
      signature: '',
    },
  },
};

const onboardingShippingPartnerSlice = createSlice({
  name: 'onboardingShippingPartner',
  initialState,
  reducers: {
    setCompany: (state, { payload }) => {
      // console.log(payload)
      state.company = payload;
    },
    setBankDetails: (state, { payload }) => {
      // console.log(payload)
      state.bankDetails = payload;
    },
    setCertification: (state, { payload }) => {
      // console.log(payload)
      state.certification = payload;
    },
    clearStatusMsg: (state, { payload }) => {
      (state.isError = payload.error), (state.statusMsg = payload.msg);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onboardingPartner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(onboardingPartner.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(onboardingPartner.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.success) {
          state.status = 'succeeded';
          state.statusMsg = payload?.message;
          state.isError = false;
          state.isOnboarded = true;
          // state.couponData = clearCouponData();
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMsg = payload;
          state.isOnboarded = false;
        }
        //  console.log(data)
      });
  },
});
export const { setCompany, setBankDetails, setCertification, clearStatusMsg } =
  onboardingShippingPartnerSlice.actions;

export default onboardingShippingPartnerSlice.reducer;
