import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initiateCustomerReturnOrderFunc } from 'services/apiFactory';

// Axios Request functions for merchant discount
export const postCustomerReturnOrder = createAsyncThunk(
  'post/customer/return/order',
  (payload: any) => {
    return initiateCustomerReturnOrderFunc(payload)
      .then((res: { data: any }) => {
        // console.log('res',res)
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        // console.log('err',err)
        const errors = err.response.data.message;
        return errors;
      });
  }
);

const initialState = {
  statusMessage: '',
  isError: '',
  order: [],
};

const initiateCustomerReturnsOrderSlice = createSlice({
  name: 'startCustomerReturnOrder',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCustomerReturnOrder.pending, (state) => {
        state.statusMessage = 'loading';
      })
      .addCase(postCustomerReturnOrder.fulfilled, (state, { payload }) => {
        state.statusMessage = 'succeeded';
        state.order = payload.data;
      })
      .addCase(postCustomerReturnOrder.rejected, (state, { error }) => {
        state.statusMessage = 'failed';
        state.isError =
          error.message ||
          'Could not information on this order. Try again later.';
      });
  },
});

export const { setStatusMessage } = initiateCustomerReturnsOrderSlice.actions;
export default initiateCustomerReturnsOrderSlice.reducer;
