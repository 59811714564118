import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RegisteredBusiness from 'components/merchant/Onboarding/Forms/RegisteredBusiness';
import {
  approveStore,
  getStoreDetails,
  getStoreApprovers,
  updateStorePaymentCategory,
} from '../../../services/apiFactory';

export const getAdminStoreDetails = createAsyncThunk(
  'admin/store-details',
  async (id: number) => {
    return getStoreDetails(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const getStoreApproversDetails = createAsyncThunk(
  'admin/store-approval-details',
  async () => {
    return getStoreApprovers()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const adminApproveStore = createAsyncThunk(
  'admin/approve-store',
  async (id: number) => {
    return approveStore(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const updatePaymentCategory = createAsyncThunk(
  'admin/update-payment-category',
  async ({ id, data }: { id: number; data: { isEscrow: boolean } }) => {
    return updateStorePaymentCategory(id, data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
interface ShippingLocation {
  state: string;
  street: string;
  country: string;
  city?: string;
}

interface RegisteredBusiness {
  cacCertificateUrl?: string;
  memorandumOfAssociationUrl?: string;
  rcNumber?: string;
}
interface Address {
  LGA: string;
  state: string;
  street: string;
  country: string;
}

interface Bank {
  isEscrow: boolean;
  bankBranch: string;
  accountName: string;
  bankName: string;
  accountNumber: string;
}

interface Merchant {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  nationality: string;
  idDocumentType: string;
  idDocumentNumber: string;
  idDocumentUrl?: string;
  homeAddress: Address[];
  officeAddress: Address[];
  useHomeAddress: boolean;
  rcNumber?: string;
  cacCertificateUrl?: string;
  memorandumOfAssociationUrl?: string;
  proofOfAddress?: string;
  staffSize: number;
  category: number;
  confirm: boolean;
  isVerified: boolean;
  physicalLocation: boolean;
  bank?: Bank;
  uniqueId?: string;
}

export interface StoreDetails {
  id: number;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo?: string;
  storePhoneNumber?: string;
  isRegisteredBusiness: boolean;
  shippingLocation: ShippingLocation[];
  proofOfAddress?: string;
  isPhysicalLocation: boolean;
  firstApproval: boolean;
  secondApproval: boolean;
  useBitmarteHub: boolean;
  bitmarteHubId?: number;
  merchant: Merchant;
  registeredBusiness: RegisteredBusiness;
}

interface AdminStoresDetailsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  approveStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updatePaymentStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  approveError: string;
  updatePaymentError: string;
  details: StoreDetails | null;
  storeApprovalsList: any[];
}

const initialState: AdminStoresDetailsState = {
  status: 'idle',
  approveStatus: 'idle',
  updatePaymentStatus: 'idle',
  error: '',
  approveError: '',
  updatePaymentError: '',
  details: null,
  storeApprovalsList: [],
};

const adminStoreDetailsSlice = createSlice({
  name: 'adminStoreDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminStoreDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAdminStoreDetails.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.details = payload?.data || null;
      })
      .addCase(getAdminStoreDetails.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      })
      .addCase(getStoreApproversDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStoreApproversDetails.fulfilled, (state, { payload }) => {
        // console.log(payload?.data)
        state.status = 'succeeded';
        state.storeApprovalsList = payload?.data;
      })
      .addCase(adminApproveStore.pending, (state) => {
        state.approveStatus = 'loading';
      })
      .addCase(adminApproveStore.fulfilled, (state) => {
        state.approveStatus = 'succeeded';
        state.details = {
          ...(state.details as StoreDetails),
          firstApproval: true,
        };
      })
      .addCase(adminApproveStore.rejected, (state, { error }) => {
        state.approveStatus = 'failed';
        state.approveError =
          error.message || 'Could not get user categories. Try again later.';
      })
      .addCase(updatePaymentCategory.pending, (state) => {
        state.updatePaymentStatus = 'loading';
      })
      .addCase(updatePaymentCategory.fulfilled, (state) => {
        state.updatePaymentStatus = 'succeeded';
      })
      .addCase(updatePaymentCategory.rejected, (state, { error }) => {
        state.updatePaymentStatus = 'failed';
        state.updatePaymentError =
          error.message ||
          'Could not update payment category. Try again later.';
      });
  },
});

export default adminStoreDetailsSlice.reducer;
