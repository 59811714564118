import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { getAdminProducts } from '../../../services/apiFactory';

export const fetchAdminProducts = createAsyncThunk(
  'product/adminProducts',
  async ({
    limit,
    offset,
    query,
  }: {
    limit: number;
    offset: number;
    query: string;
  }) => {
    return getAdminProducts({ limit, offset, query })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  name: string;
  pricing: [
    {
      price: string;
      sellingPrice: string;
    }
  ];
  hostedImages: string[];
  rating?: number;
  sku?: string;
  type?: string;
  approvalStatus?: string;
  storeName?: string;
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  count: number;
  products: Product[];
  filteredProducts: Product[];
  totalApprovedProducts: number;
  totalPendingProducts: number;
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  count: 0,
  products: [],
  filteredProducts: [],
  totalApprovedProducts: 0,
  totalPendingProducts: 0,
};

const adminProductsSlice = createSlice({
  name: 'adminProducts',
  initialState,
  reducers: {
    filterProducts(state, action) {
      switch (action.payload) {
        case 'Pending Approval':
          state.filteredProducts = state.products.filter(
            (product) => product.approvalStatus?.toLowerCase() === 'pending'
          );
          state.totalPendingProducts = state.filteredProducts.length;
          break;
        case 'Approved':
          state.filteredProducts = state.products.filter(
            (product) => product.approvalStatus?.toLowerCase() === 'approved'
          );
          state.totalApprovedProducts = state.filteredProducts.length;
          break;
        default:
          state.filteredProducts = state.products;
          break;
      }
      // return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminProducts.fulfilled, (state, { payload }) => {
        const data = payload.data;
        state.status = 'succeeded';
        state.products = data?.data || [];
        state.filteredProducts = data?.data || [];
        state.totalApprovedProducts = state.products?.filter(
          (product) => product.approvalStatus?.toLowerCase() === 'approved'
        ).length;
        state.totalPendingProducts = state.products?.filter(
          (product) => product.approvalStatus?.toLowerCase() === 'pending'
        ).length;
        state.count = payload.data.count;
      })
      .addCase(fetchAdminProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});
export const { filterProducts } = adminProductsSlice.actions;
// export const selectAdminProducts = (state:RootState) =
export default adminProductsSlice.reducer;
