import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSubCategories } from '../../../services/apiFactory';

export const getMerchantCategories = createAsyncThunk(
  'merchant/categories',
  async (id: number) => {
    return getSubCategories(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface SubCategory {
  id: number;
  name: string;
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  category: {
    id: number;
    name: string;
    subCategories: SubCategory[];
  };
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  category: {
    id: 0,
    name: '',
    subCategories: [],
  },
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantCategories.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.category = payload.data;
      })
      .addCase(getMerchantCategories.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      });
  },
});

export default categoriesSlice.reducer;
