import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateOrderStatus,
  getOrderStatuses,
  getAdminOrders,
  getShippingPartners,
  getAminOrder,
  adminAssignOrder,
  approvePayment,
  getCustomerOrderShippingAddress,
} from '../../../services/apiFactory';
import { OrderStatus } from 'types';
import { stat } from 'fs';
import { ShippingAddressType } from 'state/features/cart/cartSlice';

// Axios Request functions for merchant discount
// getMerchantCoupons
export const fetchAdminOrders = createAsyncThunk(
  'adminOrders/getOrders',
  async () => {
    return getAdminOrders()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchShippingPartners = createAsyncThunk(
  'adminOrders/getShippingPartners',
  async () => {
    return getShippingPartners()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const assignOrderToShippingPartner = createAsyncThunk(
  'adminOrders/assignOrderToShippingPartner',
  async (data: { orderId: number; partnerId: number }) => {
    return adminAssignOrder(data)
      .then((res) => {
        if (res) {
          const data = res.data;
          console.log(data);
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response)));
  }
);
export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchAllowedOrderStatuses = createAsyncThunk(
  'merchantOrders/getAllowedStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchSingleAdminOrder = createAsyncThunk(
  'adminOrders/getOrder',
  async (data: { id: number }) => {
    const { id } = data;
    const res = await getAminOrder(id);
    return res.data;
  }
);
export const approveMerchantPayment = createAsyncThunk(
  'adminOrders/approveMerchantPayment',
  async (data: { id: number }) => {
    const { id } = data;
    return approvePayment(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          console.log(data);
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response)));
  }
);

export const updateAdminOrderStatus = createAsyncThunk(
  'merchantOrders/updateMerchantOrderStatus',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    const res = await updateOrderStatus(id, payload);
    return res.data;
  }
);

export const fetchCustomerOrderShippingAddress = createAsyncThunk(
  'adminOrders/getCustomerShippingAddress',
  async (data: { customerId: number; deliveryAddressId: number }) => {
    const { customerId, deliveryAddressId } = data;
    const res = await getCustomerOrderShippingAddress(
      customerId,
      deliveryAddressId
    );
    return res.data;
  }
);

// Constants discount type decleared
export interface OrderItem {
  productId: number;
  merchantId: number;
  shippingFee: number;
  quantity: number;
  productPrice: number;
  productName: string;
  selected: boolean;
  productVariants: any[];
  replaceProductItem: any;
  storeName: string;
  hostedImages: string[];
}
interface orderActivity {
  id: number;
  orderTrackerId: number;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
export interface AdminOrder {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatus: OrderStatus;
  subtotal: string;
  shippingFee: string;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  assignedToBitmarte: boolean;
  shippingEvidence: string;
  partnerAssignOrderId: number;
  orderItems: OrderItem[];
  deliveryAddress: string;
  approvePayout: boolean;
  createdAt: string;
  orderReturnId: number;
  updatedAt: string;
  withinLagos: boolean;
  lagosMerchant?: boolean;
  lagosCustomer?: boolean;
  orderActivities: orderActivity[];
}
interface orderStatuses {
  id: number;
  code_name: string;
  known_name: string;
}
export interface shippingPartner {
  id: number;
  name: string;
  ShippingCompany: {
    companyName: string;
  };
}
interface AdminOrdersState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  orders: AdminOrder[];
  selectedOrder: AdminOrder;
  isError: boolean;
  orderStatuses: orderStatuses[];
  allowedOrderStatus: orderStatuses[];
  shippingPartners: shippingPartner[];
  shippingAddress: ShippingAddressType;
}

const initialState: AdminOrdersState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  orders: [],
  orderStatuses: [],
  allowedOrderStatus: [],
  shippingPartners: [],
  selectedOrder: {
    id: 3,
    orderReturnId: 1,
    assignedToBitmarte: false,
    orderName: '',
    orderNote: '',
    orderStatus: 'pending',
    subtotal: '',
    storeName: '',
    shippingFee: '',
    total: '',
    customerId: 1,
    merchantId: 1,
    shippingEvidence: '',
    withinLagos: false,
    lagosMerchant: false,
    lagosCustomer: false,
    orderItems: [],
    deliveryAddress: '',
    approvePayout: false,
    partnerAssignOrderId: 3,
    createdAt: '',
    updatedAt: '',
    orderActivities: [],
  },
  shippingAddress: {
    address: '',
    default: false,
    emailAddress: '',
    fullName: '',
    id: 0,
    phoneNumber: '',
    stateOrRegion: '',
  },
};

export const clearOrderData = () => {
  const clearData = initialState.orders;
  return clearData;
};

const merchantOrdersSlice = createSlice({
  name: 'merchantOrders',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminOrders.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.orders = [];

        state.orders =
          data?.map((item: any) => ({
            ...item,
            orderStatus: state.orderStatuses?.find(
              (i) => i.id === item?.orderStatusId
            )?.code_name,
          })) || [];
        state.status = 'succeeded';
      })
      .addCase(fetchAdminOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleAdminOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleAdminOrder.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedOrder = {
          ...data,
          orderStatus: state.orderStatuses.find(
            (i) => i.id === data?.orderStatusId
          )?.code_name,
        };
        state.status = 'succeeded';
      })
      .addCase(fetchSingleAdminOrder.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateAdminOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAdminOrderStatus.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateAdminOrderStatus.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderStatuses = data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAllowedOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllowedOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.allowedOrderStatus = data;
      })
      .addCase(fetchAllowedOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchShippingPartners.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShippingPartners.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.shippingPartners = data;
      })
      .addCase(fetchShippingPartners.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(assignOrderToShippingPartner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(assignOrderToShippingPartner.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(assignOrderToShippingPartner.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(approveMerchantPayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(approveMerchantPayment.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(approveMerchantPayment.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomerOrderShippingAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCustomerOrderShippingAddress.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          state.shippingAddress = payload.data;
        }
      )
      .addCase(fetchCustomerOrderShippingAddress.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setStatusMessage } = merchantOrdersSlice.actions;
export default merchantOrdersSlice.reducer;
