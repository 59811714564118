import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAminOrder,
  getDispatcherOrder,
  getDispatcherOrdersFunc,
  getOrderStatuses,
  updateOrderStatus,
} from 'services/apiFactory';
import {
  orderStatuses,
  orderCurrentStatuses,
} from 'state/features/orders/merchantOrdersSlice';

export const getDispatcherOrders = createAsyncThunk(
  'admin/dispatch/orders',
  async () => {
    return getDispatcherOrdersFunc()
      .then((res: any) => {
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export const fetchOrderStatuses = createAsyncThunk(
  'admin/dispatch/getStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export const fetchCurrentOrderStatus = createAsyncThunk(
  'admin/dispatch/getStatus',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export const updateDispatcherStatus = createAsyncThunk(
  'admin/dispatch/updateDispatcherStatus',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    const res = await updateOrderStatus(id, payload);
    return res.data;
  }
);

export const fetchDispatcherOrder = createAsyncThunk(
  'admin/dispatch/getOrder',
  async (data: { id: number }) => {
    const { id } = data;
    const resp = await getDispatcherOrder(id);
    return resp.data;
  }
);

export interface DispatcherOrderProps {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatusId: number;
  partnerAssignOrderId: null;
  dispatcherId: null;
  subtotal: string;
  shippingFee: number;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  orderItems: {
    quantity: number;
    productId: number;
    storeName: string;
    merchantId: number;
    productName: string;
    shippingFee: number;
    hostedImages: string[];
    productPrice: number;
    productVariants: {
      sku: string;
      size: string;
      color: string;
      discount: number;
      quantity: number;
    }[];
  }[];
  rewardProductsIds: [];
  deliveryAddress: number;
  dispatchPhoneNumber: null;
  orderDeliveryToken: null;
  approvePayout: boolean;
  assignedToBitmarte: boolean;
  assignedToDispatcher: boolean;
  assignedToShippingPartner: boolean;
  withinLagos: boolean;
  createdAt: string;
  updatedAt: string;
  orderStatus: string;
  currentOrderStatus: string;
  orderActivities: [];
}

interface DispatcherOrderPropsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  dispatcherOrdersList: DispatcherOrderProps[];
  selectedOrder: DispatcherOrderProps;
  orderStatuses: orderStatuses[];
  orderCurrentStatus: orderCurrentStatuses[];
  shippingPartners: any[];
}

const initialState: DispatcherOrderPropsState = {
  status: 'idle',
  error: '',
  dispatcherOrdersList: [],
  orderStatuses: [],
  orderCurrentStatus: [],
  selectedOrder: {
    id: 1,
    orderName: '',
    orderNote: '',
    orderStatusId: 2,
    partnerAssignOrderId: null,
    dispatcherId: null,
    subtotal: '',
    shippingFee: 3,
    total: '',
    customerId: 1,
    merchantId: 1,
    storeName: '',
    orderItems: [],
    rewardProductsIds: [],
    deliveryAddress: 3,
    dispatchPhoneNumber: null,
    orderDeliveryToken: null,
    approvePayout: false,
    assignedToBitmarte: false,
    assignedToDispatcher: false,
    assignedToShippingPartner: false,
    withinLagos: false,
    createdAt: '',
    updatedAt: '',
    orderStatus: '',
    currentOrderStatus: '',
    orderActivities: [],
  },
  shippingPartners: [],
};

const getDispatcherOrdersSlice = createSlice({
  name: 'getDispatcherOrdersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDispatcherOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDispatcherOrders.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        // state.dispatcherOrdersList = payload.data;
        state.dispatcherOrdersList = [];

        state.dispatcherOrdersList =
          payload.data?.map((item: any) => ({
            ...item,
            orderStatus: state.orderStatuses?.find(
              (i) => i.id === item?.orderStatusId
            )?.code_name,
          })) || [];
      })
      .addCase(getDispatcherOrders.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      })
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderStatuses = data;
      })
      .addCase(fetchOrderStatuses.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      })
      .addCase(fetchCurrentOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentOrderStatus.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderCurrentStatus = data;
      })
      .addCase(fetchCurrentOrderStatus.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      })
      .addCase(fetchDispatcherOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDispatcherOrder.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.selectedOrder = {
          ...data,
          orderStatus: state.orderStatuses.find(
            (i) => i.id === data.orderStatusId
          )?.code_name,
          currentOrderStatus: state.orderCurrentStatus.find(
            (i) => i.id === data.orderStatusId
          )?.code_name,
        };
        state.status = 'succeeded';
      })
      .addCase(fetchDispatcherOrder.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      })
      .addCase(updateDispatcherStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDispatcherStatus.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateDispatcherStatus.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default getDispatcherOrdersSlice.reducer;
