import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { VariantsCombination } from 'interfaces/merchant/products/VariantProps';
import { addProduct, adminAddProduct } from '../../../services/apiFactory';

export const createMerchantProduct = createAsyncThunk(
  'merchant/product/new ',
  async (data: any) => {
    return addProduct(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const adminCreateMerchantProduct = createAsyncThunk(
  'admin/product/new ',
  async (data: any) => {
    return adminAddProduct(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Product {
  name: string;
  description: any[];
  category: string;
  categoryId: number;
  subCategory: string;
  subCategoryId: number;
  childCategory: string;
  childCategoryId: number;
  hostedImages: string[];
  pricing: [
    {
      costPrice: number;
      sellingPrice: number;
      price: number;
    }
  ];
  sku: string;
  quantity: number;
  weight: number;
  dimensions: {
    length: number;
    width: number;
    height: number;
  };
  variant: VariantsCombination[];
  weightUnit: string;
  dimensionUnit: string;
  variantProperties: {
    type: string;
    options: string[];
  }[];
  contSalesOutOfStock?: boolean;
  stockAlertUnits?: number;
  IsDigitalProduct?: boolean;
}

interface getProductsState {
  status: PromiseStatus;
  product: Product;
  images: File[];
  variants: {
    property: string;
    options: string[];
  }[];
  message: string;
}

const initialState: getProductsState = {
  status: 'idle',
  product: {
    name: '',
    description: [],
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    childCategory: '',
    childCategoryId: 0,
    hostedImages: [],
    pricing: [
      {
        costPrice: 0,
        sellingPrice: 0,
        price: 0,
      },
    ],
    quantity: 0,
    sku: '',
    weight: 0,
    weightUnit: '',
    dimensions: {
      length: 0,
      width: 0,
      height: 0,
    },
    dimensionUnit: '',
    variant: [],
    variantProperties: [],
    contSalesOutOfStock: false,
    stockAlertUnits: 0,
    IsDigitalProduct: false,
  },
  images: [],
  variants: [
    {
      property: '',
      options: [],
    },
  ],
  message: '',
};

const newProductSlice = createSlice({
  name: 'newProduct',
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = {
        ...state.product,
        ...action.payload,
      };
    },
    setImages: (state, action: { payload: File[] }) => {
      state.images = action.payload;
    },
    setStatus: (state, action: { payload: PromiseStatus }) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMerchantProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createMerchantProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.message = 'Successfully created product.';
        state.product = initialState.product;
        state.images = [];
      })
      .addCase(createMerchantProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(adminCreateMerchantProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(adminCreateMerchantProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.message = 'Successfully created product.';
        state.product = initialState.product;
        state.images = [];
      })
      .addCase(adminCreateMerchantProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export const { setProduct, setImages, setStatus } = newProductSlice.actions;
export default newProductSlice.reducer;
