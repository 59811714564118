import { createSlice } from '@reduxjs/toolkit';
import { Product } from '../adminProducts/adminProductsSlice';
import { apiSlice } from '../api/apiSlice';

export interface Merchant {
  id: number;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo: null;
  storePhoneNumber: null;
  isRegisteredBusiness: boolean;
  shippingLocation: ShippingLocation[];
  proofOfAddress: null;
  isPhysicalLocation: null;
  firstApproval: boolean;
  secondApproval: boolean;
  reasonForRejection: string;
  firstApprovalAdminId: null;
  secondApprovalAdminId: null;
  useBitmarteHub: boolean;
  bitmarteHubId: null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ShippingLocation {
  state: string;
  street: string;
  country: string;
}

export const {
  endpoints,
  useReadStoresQuery,
  useReadProductsQuery,
  useAddPromotionProductsMutation,
  useReadPromotionProductsQuery,
  useReadOfficialOutletsQuery,
  useAddOfficialOutletMutation,
  useRemoveOfficialOutletMutation,
  useRemovePromotionProductsMutation,
  useReadOfficialOutletsAdminQuery,
} = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    readProducts: builder.query<
      { data: { count: number; data: Product[] } },
      void
    >({
      query: () => '/products/customer/getallproducts',
    }),
    readStores: builder.query<{ data: { allStore: Merchant[] } }, void>({
      query: () => '/store/customer/all-stores',
    }),
    addPromotionProducts: builder.mutation({
      query: (body: { productIds: number[]; promoBanner: string }) => ({
        url: `/products/admin/add-promotion-products`,
        method: 'POST',
        body,
      }),
    }),

    removeOfficialOutlet: builder.mutation({
      query: (id: number) => ({
        url: `/products/admin/delete-official-outlet/${id}`,
        method: 'DELETE',
      }),
    }),
    addOfficialOutlet: builder.mutation({
      query: (body) => ({
        url: `/products/admin/add-official-outlet`,
        method: 'POST',
        body,
      }),
    }),
    readPromotionProducts: builder.query({
      query: () => ({
        url: `/products/customer/promotion-products`,
      }),
    }),
    removePromotionProducts: builder.mutation({
      query: ({
        promoId,
        productId,
      }: {
        promoId: number;
        productId: number;
      }) => ({
        url: `/products/admin/delete-product-promotion/${promoId}`,
        method: 'DELETE',
        body: {
          productId,
        },
      }),
    }),
    readOfficialOutlets: builder.query({
      query: () => `products/customer/official-outlets`,
    }),
    readOfficialOutletsAdmin: builder.query({
      query: () => `products/admin/official-outlets`,
    }),
  }),
});

export const { reducer } = createSlice({
  name: 'cms',
  initialState: {},
  reducers: {},
  extraReducers: {},
});
