import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOfficialOutletsProducts } from 'services/apiFactory';

export const fetchOfficialOutletsProducts = createAsyncThunk(
  'customer/officialOutlets/products',
  async () => {
    return getOfficialOutletsProducts()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

interface getOfficialOutletsProductsState {
  status: PromiseStatus;
  error: string;
  stores: {
    [key: string]: {
      merchantId: number;
      storeId: number;
      merchant: {
        store: {
          id: number;
          storeUrl: string;
          logo?: string;
          storeName: string;
        };
        products: {
          id: number;
          name: string;
          hostedImages: string[];
          pricing: [
            {
              price: string;
              costPrice: string;
              sellingPrice: string;
            }
          ];
        }[];
      };
    };
  };
}

const initialState: getOfficialOutletsProductsState = {
  status: 'idle',
  error: '',
  stores: {},
};

const officialOutletsProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOfficialOutletsProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOfficialOutletsProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.stores = payload?.data?.data || {};
      })
      .addCase(fetchOfficialOutletsProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || '';
      });
  },
});

export default officialOutletsProductsSlice.reducer;
