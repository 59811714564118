import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getNextDaySettlementReport,
  getOrderStatuses,
} from '../../../services/apiFactory';

export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async () => {
    return getOrderStatuses('all')
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getNextDaySettlement = createAsyncThunk(
  'sales-report/sales',
  async () => {
    return getNextDaySettlementReport()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';
interface SalesReport {
  ndStettlementReports: any[];
  orderStatus: any[];
  totalData: {
    totalDeliveryAmount: number;
    totalOrderAmount: number;
    totalSalesOrderAmount: number;
    dueToPaystackFromBitmarte: number;
    remitToBitmart: number;
    dueToMerchant: number;
    dueToBitmart: number;
  };
  status: PromiseStatus;
}

const initialState: SalesReport = {
  status: 'idle',
  orderStatus: [],
  totalData: {
    totalSalesOrderAmount: 0,
    totalDeliveryAmount: 0,
    totalOrderAmount: 0,
    dueToPaystackFromBitmarte: 0,
    remitToBitmart: 0,
    dueToMerchant: 0,
    dueToBitmart: 0,
  },
  ndStettlementReports: [],
};

const adminSettlementReportSlice = createSlice({
  name: 'nextDaySettlementReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNextDaySettlement.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNextDaySettlement.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const data = payload.data.data;
        const totalData = payload.data.totalData;
        state.ndStettlementReports = data;
        state.totalData = totalData;
      })
      .addCase(getNextDaySettlement.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const data = payload.data;
        //  console.log(data)
        state.orderStatus = data && data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default adminSettlementReportSlice.reducer;
