import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AnyPtrRecord } from 'dns';
import moment from 'moment';
import {
  getCoupons,
  createCoupon,
  getProducts,
  getOneCoupon,
  updateOneCoupon,
} from '../../../services/apiFactory';

// Axios Request functions for merchant discount
// getMerchantCoupons
export const getMerchantCoupons = createAsyncThunk(
  'merchantCoupons/getCoupons',
  async () => {
    return getCoupons()
      .then((res: { data: any }) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err: { response: any }) => JSON.stringify(err.response));
  }
);

// createNewCoupon
export const createNewCoupon = createAsyncThunk(
  'merchantCoupons/creatCoupons',
  async (payload: object) => {
    return createCoupon(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        return data;
      })
      .catch((err: { response: { data: { message: any } } }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// Get One Coupon
export const oneCoupon = createAsyncThunk(
  'merchantCoupons/getOneCoupon',
  async (id: any) => {
    return getOneCoupon(id)
      .then((res: { data: { data: any } }) => {
        const data = res.data.data;
        // console.log(data);
        return data;
      })
      .catch((err: { response: { data: { message: any } } }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// Update One Coupon
export const updateCoupon = createAsyncThunk(
  'merchantCoupons/updateOneCoupon',
  async (payload: any) => {
    return updateOneCoupon(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch((err: { response: { data: { message: any } } }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// Get merchant products
export const fetchProducts = createAsyncThunk(
  'merchantCoupons/getProducts',
  async () => {
    return getProducts()
      .then((res: { data: any }) => {
        const data = res.data;
        // console.log(data);
        return data;
      })
      .catch((err: { response: { data: { message: any } } }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// Constants discount type decleared
export const PERCENTAGED = 'Percent';
export const FIXED = 'Fixed';
export const BUYXGETY = 'BuyXGetY';

export interface Coupon {
  id: number;
  couponCode: string;
  description: string;
  discountMode: string;
  discountType: string;
  endDate: string;
  startDate: string;
  usageLimit: number;
}

interface MerchantCouponsState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  isEdit: boolean;
  cId: any;
  statusMessage: string | null;
  couponList: Coupon[];
  couponData: any;
  products: any[];
  productReward: any[];
  customerBuy: any[];
  isError: boolean;
  spendAppliesToProducts: any[];
}

const initialState: MerchantCouponsState = {
  status: 'idle',
  isEdit: false,
  cId: null,
  statusMessage: '',
  isError: false,
  couponList: [],
  products: [],
  productReward: [],
  customerBuy: [],
  spendAppliesToProducts: [],
  couponData: {
    discountMode: 'Automatic',
    description: '',
    discountType: '',
    discountValue: 0,
    startDate: '',
    endDate: '',
    spendAppliesTo: 'All Product',
    spendAppliesToId: [],
    minRequireType: 'Amount',
    minRequireValue: 0,
    usageLimit: 0,
    usageLimitPerUser: 0,
    customerEligibility: 'Everyone',
    customerReward: 2000,
    rewardAppliesTo: 'Product',
    rewardAppliesToId: [],
    rewardValue: 20,
  },
};

export const clearCouponData = () => {
  const clearData = initialState.couponData;
  return clearData;
};

const merchantCouponsSlice = createSlice({
  name: 'merchantCoupons',
  initialState,
  reducers: {
    setIsEdit: (state, { payload }) => {
      state.isEdit = payload;
    },
    // Set reward products
    setProductReward: (state, { payload }) => {
      state.productReward = payload;
    },
    // Set customer buy
    setCustomerBuy: (state, { payload }) => {
      state.customerBuy = payload;
    },
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
    // Set all coupon data
    setCouponData: (state, { payload }) => {
      state.couponData = payload;
    },
    // Set indivdual General data onchange
    setDiscription: (state, { payload }) => {
      state.couponData.description = payload;
    },
    setDiscountType: (state, { payload }) => {
      state.couponData.discountType = payload;
    },
    setDiscountValue: (state, { payload }) => {
      state.couponData.discountValue = payload;
    },
    setStartDate: (state, { payload }) => {
      state.couponData.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.couponData.endDate = payload;
    },

    // Set indivdual Set Restrictions data onchange
    setSpendAppliesTo: (state, { payload }) => {
      state.couponData.spendAppliesTo = payload;
    },

    setSpendAppliesToId: (state, { payload }) => {
      state.couponData.spendAppliesToId = payload;
    },

    setSpendAppliesToProducts: (state, { payload }) => {
      state.spendAppliesToProducts = payload;
    },

    setMinRequireType: (state, { payload }) => {
      state.couponData.minRequireType = payload;
    },
    setMinRequireValue: (state, { payload }) => {
      state.couponData.minRequireValue = payload;
    },
    setRewardAppliesTo: (state, { payload }) => {
      state.couponData.rewardAppliesTo = payload;
    },
    setRewardAppliesToId: (state, { payload }) => {
      state.couponData.rewardAppliesToId = payload;
    },
    setRewardValue: (state, { payload }) => {
      state.couponData.rewardValue = payload;
    },

    // Set indivdual Add Limits data onchange
    setUsageLimit: (state, { payload }) => {
      state.couponData.usageLimit = payload;
    },
    setUsageLimitPerUser: (state, { payload }) => {
      state.couponData.usageLimitPerUser = payload;
    },
    setCustomerEligibility: (state, { payload }) => {
      state.couponData.customerEligibility = payload;
    },

    // Set indivdual Miscellaneous data onchange
    setDiscountMode: (state, { payload }) => {
      state.couponData.discountMode = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantCoupons.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.couponList = [];
        state.couponList = data || [];
        state.status = 'succeeded';
      })
      .addCase(getMerchantCoupons.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(createNewCoupon.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
          state.couponData = clearCouponData();
          state.spendAppliesToProducts = [];
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload;
        }
      })
      .addCase(createNewCoupon.rejected, (state) => {
        state.status = 'failed';
        state.statusMessage = 'Check network connection';
        state.isError = true;
      })

      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.products = payload?.data?.products;
      })

      .addCase(oneCoupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(oneCoupon.rejected, (state) => {
        state.status = 'failed';
        state.statusMessage = 'Check network connection';
        state.isError = true;
      })

      .addCase(oneCoupon.fulfilled, (state, { payload }) => {
        // console.log(payload);
        if (payload?.percentageCoupons?.length > 0) {
          const percentage = payload.percentageCoupons[0];
          state.couponData.discountValue = percentage?.discountValue;
          state.couponData.minRequireType = percentage?.minRequireType;
          state.couponData.minRequireValue = percentage?.minRequireValue;
          state.couponData.spendAppliesTo = percentage?.spendAppliesTo;
          state.couponData.spendAppliesToId = percentage?.spendAppliesToId.map(
            (obj: any) => obj.id
          );
          state.spendAppliesToProducts = percentage?.spendAppliesToId.map(
            (obj: any) => obj.name
          );
        }

        if (payload?.fixedCoupons?.length > 0) {
          const fixed = payload.fixedCoupons[0];
          state.couponData.discountValue = fixed?.discountValue;
          state.couponData.minRequireType = fixed?.minRequireType;
          state.couponData.minRequireValue = fixed?.minRequireValue;
          state.couponData.spendAppliesTo = fixed?.spendAppliesTo;
          state.couponData.spendAppliesToId = fixed?.spendAppliesToId.map(
            (obj: any) => obj.id
          );
          state.spendAppliesToProducts = fixed?.spendAppliesToId.map(
            (obj: any) => obj.name
          );
        }

        state.status = 'succeeded';
        state.couponData.discountMode = payload?.discountMode;
        state.couponData.description = payload?.description;
        state.couponData.discountType = payload?.discountType;
        state.couponData.startDate = moment(payload?.startDate)
          .utc()
          .format('YYYY-MM-DD');
        state.couponData.endDate = moment(payload?.endDate)
          .utc()
          .format('YYYY-MM-DD');
        state.couponData.usageLimit = payload?.usageLimit;
        state.couponData.usageLimitPerUser = payload?.usageLimitPerUser;
        state.couponData.customerEligibility = payload?.customerEligibility;
      })

      .addCase(updateCoupon.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload;
        }
      });
  },
});

export const {
  setIsEdit,
  setProductReward,
  setCustomerBuy,
  setStatusMessage,
  setCouponData,
  setDiscription,
  setDiscountType,
  setDiscountValue,
  setStartDate,
  setEndDate,
  setSpendAppliesTo,
  setSpendAppliesToId,
  setMinRequireType,
  setMinRequireValue,
  setRewardAppliesTo,
  setRewardAppliesToId,
  setSpendAppliesToProducts,
  setRewardValue,
  setUsageLimit,
  setUsageLimitPerUser,
  setCustomerEligibility,
  setDiscountMode,
} = merchantCouponsSlice.actions;
export default merchantCouponsSlice.reducer;
