import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTopCategories } from '../../../services/apiFactory';

export const getTopProductCategories = createAsyncThunk(
  'categories/top',
  async () => {
    return getTopCategories()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface ChildrenCategory {
  id: number;
  name: number;
}

export interface SubCategory {
  id: number;
  name: string;
  childCategories: ChildrenCategory[];
}

export interface TopCategory {
  id: number;
  name: string;
  subCategories: SubCategory[];
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  categories: TopCategory[];
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  categories: [],
};

const topCategoriesSlice = createSlice({
  name: 'allCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopProductCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTopProductCategories.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.categories = payload.data;
      })
      .addCase(getTopProductCategories.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get categories. Try again later.';
      });
  },
});

export default topCategoriesSlice.reducer;
