import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface ChatState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  selectedChat: any[];
}

const initialState: ChatState = {
  status: 'idle',
  error: '',
  selectedChat: [],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
      console.log(action.payload);
    },
  },
});

export const { setSelectedChat } = chatSlice.actions;
export default chatSlice.reducer;
