import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchSchedulerOrdersFunc } from 'services/apiFactory';

export const searchSchedulerOrders = createAsyncThunk(
  'admin/schedule/orders',
  async ({ search }: { search: string }) => {
    return searchSchedulerOrdersFunc({ search })
      .then((res: any) => {
        // console.log('res', res);
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface SchedulerOrderProps {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatusId: number;
  partnerAssignOrderId: null;
  dispatcherId: null;
  subtotal: string;
  shippingFee: number;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  orderItems: {
    quantity: number;
    productId: number;
    storeName: string;
    merchantId: number;
    productName: string;
    shippingFee: number;
    hostedImages: string[];
    productPrice: number;
    productVariants: {
      sku: string;
      size: string;
      color: string;
      discount: number;
      quantity: number;
    }[];
  }[];
  rewardProductsIds: [];
  deliveryAddress: number;
  dispatchPhoneNumber: null;
  orderDeliveryToken: null;
  approvePayout: boolean;
  assignedToBitmarte: boolean;
  assignedToDispatcher: boolean;
  assignedToShippingPartner: boolean;
  createdAt: string;
  updatedAt: string;
  orderActivities: [];
}

interface SchedulerOrderPropsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  searchSchedulerOrderList: SchedulerOrderProps[];
}

const initialState: SchedulerOrderPropsState = {
  status: 'idle',
  error: '',
  searchSchedulerOrderList: [],
};

const searchSchedulerOrdersSlice = createSlice({
  name: 'searchSchedulerOrdersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchSchedulerOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchSchedulerOrders.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.searchSchedulerOrderList = payload.data;
      })
      .addCase(searchSchedulerOrders.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default searchSchedulerOrdersSlice.reducer;
