import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAdminTransaction,
  getAdminTransactions,
} from '../../../services/apiFactory';
import dateFormat from 'dateformat';

// Axios Request functions for merchant discount
// getMerchantCoupons
export const fetchAdminTransactions = createAsyncThunk(
  'adminTransactions/getTransactions',
  async () => {
    return getAdminTransactions()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchAdminTransaction = createAsyncThunk(
  'adminTransactions/getTransaction',
  async (data: { id: string }) => {
    const { id } = data;
    return getAdminTransaction(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

// Constants discount type decleared
export interface AdminTransactionOrderItem {
  storeId: number;
  quantity: number;
  productId: number;
  storeName: string;
  merchantId: number;
  productName: string;
  shippingFee: number;
  productPrice: string;
}
export interface AdminTransaction {
  id: number;
  orderTrackerId: any;
  transactionId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  amount: string;
  customerName: string;
  reference: string;
  fees: string;
  date: string;
  customerEmail: string;
  cardType: string;
  cardNumber: string;
  bank: string;
  country: string;
  ipAddress: string;
  paymentChannel: string;
  orderName: string;
  orderItems: AdminTransactionOrderItem[];
}

interface AdminTransactiuonsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  transactions: AdminTransaction[];
  transaction: AdminTransaction;
  dataAsCSV: AdminTransaction[];
  isError: boolean;
}

const initialState: AdminTransactiuonsState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  transactions: [],
  dataAsCSV: [],
  transaction: {
    id: 1,
    orderTrackerId: 5,
    transactionId: '',
    amount: '',
    reference: '',
    fees: '',
    date: '',
    customerName: ' ',
    customerEmail: '',
    cardType: ' ',
    cardNumber: '',
    bank: '',
    country: '',
    ipAddress: '',
    paymentChannel: '',
    status: '',
    orderName: '',
    createdAt: '',
    updatedAt: '',
    orderItems: [
      {
        storeId: 1,
        quantity: 1,
        productId: 10,
        storeName: '',
        merchantId: 1,
        productName: '',
        shippingFee: 2459.8,
        productPrice: '',
      },
    ],
  },
};

const merchantOrdersSlice = createSlice({
  name: 'adminTransactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminTransactions.fulfilled, (state, { payload }) => {
        const data = payload?.data;

        state.status = 'succeeded';
        state.transactions = data?.map((item: any) => ({
          ...item,
          orderName: item.orderTracker.orderName,
          channel: item?.paymentChannel,
        }));
        state.dataAsCSV = payload.cleanDataForCSV.map((item: any) => ({
          orderName: item.orderTracker.orderName,
          amount: item.amount,
          customer: item.customerName,
          status: item.status,
          channel: item?.paymentChannel,
          reference: `'${item.reference}'`,
          createdAt: dateFormat(item.createdAt, 'mediumDate'),
        }));
      })
      .addCase(fetchAdminTransactions.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAdminTransaction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminTransaction.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.transaction = {
          ...data,
          orderItems: data?.orderTracker?.orderItems,
          orderName: data?.orderTracker?.orderName,
        };
      })
      .addCase(fetchAdminTransaction.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default merchantOrdersSlice.reducer;
