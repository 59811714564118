import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAdmins } from '../../../services/apiFactory';

export const fetchAdmins = createAsyncThunk('admins/get', async () => {
  return getAdmins()
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw err.response.data;
    });
});

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Admin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isActive?: boolean;
  roleId: number;
  confirm: boolean;
  notificationEndPoint?: string;
  lastLogin: string;
  phoneNumber: string;
  locations: string[];
  uniqueId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

interface ProfileState {
  status: PromiseStatus;
  message: string;
  admins: Admin[];
}

const initialState: ProfileState = {
  status: 'idle',
  message: '',
  admins: [],
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdmins.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.admins = payload?.data || [];
        state.message = 'Successfully fethced bitmarte admins.';
      })
      .addCase(fetchAdmins.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export default adminsSlice.reducer;
