import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRandomProducts } from '../../../services/apiFactory';

export const fetchRandomProducts = createAsyncThunk(
  'product/randomProducts',
  async () => {
    return getRandomProducts()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  merchantId: number;
  categoryId: number;
  name: string;
  description: [
    {
      children: [
        {
          text: string;
        }
      ];
    }
  ];
  pricing: [
    {
      price: string;
      costPrice: string;
      sellingPrice: string;
    }
  ];
  endDate: string;
  hostedImages: string[];
  sku: string;
}

export interface RandomProduct {
  id: number;
  name: string;
  products: Product[];
}

interface RandomProductsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  randomProducts: RandomProduct[];
}

const initialState: RandomProductsState = {
  status: 'idle',
  error: '',
  randomProducts: [],
};

const randomProductsSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRandomProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRandomProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.randomProducts = payload.data;
      })
      .addCase(fetchRandomProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products right now. Try again later.';
      });
  },
});

export default randomProductsSlice.reducer;
