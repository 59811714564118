import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const JWT = localStorage.getItem('jwt');

      if (JWT) {
        headers.set('authorization', `Bearer ${JWT}`);
      }

      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  endpoints: (builder) => ({
    readUser: builder.query({ query: () => '' }),
  }),
});
