import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAdminBitMarteHubOrders,
  getOrderStatuses,
} from 'services/apiFactory';
import { OrderStatus } from 'types';

export const fetchAdminBitMarteHubOrders = createAsyncThunk(
  'admin/bitmarte-hub/get-orders',
  async () => {
    return getAdminBitMarteHubOrders()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => {
        JSON.stringify(err.response);
      });
  }
);

export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export interface OrderItem {
  productId: number;
  merchantId: number;
  shippingFee: number;
  quantity: number;
  productPrice: number;
  productName: string;
  selected: boolean;
  productVariants: any[];
  replaceProductItem: any;
  storeName: string;
  hostedImages: string[];
}
interface orderActivity {
  id: number;
  orderTrackerId: number;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
export interface AdminOrder {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatus: OrderStatus;
  subtotal: string;
  shippingFee: string;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  assignedToBitmarte: boolean;
  shippingEvidence: string;
  partnerAssignOrderId: number;
  orderItems: OrderItem[];
  deliveryAddress: string;
  approvePayout: boolean;
  createdAt: string;
  orderReturnId: number;
  updatedAt: string;
  withinLagos: boolean;
  lagosMerchant: boolean;
  lagosCustomer: boolean;
  orderActivities: orderActivity[];
}
interface orderStatuses {
  id: number;
  code_name: string;
  known_name: string;
}
export interface shippingPartner {
  id: number;
  name: string;
}
interface AdminOrdersState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  orders: AdminOrder[];
  selectedOrder: AdminOrder;
  isError: boolean;
  orderStatuses: orderStatuses[];
  allowedOrderStatus: orderStatuses[];
  shippingPartners: shippingPartner[];
}

const initialState: AdminOrdersState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  orders: [],
  orderStatuses: [],
  allowedOrderStatus: [],
  shippingPartners: [],
  selectedOrder: {
    id: 3,
    orderReturnId: 1,
    assignedToBitmarte: false,
    orderName: '',
    orderNote: '',
    orderStatus: 'pending',
    subtotal: '',
    storeName: '',
    shippingFee: '',
    total: '',
    customerId: 1,
    merchantId: 1,
    shippingEvidence: '',
    withinLagos: false,
    lagosMerchant: false,
    lagosCustomer: false,
    orderItems: [],
    deliveryAddress: '',
    approvePayout: false,
    partnerAssignOrderId: 3,
    createdAt: '',
    updatedAt: '',
    orderActivities: [],
  },
};

export const clearOrderData = () => {
  const clearData = initialState.orders;
  return clearData;
};

const adminBitMarteHubOrdersSlice = createSlice({
  name: 'adminBitMarteHubOrders',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminBitMarteHubOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminBitMarteHubOrders.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.orders = [];

        state.orders =
          data?.map((item: any) => ({
            ...item,
            orderStatus: state.orderStatuses?.find(
              (i) => i.id === item?.orderStatusId
            )?.code_name,
          })) || [];
        state.status = 'succeeded';
      })
      .addCase(fetchAdminBitMarteHubOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderStatuses = data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setStatusMessage } = adminBitMarteHubOrdersSlice.actions;
export default adminBitMarteHubOrdersSlice.reducer;
