import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from 'state/features/products/productsSlice';
import { getMerchantProducts } from '../../../services/apiFactory';

export const fetchMerchantProducts = createAsyncThunk(
  'product/merchantProducts',
  async ({ id }: { id: number }) => {
    return getMerchantProducts(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface ProductState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  products: Product[];
}

const initialState: ProductState = {
  status: 'idle',
  error: '',
  products: [],
};

const adminStoreProductsSlice = createSlice({
  name: 'adminStoreProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data?.data) {
          state.products = payload.data.data;
        } else {
          state.products = [];
        }
      })
      .addCase(fetchMerchantProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default adminStoreProductsSlice.reducer;
