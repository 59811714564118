import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from 'state/features/customerProducts/customerProductsSlice';
import { getTopMerchantsProduct } from '../../../services/apiFactory';

// Axios Request functions for merchant discount
export const fetchTopmerchantsProducts = createAsyncThunk(
  'product/fetchTopmerchantsProducts',
  async () => {
    return getTopMerchantsProduct()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export type Merchant = {
  merchantId: number;
  storeId: number;
  merchant: {
    id: number;
    firstName: string;
    lastName: string;
    store: {
      id: number;
      storeName: string;
      storeUrl: string;
      logo?: string;
    };
    products: Product[];
  };
};
interface topMerchantProductsState {
  status: PromiseStatus;
  merchants: Merchant[];
  error: string;
}

const initialState: topMerchantProductsState = {
  status: 'idle',
  merchants: [],
  error: '',
};

const topMerchantProductsSlice = createSlice({
  name: 'topMerchants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopmerchantsProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopmerchantsProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.merchants = payload?.data?.data
          ? Object.values(payload?.data?.data)
          : [];
      })
      .addCase(fetchTopmerchantsProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || '';
      });
  },
});

export default topMerchantProductsSlice.reducer;
