import { createSlice } from '@reduxjs/toolkit';
import { shippingApiSlice } from '../api/shippingApiSlice';

export const {
  endpoints,
  useReadShippingsQuery,
  useReadShippingQuery,
  useUpdateShippingMutation,
} = shippingApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    readShippings: builder.query({
      query: () => '/vehicle',
    }),
    readShipping: builder.query({
      query: (id: string) => `/vehicle/${id}`,
    }),
    updateShipping: builder.mutation({
      query: (body) => ({
        url: `/vehicle/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { reducer } = createSlice({
  name: 'vehicle',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(endpoints.readShippings.matchFulfilled, () => {
      // TODO
    });
  },
});
