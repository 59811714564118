import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCategoryProducts,
  getChildCategoryProducts,
  getSubCategoryProducts,
} from '../../../services/apiFactory';

export const getProductsByChildCategory = createAsyncThunk(
  'product/childCategory',
  async ({
    limit,
    offset,
    childCategoryId,
  }: {
    limit: number;
    offset: number;
    childCategoryId: number;
  }) => {
    return getChildCategoryProducts({ limit, offset, childCategoryId })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const getProductsBySubCategory = createAsyncThunk(
  'product/subCategory',
  async ({
    limit,
    offset,
    subCategoryId,
  }: {
    limit: number;
    offset: number;
    subCategoryId: number;
  }) => {
    return getSubCategoryProducts({ limit, offset, subCategoryId })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const getProductsByCategory = createAsyncThunk(
  'product/category',
  async ({
    limit,
    offset,
    categoryId,
  }: {
    limit: number;
    offset: number;
    categoryId: number;
  }) => {
    return getCategoryProducts({ limit, offset, categoryId })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  name: string;
  description: {
    head: string;
  };
  pricing: [
    {
      price: string;
      sellingPrice: string;
    }
  ];
  hostedImages: string[];
  rating?: number;
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  count: number;
  products: Product[];
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  count: 0,
  products: [],
};

const filteredProductsSlice = createSlice({
  name: 'filteredProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsByChildCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductsByChildCategory.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data?.data) {
          state.products = payload.data.data;
          state.count = payload.data.count;
        } else {
          state.products = [];
          state.count = 0;
        }
      })
      .addCase(getProductsByChildCategory.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      })
      .addCase(getProductsBySubCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductsBySubCategory.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data?.data) {
          state.products = payload.data.data;
          state.count = payload.data.count;
        } else {
          state.products = [];
          state.count = 0;
        }
      })
      .addCase(getProductsBySubCategory.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      })
      .addCase(getProductsByCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProductsByCategory.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data?.data) {
          state.products = payload.data.data;
          state.count = payload.data.count;
        } else {
          state.products = [];
          state.count = 0;
        }
      })
      .addCase(getProductsByCategory.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default filteredProductsSlice.reducer;
