import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  addToCart as atc,
  deleteCartItem,
  getCartItems,
  applyCouponCode,
  makePayment,
  placeOrder,
  getShippingAddresses,
  generateShippingFee as computeShippingFee,
  addShippingAddress as ASD,
  setDefaultShippingAddress,
  createTransaction as CT,
  deleteShippingAddress,
  getAllCouponCode,
  createNewCustomerOrder,
} from '../../../services/apiFactory';
import { CartItemProps } from '../../../interfaces/customer/cart';
import toast from 'react-hot-toast';

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async (payload: object) => {
    return atc(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
);
export const createTransaction = createAsyncThunk(
  'cart/createTransaction',
  async (payload: object) => {
    return CT(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => console.log(err.response));
  }
);

export const addShippingAddress = createAsyncThunk(
  'cart/addShippingAddress',
  async (payload: object) => {
    return ASD(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => console.log(err.response));
  }
);

export const removeShippingAddress = createAsyncThunk(
  'cart/removeShippingAddress',
  async (id: string) => {
    return deleteShippingAddress(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => console.log(err.response));
  }
);

export const createCustomerOrder = createAsyncThunk(
  'cart/createNewCustomerOrder',
  async (payload: object) => {
    return createNewCustomerOrder(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response.data)));
  }
);
export const checkout = createAsyncThunk(
  'cart/checkout',
  async (payload: object) => {
    return placeOrder(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response.data)));
  }
);

export const generateShippingFee = createAsyncThunk(
  'cart/generateShippingFee',
  async (payload: object) => {
    return computeShippingFee(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response.data)));
  }
);
export const updateDefaultShippingAddress = createAsyncThunk(
  'cart/updateDefaultShippingAddress',
  async (payload: { id: number; data: object }) => {
    const { id, data } = payload;
    return setDefaultShippingAddress(id, data)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => console.log(JSON.stringify(err.response.data)));
  }
);

export const applyCustomerCouponCode = createAsyncThunk(
  'cart/applyCoupon',
  async (payload: object) => {
    return applyCouponCode(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        const errors = err.response.data.message;
        return errors;
      });
  }
);

export const getAllCustomerCouponCode = createAsyncThunk(
  'cart/getAllCoupons',
  async (payload: object) => {
    return getAllCouponCode(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log('data', data);
          return data;
        }
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        const errors = err.response.data.message;
        return errors;
      });
  }
);

export const submitOrder = createAsyncThunk(
  'cart/submitOrder',
  async (payload: object) => {
    return makePayment(payload)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchCart = createAsyncThunk('cart/fetchCart', async () => {
  return getCartItems()
    .then((res) => {
      if (res) {
        const data = res.data;

        return data;
      }
    })
    .catch((err) => JSON.stringify(err.response));
});

export const fetchCustomerShippingAddresses = createAsyncThunk(
  'cart/fetchCustomerShippingAddresses',
  async () => {
    return getShippingAddresses()
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const deleteSingleItemFromCart = createAsyncThunk(
  'cart/deleteSingleItemFromCart',
  async (payload: object) => {
    return deleteCartItem(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export interface shippingFeesProps {
  id: number;
  name: string;
  shippingFee: number;
}

interface CartState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMsg: string;
  cartItemsList: CartItemProps[];
  isError: boolean;
  discountedProduct: any;
  cartTotal: number;
  discount: number;
  orderPlaced: boolean;
  checkOutDiscountCode: any;
  shippingFees: shippingFeesProps[];
  shippingFeeTotal: number;
  shippingAddresses: ShippingAddressType[];
  weight: number;
}

export interface ShippingAddressType {
  id: number;
  fullName: string;
  phoneNumber: string;
  emailAddress: string;
  stateOrRegion: string;
  cityInLagos?: string;
  address: string;
  default: boolean;
}
const initialState: CartState = {
  status: 'idle',
  statusMsg: '',
  isError: false,
  discountedProduct: null,
  cartItemsList: [],
  cartTotal: 0,
  discount: 0,
  checkOutDiscountCode: [],
  orderPlaced: false,
  shippingFees: [],
  shippingAddresses: [],
  shippingFeeTotal: 0,
  weight: 0,
};

export const clearCart = () => {
  const clearData = initialState.cartItemsList;
  return clearData;
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    removeItem: (state, { payload }) => {
      const { id } = payload;
      const filtered = state.cartItemsList.filter((item) => item.id !== id);
      state.cartItemsList = filtered;
    },
    addItemToCart: (state, { payload }) => {
      // console.log(payload);
      const cart = state.cartItemsList;
      cart.push(payload);
      state.cartItemsList = cart;
    },

    setCart: (state, { payload }) => {
      state.cartItemsList = payload;
    },
    toggleWatchItem: (state, { payload }) => {
      const { id } = payload;
      const tempCart = [...state.cartItemsList];
      const itemIndex = tempCart.findIndex((item) => item.id === id);
      let update = tempCart[itemIndex];
      update = {
        ...update,
        // like: !update.like,
      };
      const newArray = tempCart;
      newArray[itemIndex] = update;
      state.cartItemsList = newArray;
    },

    increaseItemCount: (state, { payload }) => {
      const { id } = payload;
      const tempCart = [...state.cartItemsList];
      const itemIndex = tempCart.findIndex((item) => item.id === id);
      let update = tempCart[itemIndex];
      let count = update.productVariant.quantity;
      count++;
      update = {
        ...update,
        productVariant: {
          ...update.productVariant,
          quantity: count,
        },
      };
      const newArray = tempCart;
      newArray[itemIndex] = update;
      state.cartItemsList = newArray;
    },
    onChangeItemCount: (state, { payload }) => {
      const { id, qty } = payload;
      const tempCart = [...state.cartItemsList];
      const itemIndex = tempCart.findIndex((item) => item.id === id);
      let update = tempCart[itemIndex];
      update = {
        ...update,
        productVariant: {
          ...update.productVariant,
          quantity: qty,
        },
      };
      const newArray = tempCart;
      newArray[itemIndex] = update;
      state.cartItemsList = newArray;
    },

    decreaseItemCount: (state, { payload }) => {
      const { id } = payload;
      const tempCart = [...state.cartItemsList];
      const itemIndex = tempCart.findIndex((item) => item.id === id);
      let update = tempCart[itemIndex];
      let count = update.productVariant.quantity;
      count--;
      update = {
        ...update,
        productVariant: {
          ...update.productVariant,
          quantity: count,
        },
      };
      const newArray = tempCart;
      newArray[itemIndex] = update;
      state.cartItemsList = newArray;
    },
    setCartTotal: (state, { payload }) => {
      state.cartTotal = payload;
    },
    setDiscount: (state, { payload }) => {
      state.discount = payload;
    },
    setDiscountedProduct: (state, { payload }) => {
      state.discountedProduct = payload;
    },
    clearStatusMsg: (state, { payload }) => {
      (state.isError = payload.isError), (state.statusMsg = payload.statusMsg);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCart.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.cartItemsList = [];
        state.cartItemsList =
          data?.cartItemsData.map((item: any) => ({
            ...item,
            selected: false,
          })) || [];

        let total = 0;
        data?.cartItemsData.forEach(
          (item: CartItemProps) =>
            (total +=
              item.productVariant.quantity *
              Number(item.pricing[0].sellingPrice))
        );
        total = Number(total.toFixed(2));
        state.cartTotal = total;
        state.status = 'succeeded';
      })
      .addCase(fetchCart.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(addToCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(addToCart.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(applyCustomerCouponCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(applyCustomerCouponCode.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        // console.log(`data`);
        // console.log('payload', payload || []);
        if (payload.success === true) {
          const sumTotal =
            data &&
            data.reduce(
              (a: any, b: any) =>
                Number(a) +
                Number(
                  b.productPrice * b.quantity - b.discountedPrice * b.quantity
                ),
              0
            );
          // console.log(sumTotal);
          state.discount = sumTotal;
          state.status = 'succeeded';
          state.isError = false;
          state.statusMsg = payload.message;
        } else {
          state.isError = true;
          state.statusMsg = payload;
        }
      })
      .addCase(applyCustomerCouponCode.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(generateShippingFee.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(getAllCustomerCouponCode.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.checkOutDiscountCode = payload;
      })
      .addCase(getAllCustomerCouponCode.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getAllCustomerCouponCode.pending, (state) => {
        // state.status = 'loading';
      })

      .addCase(generateShippingFee.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        // console.log(data);
        state.status = 'succeeded';
        state.shippingFees = data;
        let total = 0;
        data?.forEach((item: shippingFeesProps) => (total += item.shippingFee));
        total = Number(total.toFixed(2));
        state.shippingFeeTotal = total;
      })
      .addCase(generateShippingFee.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteSingleItemFromCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSingleItemFromCart.fulfilled, (state, { payload }) => {
        const data = payload?.data?.data;
        state.status = 'succeeded';
        // removeItem({ id: data.id });
      })
      .addCase(deleteSingleItemFromCart.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(addShippingAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addShippingAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(addShippingAddress.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomerShippingAddresses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCustomerShippingAddresses.fulfilled,
        (state, { payload }) => {
          const data = payload?.data?.rows;
          // console.log(data)
          state.status = 'succeeded';
          state.shippingAddresses = data;
        }
      )
      .addCase(fetchCustomerShippingAddresses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateDefaultShippingAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDefaultShippingAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateDefaultShippingAddress.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createTransaction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTransaction.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(createTransaction.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  increaseItemCount,
  decreaseItemCount,
  setCart,
  removeItem,
  toggleWatchItem,
  addItemToCart,
  setCartTotal,
  setDiscount,
  setDiscountedProduct,
  setStatus,
  clearStatusMsg,
  onChangeItemCount,
} = cartSlice.actions;
export default cartSlice.reducer;
