import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizedUser } from 'services/auth';
import { useCookie } from 'next-cookie';
import { customerLogin } from 'services/apiFactory';

const cookie = useCookie();
// Axios Request functions for merchant discount
export const loginCustomer = createAsyncThunk(
  'Customer/Login',
  async (payload: object) => {
    return customerLogin(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        setAuthorizedUser('token', data);
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);
// console.log(user?.token)
// initialState

const checkUser = cookie.get('token');

// if(checkUser){
//   console.log('i am in')
// }else{
//   console.log('i am out')
// }
const user = checkUser;
const initialState = user
  ? {
      isLoggedIn: true,
      statusMessage: '',
      role: 'customer',
      isError: false,
      userAccess: cookie.get('token'),
    }
  : {
      isLoggedIn: false,
      statusMessage: '',
      role: '',
      userAccess: null,
      isError: true,
    };

const customerLoginSlice = createSlice({
  name: 'customerLogin',
  initialState,
  reducers: {
    setStatusMessage: (state: { statusMessage: any }, { payload }: any) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginCustomer.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isLoggedIn = true;
          state.isError = false;
        } else {
          state.isLoggedIn = false;
          state.isError = true;
          state.statusMessage = payload;
        }
      })

      .addCase(loginCustomer.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});
export const { setStatusMessage } = customerLoginSlice.actions;
export default customerLoginSlice.reducer;
