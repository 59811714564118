import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerName } from '../../../../services/apiFactory';

// 'customerOrderInfo/getCustomerOrderInfo',
export const getCustomerInfo = createAsyncThunk('customerInfo', async () => {
  return getCustomerName()
    .then((res: any) => {
      // console.log(res)
      const data = res.data;
      return data;
    })
    .catch((err: any) => {
      throw err.response.data;
    });
});

export interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface OrdersState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  customer: Customer;
}

const initialState: OrdersState = {
  status: 'idle',
  error: '',
  customer: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
};

const customerInfoSlice = createSlice({
  name: 'customerInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerInfo.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.customer = payload.data;
      })
      .addCase(getCustomerInfo.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message ||
          'Could not get information on this customer. Try again later.';
      });
  },
});

export default customerInfoSlice.reducer;
