import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSingleProductDetails,
  getSingleProductReviews,
  getFollowers,
  getMerchantPolicy,
  followMerchant,
  getFollowing,
} from '../../../services/apiFactory';

// Axios Request functions for merchant discount
// http://ec2-50-17-10-67.compute-1.amazonaws.com/api/v1/reviews/2
export const fetchProductDetails = createAsyncThunk(
  'product/getSingleProductDetails',
  async (id: any) => {
    return getSingleProductDetails(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data)
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchProductReviews = createAsyncThunk(
  'product/getProductReview',
  async (id: any) => {
    return getSingleProductReviews(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchfollowers = createAsyncThunk(
  'product/getfollowers',
  async (id: any) => {
    return getFollowers(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchMerchantPolicy = createAsyncThunk(
  'product/getMerchantPolicy',
  async (id: any) => {
    return getMerchantPolicy(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const followMerchanStore = createAsyncThunk(
  'product/followStore',
  async (id: any, { dispatch }) => {
    return followMerchant(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          // console.log(data);

          dispatch(fetchFollowing(id));
          return data;
        }
      })
      .catch((err) => {
        const errors = err.response.data;
        console.log(errors);
        return errors;
      });
  }
);

export const fetchFollowing = createAsyncThunk(
  'product/getFollowing',
  async (id: any) => {
    return getFollowing(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          console.log(data);
          return data;
        }
      })
      .catch((err) => {
        const errors = err.response.data;
        console.log(errors);
        return errors;
      });
  }
);

export interface ProductState {
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  id: number | null;
  product: any | null;
  reviews: any | null;
  policy: any | null;
  followers: number;
  following: boolean;
}

const initialState: ProductState = {
  error: '',
  status: 'idle',
  id: null,
  product: [],
  reviews: [],
  policy: [],
  followers: 0,
  following: false,
};
const singleProductDetailsSlice = createSlice({
  name: 'singleProductDetails',
  initialState,
  reducers: {
    setFollowing: (
      state: {
        following: any;
      },
      { payload }: any
    ) => {
      state.following = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Product details
      .addCase(fetchProductDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductDetails.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';

        if (payload?.message === 'Success') {
          state.product = payload.data;
        } else {
          state.product = null;
        }
      })
      .addCase(fetchProductDetails.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products details. Try again later.';
      })

      // Reviews
      .addCase(fetchProductReviews.fulfilled, (state, { payload }) => {
        // console.log(payload.data);
        state.reviews = payload.data;
      })
      // Policy
      .addCase(fetchMerchantPolicy.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.policy = payload.data.rows;
      })
      // Followers
      .addCase(fetchfollowers.fulfilled, (state, { payload }) => {
        // console.log(payload.data);
        state.followers = payload.data;
      })

      // Following store
      .addCase(fetchFollowing.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.following = payload.data?.following;
      });
  },
});
export const { setFollowing } = singleProductDetailsSlice.actions;
export default singleProductDetailsSlice.reducer;
