import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { useCookie } from 'next-cookie';
import { RootState } from 'state/store';

const url = process.env.NEXT_PUBLIC_THEME_BASE_URL;

export interface MerchantTheme {
  id: number;
  themeId: string;
  merchantId: string;
  isActive: boolean;
  type: string;
  price: string;
  paymentStatus: null;
  txnId: null;
  createdAt: Date;
  updatedAt: Date;
  theme: Theme;
  themeHeader: ThemeHeader;
  themeBody: ThemeBody;
  themeColor: ThemeColor;
  themeSocialMedia: ThemeSocialMedia;
}

export interface Theme {
  id: number;
  name: string;
  slug: string;
  image: string;
  type: string;
  price: string;
  description: null;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ThemeBody {
  id?: number;
  merchantThemeId?: number;
  products?: null;
  testimonials: ThemeTestimonials;
  promoImage: string;
  bannerImage: string;
  bannerMainText: string[] | string;
  bannerSubText: string;
}

export interface ThemeColor {
  id?: number;
  merchantThemeId?: number;
  headingsAndLinks: string;
  bodyText: string;
  salePrice: string;
  primaryButton: string;
  primaryLabel: string;
  fieldText: string;
  fieldBorder: string;
  fieldBackground: string;
}

export interface ThemeHeader {
  id?: number;
  logo: string | undefined;
  email: string[] | undefined | string;
  storeName?: undefined | string;
  phoneNumber: string[] | undefined | string;
}

export type ThemeTestimonials = {
  author: string;
  description: string;
  id: number;
}[];

export interface ThemeSocialMedia {
  id?: number;
  merchantThemeId?: number;
  twitter: string | undefined;
  facebook: string | undefined;
  pinterest: string | undefined;
  instagram: string | undefined;
  tumblr: string | undefined;
  snapchat: string | undefined;
  youtube: string | undefined;
  tiktok: string | undefined;
  whatsapp: string | undefined;
  vimeo: string | undefined;
}

type ThemeSliceType = {
  bitmartThemes: Theme[];
  merchantThemes: MerchantTheme[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
};

export const fetchBitmartThemes = createAsyncThunk(
  'themes/fetchBitmartThemes',
  async () => {
    const cookie = useCookie();
    const token = cookie.get('token');
    const response = await axios.get(`${url}/api/merchant/themes`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  }
);

export const fetchMerchantThemes = createAsyncThunk(
  'themes/fetchMerchantThemes',
  async () => {
    const cookie = useCookie();
    const token = cookie.get('token');
    const response = await axios.get(`${url}/api/merchant/theme`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  }
);

export const activateTheme = createAsyncThunk(
  'themes/activateTheme',
  async (id: string | number) => {
    const cookie = useCookie();
    const token = cookie.get('token');

    const response = await axios.patch(
      `${url}/api/merchant/theme/activate`,
      {
        themeId: id,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  }
);

export const purchaseTheme = createAsyncThunk(
  'themes/purchaseTheme',
  async (id: string | number) => {
    const cookie = useCookie();
    const token = cookie.get('token');

    const response = await axios.post(
      `${url}/api/merchant/theme/purchase`,
      {
        themeId: id,
        type: 'free',
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  }
);

const slice = 'themes';

const initialState: ThemeSliceType = {
  bitmartThemes: [],
  merchantThemes: [],
  status: 'idle',
};

export const { reducer, actions } = createSlice({
  name: slice,
  initialState,
  reducers: {
    setStatus: (state: { status: any }, { payload }: any) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBitmartThemes.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchMerchantThemes.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      fetchBitmartThemes.fulfilled,
      (state, { payload }: PayloadAction<Theme[]>) => {
        state.bitmartThemes = payload;
        state.status = 'succeeded';
      }
    );
    builder.addCase(
      fetchMerchantThemes.fulfilled,
      (state, { payload }: PayloadAction<MerchantTheme[]>) => {
        state.merchantThemes = payload;
        state.status = 'succeeded';
      }
    );
  },
});

export const { setStatus } = actions;
export const getBitmartThemes = (state: RootState) =>
  state[slice].bitmartThemes;
export const getMerchantThemes = (state: RootState) =>
  state[slice].merchantThemes;
