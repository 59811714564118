import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrderStatuses,
  updateShippingPartnerOrderStatus as USPOS,
  getShippingPartnerOrders,
  updateOrderStatus,
  getShippingPartnerOrder,
  updateDispatcherNumber,
  declineShippingPartnerOrder,
} from '../../../services/apiFactory';
import { orderCurrentStatuses } from 'state/features/orders/merchantOrdersSlice';

import { OrderStatus } from 'types';

export const fetchShippingPartnerOrders = createAsyncThunk(
  'shippingPartnerOrders/getOrders',
  async () => {
    return getShippingPartnerOrders()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchCurrentOrderStatus = createAsyncThunk(
  'shippingPartner/getCurrentStatus',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const declineOrder = createAsyncThunk(
  'shippingPartnerOrders/declineSingleOrder',
  async (data: { id: number }) => {
    const { id } = data;
    const response = await declineShippingPartnerOrder(id);
    return response.data;
  }
);

export const fetchSingleShippingPartnerOrder = createAsyncThunk(
  'shippingPartnerOrders/getSingleOrder',
  async (data: { id: number }) => {
    const { id } = data;
    return getShippingPartnerOrder(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const updateShippingPartnerOrderStatus = createAsyncThunk(
  'shippingPartnerOrders/updateShippingPartnerOrderStatus',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateOrderStatus(id, payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const updateDispatcherPhoneNumber = createAsyncThunk(
  'shippingPartnerOrders/updateDispatcherPhoneNumber',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateDispatcherNumber(id, payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

// Constants discount type decleared
interface OrderItem {
  productId: number;
  merchantId: number;
  shippingFee: number;
  quantity: number;
  productPrice: number;
  productName: string;
  productVariants: variant[];
}
interface variant {
  color: string;
}
interface orderActivity {
  id: number;
  orderTrackerId: number;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
export interface ShippingPartnerOrder {
  dispatchPhoneNumber: any;
  id: number;
  orderName: string;
  orderNote: string;
  orderStatus: OrderStatus;
  subtotal: string;
  shippingFee: string;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  assignedToBitmarte: boolean;
  shippingEvidence: string;
  orderItems: OrderItem[];
  deliveryAddress: string;
  approvePayout: boolean;
  createdAt: string;
  updatedAt: string;
  withinLagos: boolean;
  orderActivities: orderActivity[];
  currentOrderStatus: string;
}
interface orderStatuses {
  id: number;
  code_name: string;
  known_name: string;
}
interface ShippingPartnerOrderState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  orders: ShippingPartnerOrder[];
  selectedOrder: any;
  isError: boolean;
  orderStatuses: orderStatuses[];
  orderCurrentStatus: orderCurrentStatuses[];
}

const initialState: ShippingPartnerOrderState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  orders: [],
  orderStatuses: [],
  selectedOrder: {
    id: 3,
    storeName: '',
    assignedToBitmarte: false,
    orderName: '#OHGOALEN',
    orderNote: 'Drop at the gate',
    orderStatus: 'pending',
    currentOrderStatus: '',
    subtotal: '258172',
    shippingFee: '6007000',
    total: '6265172',
    customerId: 1,
    merchantId: 1,
    shippingEvidence: '',
    withinLagos: false,
    orderItems: [
      {
        productId: 1,
        merchantId: 1,
        shippingFee: 6007000,
        quantity: 4,
        productPrice: 64543,
        productName: '',
        productVariants: [
          {
            color: 'red',
          },
        ],
      },
    ],
    deliveryAddress: '14 aliu animashaun avenue',
    approvePayout: false,
    createdAt: '2022-03-22T10:03:28.806Z',
    updatedAt: '2022-03-22T10:03:28.806Z',
    orderActivities: [
      {
        id: 3,
        orderTrackerId: 3,
        activity: 'customer created order',
        createdAt: '2022-03-22T10:03:28.813Z',
        updatedAt: '2022-03-22T10:03:28.813Z',
      },
    ],
  },
  orderCurrentStatus: [],
};

export const clearOrderData = () => {
  const clearData = initialState.orders;
  return clearData;
};

const shippingPartnerOrdersSlice = createSlice({
  name: 'shippingPartnerOrder',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingPartnerOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShippingPartnerOrders.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.orders = [];

        state.orders =
          data?.map((item: any) => ({
            ...item,
            orderStatus: state.orderStatuses.find(
              (i) => i.id === item.orderStatusId
            )?.code_name,
          })) || [];
        state.status = 'succeeded';
      })
      .addCase(fetchShippingPartnerOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleShippingPartnerOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchSingleShippingPartnerOrder.fulfilled,
        (state, { payload }) => {
          const data = payload?.data;
          if (data) {
            state.selectedOrder = {
              ...data[0],
              currentOrderStatus: state.orderCurrentStatus.find(
                (i) => i.id === data[0].orderStatusId
              )?.code_name,
            };

            state.status = 'succeeded';
          }
        }
      )
      .addCase(fetchSingleShippingPartnerOrder.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateShippingPartnerOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        updateShippingPartnerOrderStatus.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
        }
      )
      .addCase(updateShippingPartnerOrderStatus.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        // console.log(`data should be here ${data}`);
        state.status = 'succeeded';
        state.orderStatuses = data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCurrentOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentOrderStatus.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderCurrentStatus = data;
      })
      .addCase(fetchCurrentOrderStatus.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(updateDispatcherPhoneNumber.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDispatcherPhoneNumber.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(declineOrder.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
      })
      .addCase(declineOrder.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        // state.orders = state.orders.filter((order) => order.id !== payload.id);
      });
  },
});

export const { setStatusMessage } = shippingPartnerOrdersSlice.actions;
export default shippingPartnerOrdersSlice.reducer;
