import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  onBoarding,
  getStates,
  getOnBoardingInfo,
} from '../../../services/apiFactory';

// Axios Request functions for merchant discount
export const onboardMerchant = createAsyncThunk(
  'merchant/onboarding',
  async () => {
    return onBoarding()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

// Get onboarding info
export const fetchOnBoardingInfo = createAsyncThunk(
  'merchant/fetchInfo',
  async () => {
    return getOnBoardingInfo()
      .then((res) => {
        const data = res.data;
        // console.log(data)
        return data;
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
// Get all state and state codes
export const fetchAllstates = createAsyncThunk(
  'merchant/fetchStats',
  async () => {
    return getStates()
      .then((res) => {
        const data = res.data;
        // console.log(data)
        return data;
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

interface OnboardingState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';

  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  onBoardingInfoStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  percentageComplete: number;
  states: any;
  onBoardingInfo: any | null;
}

const initialState: OnboardingState = {
  status: 'idle',
  onBoardingInfoStatus: 'idle',
  percentageComplete: 25,
  states: [],
  onBoardingInfo: null,
};

const onaboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setPercentageComplete: (state, action) => {
      state.percentageComplete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllstates.fulfilled, (state, { payload }) => {
      state.status = 'succeeded';
      state.states = payload.locations;
    });
    builder.addCase(fetchOnBoardingInfo.fulfilled, (state, { payload }) => {
      state.onBoardingInfoStatus = 'succeeded';
      // console.log(payload);
      state.onBoardingInfo = payload.data;
    });
  },
});

export const { setPercentageComplete } = onaboardingSlice.actions;
export default onaboardingSlice.reducer;
