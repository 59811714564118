import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { contactUsFunc } from 'services/apiFactory';

export const contactUs = createAsyncThunk(
  'contact/us/func',
  async ({ payload }: { payload: object }) => {
    return contactUsFunc(payload)
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

const initialState = {
  status: '',
  error: true,
};

const contactUsSlice = createSlice({
  name: 'contactUsFuncData',
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(contactUs.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.status = payload?.message;
          state.error = false;
        } else {
          state.error = true;
          state.status = payload;
        }
      })
      .addCase(contactUs.rejected, (state, { error }) => {
        state.status = 'Check network connection';
        state.error = true;
      });
  },
});

export const { setStatus } = contactUsSlice.actions;
export default contactUsSlice.reducer;
