import { useCookie } from 'next-cookie';
const cookie = useCookie();

export const setAuthorizedUser = (key, value) => {
  // User token set
  cookie.set(key, value.token, {
    httpOnly: false,
    secure: true,
    sameSite: 'strict',
    path: '/',
  });

  // User info set
  const userInfo = JSON.stringify(value.data);
  // console.log(userInfo);
  localStorage.setItem('user', userInfo);
};

export const userLogOut = () => {
  cookie.remove('token');
  localStorage.clear('user');
};
