import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { VariantsCombination } from 'interfaces/merchant/products/VariantProps';
import { editProduct, getSingleProduct } from '../../../services/apiFactory';

export const getSingleMerchantProduct = createAsyncThunk(
  'merchant/product/get ',
  async (id: number) => {
    return getSingleProduct(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const editMerchantProduct = createAsyncThunk(
  'merchant/product/edit ',
  async ({ data, id }: { data: any; id: number }) => {
    return editProduct(data, id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Product {
  id?: number;
  name: string;
  description: any[];
  category: string;
  categoryId: number;
  subCategory: string;
  subCategoryId: number;
  childCategory: string;
  childCategoryId: number;
  hostedImages: string[];
  pricing: [
    {
      costPrice: number;
      sellingPrice: number;
      price: number;
    }
  ];
  sku: string;
  quantity: number;
  weight: number;
  dimension: {
    length: number;
    width: number;
    height: number;
  };
  variant: VariantsCombination[];
  weightUnit: string;
  dimensionUnit: string;
  variantProperties: {
    type: string;
    options: string[];
  }[];
  contSalesOutOfStock?: boolean;
  stockAlertUnits?: number;
  productVariant?: {
    variant: VariantsCombination[];
  };
  IsDigitalProduct?: boolean;
}

interface getProductsState {
  status: PromiseStatus;
  editStatus: PromiseStatus;
  product: Product;
  images: File[];
  variants: {
    property: string;
    options: string[];
  }[];
  message: string;
}

const initialState: getProductsState = {
  status: 'idle',
  editStatus: 'idle',
  product: {
    name: '',
    description: [],
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    childCategory: '',
    childCategoryId: 0,
    hostedImages: [],
    pricing: [
      {
        costPrice: 0,
        sellingPrice: 0,
        price: 0,
      },
    ],
    quantity: 0,
    sku: '',
    weight: 0,
    weightUnit: '',
    dimension: {
      length: 0,
      width: 0,
      height: 0,
    },
    dimensionUnit: '',
    variant: [],
    variantProperties: [],
    contSalesOutOfStock: false,
    stockAlertUnits: 0,
    IsDigitalProduct: false,
  },
  images: [],
  variants: [
    {
      property: '',
      options: [],
    },
  ],
  message: '',
};

const editProductSlice = createSlice({
  name: 'editProduct',
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = {
        ...state.product,
        ...action.payload,
      };
    },
    setImages: (state, action: { payload: File[] }) => {
      state.images = action.payload;
    },
    setStatus: (state, action: { payload: PromiseStatus }) => {
      state.status = action.payload;
    },
    setEditeStatus: (state, action: { payload: PromiseStatus }) => {
      state.editStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editMerchantProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editMerchantProduct.fulfilled, (state) => {
        state.editStatus = 'succeeded';
        state.message = 'Successfully edited product.';
        state.product = initialState.product;
        state.images = [];
      })
      .addCase(editMerchantProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(getSingleMerchantProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleMerchantProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.editStatus = 'idle';
        state.message = 'Successfully fetched product.';
        if (payload?.data) {
          state.product = payload.data;
        }
      })
      .addCase(getSingleMerchantProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export const { setProduct, setImages, setStatus, setEditeStatus } =
  editProductSlice.actions;
export default editProductSlice.reducer;
