import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { merchantSignIn } from '../../../services/apiFactory';
import { setAuthorizedUser } from 'services/auth';
import { useCookie } from 'next-cookie';

const cookie = useCookie();
// Axios Request functions for merchant discount
export const merchantAuth = createAsyncThunk(
  'merchantSignIn',
  async (payload: object) => {
    // console.log(payload)
    return merchantSignIn(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        setAuthorizedUser('token', data);
        return data;
      })
      .catch((err) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// console.log(user?.token)
// initialState
const checkUser = cookie.get('token');
const user = checkUser;
const initialState = user
  ? {
      isLoggedIn: true,
      role: 'merchant',
      statusMessage: '',
      isError: false,
      userAccess: cookie.get('token'),
    }
  : {
      isLoggedIn: false,
      statusMessage: '',
      merchant: '',
      userAccess: null,
      isError: true,
    };

const merchanSignInSlice = createSlice({
  name: 'merchantSignIn',
  initialState,
  reducers: {
    setStatusMessage: (state: { statusMessage: any }, { payload }: any) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(merchantAuth.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isLoggedIn = true;
          state.isError = false;
        } else {
          state.isLoggedIn = false;
          state.isError = true;
          state.statusMessage = payload;
        }
      })

      .addCase(merchantAuth.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});
export const { setStatusMessage } = merchanSignInSlice.actions;
export default merchanSignInSlice.reducer;
