import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPricing, getSubscriptionInfo } from 'services/apiFactory';

export const getAllPricingList = createAsyncThunk('allPricing', async () => {
  return getPricing()
    .then((res: any) => {
      // console.log(res)
      const data = res.data;
      return data;
    })
    .catch((err: any) => {
      throw err.response.data;
    });
});
export const getAllSubscriptionInfo = createAsyncThunk(
  'subscriptionInfo',
  async () => {
    return getSubscriptionInfo()
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Pricing {
  id: number;
  name: string;
  payment_interval: number;
  subProductId: number;
  createdAt: string;
  updatedAt: string;
  subscriptionPrice: {
    id: number;
    price: string;
    subProoductPlanId: number;
    currency: string;
    createdAt: string;
    updatedAt: string;
  };
  subscriptionProduct: {
    id: number;
    name: string;
    description: string;
    onelineStore: boolean;
    ecommerceMarketPlace: boolean;
    freeSubDomain: boolean;
    customDomain: boolean;
    productLimit: string;
    nextDaySettlement: boolean;
    socialMediaIntegration: boolean;
    staffLimit: string;
    basicAnalytics: boolean;
    communicationGateway: boolean;
    shipmentGateway: boolean;
    discountManagement: boolean;
    advancedAnalytics: boolean;
    websiteCustomization: boolean;
    financialAccountingIntegration: boolean;
    additionalStores: string;
    createdAt: string;
    updatedAt: string;
  };
}

interface PricingState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  pricingList: Pricing[];
}

const initialState: PricingState = {
  status: 'idle',
  error: '',
  pricingList: [],
};

const pricingSlice = createSlice({
  name: 'allPricingList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPricingList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllPricingList.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.pricingList = payload.data;
      })
      .addCase(getAllPricingList.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default pricingSlice.reducer;
