import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerOrder } from '../../../../services/apiFactory';

export const getCustomerOrderInfo = createAsyncThunk(
  'orderInfo',
  async ({ id }: { id: string }) => {
    return getCustomerOrder({ id })
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Order {
  orderHistory: {
    id: number;
    orderName: string;
    orderNote: string;
    orderStatusId: number;
    partnerAssignOrderId: null | string;
    subTotal: string;
    subtotal: string;
    shippingFee: string;
    total: string;
    customerId: number;
    merchantId: number;
    storeName: string;
    orderItems: {
      productId: number;
      merchantId: number;
      storeName: string;
      storeId: number;
      shippingFee: number;
      quantity: number;
      productPrice: number;
      productName: string;
      hostedImages: string[];
      productVariants: any[];
    }[];
    rewardProductsIds: [];
    deliveryAddress: string;
    orderDeleveryToken: null | string;
    approvePayout: boolean;
    assignedToBitmarte: boolean;
    createdAt: string;
    updatedAt: string;
    orderActivities?: {
      id: number;
      orderTrackerId: number;
      activity: string;
      createdAt: string;
      updatedAt: string;
    }[];
  };
}

interface OrdersState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  order: Order;
}

const initialState: OrdersState = {
  status: 'idle',
  error: '',
  order: {
    orderHistory: {
      id: 0,
      orderName: '',
      orderNote: '',
      orderStatusId: 0,
      partnerAssignOrderId: null,
      subTotal: '',
      subtotal: '',
      shippingFee: '',
      total: '',
      customerId: 0,
      merchantId: 0,
      storeName: '',
      orderItems: [
        {
          productId: 0,
          merchantId: 0,
          storeName: '',
          storeId: 0,
          shippingFee: 0,
          quantity: 0,
          productPrice: 0,
          productName: '',
          hostedImages: [''],
          productVariants: [],
        },
      ],
      rewardProductsIds: [],
      deliveryAddress: '',
      orderDeleveryToken: null,
      approvePayout: false,
      assignedToBitmarte: false,
      createdAt: '',
      updatedAt: '',
      orderActivities: [],
    },
  },
};

const customerOrderInfoSlice = createSlice({
  name: 'customerOrderInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerOrderInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerOrderInfo.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.order = payload.data;
      })
      .addCase(getCustomerOrderInfo.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message ||
          'Could not information on this order. Try again later.';
      });
  },
});

export default customerOrderInfoSlice.reducer;
