import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMerchantsStore } from '../../../services/apiFactory';

export const getAllMerchants = createAsyncThunk(
  'merchants/getAllMerchants',
  async () => {
    return getAllMerchantsStore()
      .then((res) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

export interface Merchant {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  nationality: string;
  idDocumentType: string;
  idDocumentNumber: string;
  idDocumentUrl: string | null;
  homeAddress: [
    {
      LGA: string;
      state: string;
      street: string;
      country: string;
    }
  ];
  useHomeAddress: boolean;
  proofOfAddress: null;
  staffSize: number;
  category: number;
  password: string;
  role: string;
  roleId: number;
  confirm: boolean;
  isVerified: boolean;
  store: {
    createdAt: string;
    id: number;
    isRegisteredBusiness: boolean;
    logo: string | null;
    merchantId: string;
    officeAddress: [];
    products: [];
    proofOfAddress: null;
    storeFollowers: [];
    storeName: string;
    storeUrl: string;
    updatedAt: string;
  };
  physicalLocation: boolean;
  pickupLocation: boolean;
  subCategoryIds: null;
  createdAt: boolean;
  updatedAt: boolean;
}

interface MerchantsStoreState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  merchants: Merchant[];
  error: string;
}

const initialState: MerchantsStoreState = {
  status: 'idle',
  merchants: [],
  error: '',
};

const MerchantsListSlice = createSlice({
  name: 'merchantList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchants.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllMerchants.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.merchants = payload?.data || [];
      })
      .addCase(getAllMerchants.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default MerchantsListSlice.reducer;
