/* eslint-disable react-hooks/rules-of-hooks */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCustomerAddress,
  getShippingAddresses,
  getUserData,
  setCustomerDefaultShippingAddress,
  updateCustomerAddress,
} from '../../../services/apiFactory';
import { useCookie } from 'next-cookie';
import axios from 'axios';
const cookie = useCookie();

export const fetchCustomerInfo = createAsyncThunk(
  'customerProfile/getCustomerProfile',
  async () => {
    // Token was set here manually because auth for customer hasn't been setup
    //Please remove after customer auth has been setup
    return axios
      .all([getUserData(), getShippingAddresses()])
      .then(
        axios.spread((...res) => {
          const userData = res[0].data;
          const customerShippingAddresses = res[1].data;
          return { userData, customerShippingAddresses };
        })
      )
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

export const addCustomerShippingAddress = createAsyncThunk(
  'customerProfile/addCustomerShippingAddress',
  async (payload: object) => {
    return addCustomerAddress(payload)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

export const setCustomerDefaultAddress = createAsyncThunk(
  'customerProfile/setCustomerDefaultAddress',
  async (id: number) => {
    return setCustomerDefaultShippingAddress(id)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

export const updateCustomerShippingAddress = createAsyncThunk(
  'customerProfile/updateCustomerShippingAddress',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateCustomerAddress(id, payload)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);
interface CustomerInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}
export interface CustomerShippingAddress {
  address: string;
  createdAt: string;
  customerId: number;
  default: boolean;
  emailAddress: string;
  fullName: string;
  id: number;
  phoneNumber: string;
  stateOrRegion: string;
  cityInLagos?: any;
  updatedAt: string;
}
interface CustomerProfileState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  customerInfo: CustomerInfo | null;
  customerShippingAddresses: CustomerShippingAddress[];
  error: string;
}
const initialState: CustomerProfileState = {
  status: 'idle',
  customerInfo: null,
  customerShippingAddresses: [],
  error: '',
};

const CustomerProfileSlice = createSlice({
  name: 'customerProfile',
  initialState,
  reducers: {
    setCustomerInfo(state, action) {
      state.customerInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerInfo.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.customerInfo = payload?.userData.data || [];
        state.customerShippingAddresses =
          payload?.customerShippingAddresses.data?.rows || [];
      })
      .addCase(fetchCustomerInfo.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get customer info. Try again later.';
      })
      .addCase(addCustomerShippingAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCustomerShippingAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.customerShippingAddresses.push(payload.data.data);
      })
      .addCase(addCustomerShippingAddress.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not add new address. Try again later.';
      })
      .addCase(updateCustomerShippingAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        updateCustomerShippingAddress.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          console.log(payload.data);

          const addressIndex = state.customerShippingAddresses.findIndex(
            (customerShippingAddress) =>
              customerShippingAddress.id === payload.data.data.id
          );
          state.customerShippingAddresses[addressIndex] = payload.data.data;
        }
      )
      .addCase(updateCustomerShippingAddress.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not update address. Try again later.';
      })
      .addCase(setCustomerDefaultAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setCustomerDefaultAddress.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        console.log(payload.data);

        state.customerShippingAddresses = state.customerShippingAddresses.map(
          (customerShippingAddress) => {
            customerShippingAddress.default = false;
            if (payload.data.previousDefault) {
              return customerShippingAddress.id ===
                payload.data.previousDefault.id
                ? payload.data.previousDefault
                : customerShippingAddress;
            } else {
              return customerShippingAddress;
            }
          }
        );
      })
      .addCase(setCustomerDefaultAddress.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not update address. Try again later.';
      });
  },
});

export default CustomerProfileSlice.reducer;
