import { createSlice } from '@reduxjs/toolkit';

interface NotificationProps {
  userNotificationId: string;
}

const initialState: NotificationProps = {
  userNotificationId: '',
};
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUserNotificationId: (
      state: { userNotificationId: any },
      { payload }: any
    ) => {
      state.userNotificationId = payload;
    },
  },
});

export const { setUserNotificationId } = notificationsSlice.actions;
export default notificationsSlice.reducer;
