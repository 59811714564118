import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  adminForgotPassword,
  customerForgotPassword,
  merchantForgotPassword,
  shippingPartnerForgotPassword,
} from 'services/apiFactory';

const handleForgotPassword = (email: string, userType: string) => {
  switch (userType) {
    case 'merchant': {
      return merchantForgotPassword({ email });
    }
    case 'customer': {
      return customerForgotPassword({ email });
    }
    case 'admin': {
      return adminForgotPassword({ email });
    }
    case 'partner': {
      return shippingPartnerForgotPassword({ email });
    }
  }
};

export const initiateForgotPassword = createAsyncThunk(
  'forgotPassword',
  async ({ email, userType }: { email: string; userType: string }) => {
    if (!email || !userType) return;
    return await handleForgotPassword(email, userType)
      ?.then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log('errror', err.response.data.message);
        return err;
      });
  }
);

interface ForgotPasswordState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  successMessage: string;
  errorMessage: string;
}

const initialState: ForgotPasswordState = {
  status: 'idle',
  successMessage: '',
  errorMessage: '',
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiateForgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initiateForgotPassword.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.status = 'succeeded';
          state.successMessage = payload?.message;
        } else {
          state.status = 'failed';
          state.errorMessage = payload?.message;
        }
      })
      .addCase(initiateForgotPassword.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.errorMessage = 'Please check your internet and try again';
      });
  },
});

export default forgotPasswordSlice.reducer;
