import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMerchantOrders,
  updateOrderStatus,
  getMerchantOrder,
  getOrderStatuses,
  assignOrder,
  getCustomerShippingDetails,
  getMerchantShippingDetails,
  initializeOrderReturns,
  initializeOrderReplacement,
  getMerchantReturnOrders,
  updateReturnOrderStatus,
  getOrderShippingAddress,
  getCustomerSingleOrderShippingDetails,
} from '../../../services/apiFactory';
import { OrderStatus } from 'types';
import { ShippingAddressType } from 'state/features/cart/cartSlice';
import toast from 'react-hot-toast';

// Axios Request functions for merchant discount
// getMerchantCoupons
export const fetchMerchantOrders = createAsyncThunk(
  'merchantOrders/getOrders',
  async () => {
    return getMerchantOrders()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchMerchantReturnOrders = createAsyncThunk(
  'merchantOrders/getReturnOrders',
  async (data: { id: number }) => {
    const { id } = data;
    return getMerchantReturnOrders(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchMerchantShippingDetails = createAsyncThunk(
  'merchantOrders/fetchMerchantShippingDetails',
  async (data: { id: number }) => {
    const { id } = data;
    return getMerchantShippingDetails(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchCustomerShippingDetails = createAsyncThunk(
  'merchantOrders/fetchCustomerShippingDetails',
  async (data: { id: number }) => {
    const { id } = data;
    return getCustomerShippingDetails(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchCustomerSingleOrderShippingDetails = createAsyncThunk(
  'merchantOrders/fetchCustomerSingleOrderShippingDetails',
  async (data: { id: number }) => {
    const { id } = data;
    return getCustomerSingleOrderShippingDetails(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchCustomerOrderShippingDetails = createAsyncThunk(
  'fetch/customer/order/shipping/details',
  async (data: { id: number }) => {
    const { id } = data;
    return getOrderShippingAddress(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const assignOrderToBitMart = createAsyncThunk(
  'merchantOrders/assignOrderToBitMart',
  async (data: { id: number }) => {
    const { id } = data;
    return assignOrder(id)
      .then((res) => {
        if (res) {
          const data = res.data;

          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async (data: { query: string }) => {
    const { query } = data;
    return getOrderStatuses(query)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const fetchSingleMerchantOrder = createAsyncThunk(
  'merchantOrders/getOrder',
  async (data: { id: number }) => {
    const { id } = data;
    return getMerchantOrder(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const updateMerchantOrderStatus = createAsyncThunk(
  'merchantOrders/updateMerchantOrderStatus',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    const res = await updateOrderStatus(id, payload);
    return res.data;
  }
);
export const updateOrderReturnStatus = createAsyncThunk(
  'merchantOrders/updateReturnOrderStatus',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    const res = await updateReturnOrderStatus(id, payload);
    return res.data;
  }
);

export const returnOrder = createAsyncThunk(
  'merchantOrders/returnOrders',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return initializeOrderReturns(id, payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          toast.success('Order return was successful');
          return data;
        }
      })
      .catch((err) => {
        JSON.stringify(err.response);
        toast.error('Something went wrong');
      });
  }
);
export const replaceOrder = createAsyncThunk(
  'merchantOrders/replaceOrder',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return initializeOrderReplacement(id, payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          toast.success('Order replacement was successful');
          return data;
        }
      })
      .catch((err) => {
        JSON.stringify(err.response);
        toast.error('Something went wrong');
      });
  }
);

interface ShippingLocation {
  state: string;
  street: string;
  city?: string;
  country: string;
}
// Constants discount type decleared
export interface returnedOrderItem {
  createdAt: string;
  id: number;
  merchantId: number;
  orderId: number;
  products: OrderItem[];
  refund: boolean;
  replace: boolean;
  type: string;
  status: string;
}
export interface OrderItem {
  productId: number;
  merchantId: number;
  shippingFee: number;
  quantity: number;
  storeName: string;
  productPrice: number;
  productName: string;
  hostedImages: string[];
  selected?: boolean;
  replaceProductItem?: any;
  productVariants: any[];
}
interface orderActivity {
  id: number;
  orderTrackerId: number;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
export interface MerchantOrder {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatus: OrderStatus;
  subtotal: string;
  dispatchPhoneNumber: string | null;
  shippingFee: string;
  total: string;
  customerId: number;
  merchantId: number;
  assignedToBitmarte: boolean;
  shippingEvidence: string;
  orderItems: OrderItem[];
  lagosMerchant: boolean;
  lagosCustomer: boolean;
  deliveryAddress: string;
  storeName: string;
  approvePayout: boolean;
  createdAt: string;
  orderReturnId: number;
  updatedAt: string;
  withinLagos: boolean;
  orderActivities: orderActivity[];
}
export interface orderStatuses {
  id: number;
  code_name: string;
  known_name: string;
}
export interface orderCurrentStatuses {
  id: number;
  code_name: string;
  known_name: string;
}
export interface MerchantDetails {
  storeName: string;
  phoneNumber: string;
  shippingLocation: ShippingLocation;
  bitmarteHubPhoneNumber?: string;
}
interface MerchantOrdersState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  orders: MerchantOrder[];
  selectedOrder: MerchantOrder;
  returnedOrder: returnedOrderItem;
  isError: boolean;
  orderStatuses: orderStatuses[];
  customerShippingDetails: ShippingAddressType;
  customerSingleOrderShippingDetails: ShippingAddressType;
  merchantShippingDetails: MerchantDetails;
}

const initialState: MerchantOrdersState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  returnedOrder: {
    createdAt: '',
    id: 1,
    merchantId: 1,
    status: '',
    type: '',
    orderId: 1,
    products: [],
    refund: false,
    replace: false,
  },
  orders: [],
  orderStatuses: [],
  merchantShippingDetails: {
    storeName: '',
    phoneNumber: '',
    shippingLocation: {
      state: '',
      street: '',
      country: '',
    },
    bitmarteHubPhoneNumber: '',
  },
  customerShippingDetails: {
    id: 0,
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    stateOrRegion: '',
    address: '',
    default: false,
  },
  customerSingleOrderShippingDetails: {
    id: 0,
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    stateOrRegion: '',
    address: '',
    default: false,
  },
  selectedOrder: {
    id: 0,
    orderName: '',
    storeName: '',
    orderReturnId: 1,
    orderNote: '',
    orderStatus: 'pending',
    subtotal: '',
    shippingFee: '',
    dispatchPhoneNumber: null,
    total: '',
    customerId: 1,
    merchantId: 2,
    assignedToBitmarte: false,
    shippingEvidence: '',
    orderItems: [],
    deliveryAddress: '',
    approvePayout: false,
    createdAt: '',
    updatedAt: '',
    withinLagos: false,
    lagosMerchant: false,
    lagosCustomer: false,
    orderActivities: [],
  },
};

export const clearOrderData = () => {
  const clearData = initialState.orders;
  return clearData;
};

const merchantOrdersSlice = createSlice({
  name: 'merchantOrders',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantOrders.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        // state.orders = []; //I commented this out cus I don't think it should be there

        state.orders =
          data?.map((item: any) => ({
            ...item,
            orderStatus: state.orderStatuses.find(
              (i) => i.id === item.orderStatusId
            )?.code_name,
          })) || [];
        state.status = 'succeeded';
      })
      .addCase(fetchMerchantOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleMerchantOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleMerchantOrder.fulfilled, (state, { payload }) => {
        const data = payload?.data;

        state.selectedOrder = {
          ...data,
          orderStatus: state.orderStatuses.find(
            (i) => i.id === data.orderStatusId
          )?.code_name,
        };
        state.status = 'succeeded';
      })
      .addCase(fetchSingleMerchantOrder.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateMerchantOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMerchantOrderStatus.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateMerchantOrderStatus.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.orderStatuses = data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(assignOrderToBitMart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(assignOrderToBitMart.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(assignOrderToBitMart.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomerShippingDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerShippingDetails.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.customerShippingDetails = data;
      })
      .addCase(fetchCustomerShippingDetails.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomerSingleOrderShippingDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCustomerSingleOrderShippingDetails.fulfilled,
        (state, { payload }) => {
          const data = payload?.data;
          state.status = 'succeeded';
          state.customerSingleOrderShippingDetails = data;
        }
      )
      .addCase(fetchCustomerSingleOrderShippingDetails.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomerOrderShippingDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCustomerOrderShippingDetails.fulfilled,
        (state, { payload }) => {
          const data = payload?.data;
          state.status = 'succeeded';
          state.customerShippingDetails = data;
        }
      )
      .addCase(fetchCustomerOrderShippingDetails.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(returnOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(returnOrder.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(returnOrder.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(replaceOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(replaceOrder.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(replaceOrder.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchMerchantReturnOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantReturnOrders.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.returnedOrder = data;
      })
      .addCase(fetchMerchantReturnOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateOrderReturnStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderReturnStatus.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
      })
      .addCase(updateOrderReturnStatus.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchMerchantShippingDetails.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(fetchMerchantShippingDetails.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        const shippingLocationData = data?.['0']?.shippingLocation;
        const shippingLocation = Array.isArray(shippingLocationData)
          ? shippingLocationData[0]
          : shippingLocationData;
        state.status = 'succeeded';
        state.merchantShippingDetails = {
          storeName: data?.['0']?.storeName,
          phoneNumber: data?.merchant?.phoneNumber,
          shippingLocation: {
            // shipping location can either come as an array or object
            street: shippingLocation?.street,
            city: shippingLocation?.city,
            state: shippingLocation?.state,
            country: shippingLocation?.country,
          },
          bitmarteHubPhoneNumber:
            data?.bitmarteHub?.phoneNumber || data?.merchant.phoneNumber,
        };
      })
      .addCase(fetchMerchantShippingDetails.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setStatusMessage } = merchantOrdersSlice.actions;
export default merchantOrdersSlice.reducer;
