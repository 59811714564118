import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantSearchProducts } from '../../../services/apiFactory';

export const fetchMerchantSearchProducts = createAsyncThunk(
  'product/merchant/search/products',
  async ({ search }: { search?: string }) => {
    // console.log(search)
    return getMerchantSearchProducts({ search })
      .then((res) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface Product {
  id: number;
  name: string;
  description: {
    head: string;
  };
  pricing: [
    {
      price: string;
      sellingPrice: string;
    }
  ];
  hostedImages: string[];
  rating?: number;
  productVariant: {
    createdAt: string;
    id: number;
    productId: number;
    properties: {
      type: string;
      options: string[];
    }[];
    length: number;
    updatedAt: string;
  };
}

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  count: number;
  products: Product[];
}

const initialState: CategoriesState = {
  status: 'idle',
  error: '',
  count: 0,
  products: [],
};

const merchantProductsSearchSlice = createSlice({
  name: 'merchantSearchProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantSearchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantSearchProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload.data.data) {
          state.products = payload.data.data;
          state.count = payload.data.count;
        } else {
          state.products = [];
          state.count = 0;
        }
      })
      .addCase(fetchMerchantSearchProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get products. Try again later.';
      });
  },
});

export default merchantProductsSearchSlice.reducer;
