import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllOrderReturns,
  updateOrderChargebackDecision,
  updateOrderRefundDecision,
} from 'services/apiFactory';
import { OrderStatus } from 'types';

export const fetchAllOrderReturns = createAsyncThunk(
  'adminOrders/getAllOrderReturns',
  async ({ refund }: { refund?: boolean } = {}) => {
    return getAllOrderReturns(refund)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const updateRefundDecision = createAsyncThunk(
  'merchantReturns/updateRefundDecision',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateOrderRefundDecision(id, payload)
      .then((res) => {
        const data = res.data;
        console.log(data);

        return data;
      })
      .catch((err) => {
        const errors = err.response.data;
        // console.log(errors)
        return errors;
      });
    // console.log('Submitted', res.data);
    // return res.data;
  }
);
export const updateChargebackDecision = createAsyncThunk(
  'merchantReturns/updateChargebackDecision',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateOrderChargebackDecision(id, payload)
      .then((res) => {
        const data = res.data;
        console.log(data);

        return data;
      })
      .catch((err) => {
        const errors = err.response.data;
        // console.log(errors)
        return errors;
      });
  }
);

export interface ReturnedOrder {
  id: number;
  merchantId: number;
  customerId: number;
  merchantName: string;
  orderDate: string;
  salesAmount: string;
  orderNo: string;
  orderId: number;
  refundDecision: null;
  refundAmount: null;
  refundDate: null;
  location: string;
  refund: boolean;
  replace: boolean;
  status: string;
  type: string;
  chargebackDate?: string;
  createdAt: string;
  updatedAt: string;
}

interface AdminOrdersState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: any;
  returnedOrders: ReturnedOrder[];
  isError: boolean;
  isUpdated: boolean;
}

const initialState: AdminOrdersState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  returnedOrders: [],
  isUpdated: false,
};

const adminOrderReturnSlice = createSlice({
  name: 'merchantOrderReturn',
  initialState,
  reducers: {
    setIsUpdated: (state, { payload }) => {
      state.isUpdated = payload;
    },
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrderReturns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllOrderReturns.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.returnedOrders = data?.rows;
        state.status = 'succeeded';
      })
      .addCase(fetchAllOrderReturns.rejected, (state, payload) => {
        state.status = 'failed';
        state.statusMessage = 'Check network connection';
        state.isError = true;
      })
      .addCase(updateRefundDecision.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateRefundDecision.fulfilled, (state, { payload }: any) => {
        if (payload?.success) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
        } else {
          console.log('false');
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload?.message;
        }
      })
      .addCase(updateRefundDecision.rejected, (state, payload) => {
        state.status = 'failed';
        state.statusMessage = 'Check network connection';
        state.isError = true;
      })
      .addCase(updateChargebackDecision.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChargebackDecision.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload?.message;
        }
      })
      .addCase(updateChargebackDecision.rejected, (state) => {
        state.status = 'failed';
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});

export const { setIsUpdated, setStatusMessage } = adminOrderReturnSlice.actions;
export default adminOrderReturnSlice.reducer;
