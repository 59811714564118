import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createMerchantTrackerFunc } from 'services/apiFactory';

// Axios Request functions for merchant discount
export const createMerchantTracker = createAsyncThunk(
  'subscriptions/create-merchant-tracker',
  async (payload: object) => {
    return createMerchantTrackerFunc(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

interface CreateMerchantTrackerState {
  // Status type 'idle' | 'loading' | 'succeeded' | 'failed';

  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState = {
  statusMessage: '',
  isError: true,
};

const createMerchantTrackerSlice = createSlice({
  name: 'subscriptionCreateMerchantTracker',
  initialState,
  reducers: {
    setStatusMessage: (state, { payload }) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMerchantTracker.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isError = false;
        } else {
          state.isError = true;
          state.statusMessage = payload;
        }
      })

      .addCase(createMerchantTracker.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});

export const { setStatusMessage } = createMerchantTrackerSlice.actions;
export default createMerchantTrackerSlice.reducer;
