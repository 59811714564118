import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSubCategory } from '../../../services/apiFactory';

export const getSingleSubCategory = createAsyncThunk(
  'subCategory',
  async (id: number) => {
    return getSubCategory({ id })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface SubCategoryState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  subCategory: {
    id: number;
    name: string;
    categoryId: number;
  };
}

const initialState: SubCategoryState = {
  status: 'idle',
  error: '',
  subCategory: {
    id: 0,
    name: '',
    categoryId: 0,
  },
};

const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleSubCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleSubCategory.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.subCategory = payload.data;
      })
      .addCase(getSingleSubCategory.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get child category. Try again later.';
      });
  },
});

export default subCategorySlice.reducer;
