import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userLogOut } from 'services/auth';
import { getUserData } from '../../../services/apiFactory';

// Axios Request functions for merchant discount
export const getMerchantUserData = createAsyncThunk(
  'merchant/userData',
  async () => {
    return getUserData()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface UserDataState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    category: number;
    role: string;
    storeId: number;
    storeName: string;
    isRegisteredBusiness?: boolean;
    isVerified: boolean;
    companyName?: any;
    image?: string;
    name?: string;
    logo?: string;
    uniqueId?: string;
    lastLogin: string;
    permission: any[];
    store: {
      id: number;
      isRegisteredBusiness: boolean;
      isActiveSubscription: boolean;
      storeName: string;
      logo?: string;
    };
  } | null;
}

const initialState: UserDataState = {
  status: 'idle',
  error: '',
  // isLoggedIn: cookie.get('token') || '',
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      state.user = null;
      userLogOut();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantUserData.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.user = payload.data;
      })
      .addCase(getMerchantUserData.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user data. Try again later.';
      });
  },
});

export const { setUserData, logout } = userSlice.actions;
export default userSlice.reducer;
