import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProduct } from '../../../services/apiFactory';

// Axios Request functions for merchant discount
export const fetchCustomerProduct = createAsyncThunk(
  'product/fetchCustomerProduct',
  async (payload: object) => {
    return getProduct(payload)
      .then((res) => {
        if (res) {
          console.log(payload, 'here');
          const data = res.data;
          console.log(data);
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';
export interface variantProperties {
  type: string;
  options: string[];
}
interface variant {
  sku: string;
  size: string;
  color: string;
  quantity: string;
  discount: string;
}
export interface productVariant {
  id: number;
  productId: number;
  variant: variant[];
  properties: variantProperties[];
}
export interface Product {
  id: number;
  name: string;

  description: any[];
  pricing: [
    {
      costPrice: number;
      sellingPrice: number;
      price: number;
    }
  ];
  quantity: number;
  sku: string;
  weight: number;
  dimension: {
    width: string;
    height: string;
    length: string;
  };
  productVariant: productVariant;
  storeName: string;
  category: string;
  subCategory: string;
  childCategory: string;
  hostedImages: never[];
  whenSoldOut?: string;
}

interface getProductsState {
  status: PromiseStatus;
  products: Product[];
  error: string;
  selectedProduct: Product;
}

const initialState: getProductsState = {
  status: 'idle',
  products: [],
  error: '',

  selectedProduct: {
    name: '',
    id: 0,
    whenSoldOut: '',
    description: [],
    category: '',
    storeName: '',
    hostedImages: [],
    pricing: [
      {
        costPrice: 0,
        sellingPrice: 0,
        price: 0,
      },
    ],
    quantity: 0,
    sku: '',
    weight: 0,
    subCategory: '',
    childCategory: '',
    dimension: {
      width: '',
      height: '',
      length: '',
    },
    productVariant: {
      id: 1,
      productId: 43,
      variant: [
        {
          sku: 'hack',
          size: 'M',
          color: 'violet',
          discount: '894',
          quantity: '952',
        },
        {
          sku: 'copy',
          size: 'L',
          color: 'teal',
          discount: '205',
          quantity: '150',
        },
      ],
      properties: [
        {
          type: 'model',
          options: ['256', '64'],
        },
        {
          type: 'colors',
          options: ['red', 'green'],
        },
      ],
    },
  },
};

const customerProductsSlice = createSlice({
  name: 'CustomerProducts',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.selectedProduct = payload?.data;
      })
      .addCase(fetchCustomerProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || '';
      });
    //   .addCase(updateMerchantProduct.pending, (state) => {
    //     state.product.status = 'loading';
    //   })
    //   .addCase(updateMerchantProduct.fulfilled, (state, { payload }) => {
    //     state.product.status = 'succeeded';
    //     state.product.message = 'Product updated successfully';

    //     if (payload?.data?.id) {
    //       const products = [...state.products];
    //       console.log(products);
    //       console.log(payload.data.id);
    //       // products[payload.data.id].quantity = payload.data?.quantity || 0;

    //       // state.products = products;
    //     }
    //   })
    //   .addCase(updateMerchantProduct.rejected, (state, { error }) => {
    //     state.product.status = 'failed';
    //     console.log(error);
    //     state.error =
    //       error.message || 'Could not update product. Try again later.';
    //   });
  },
});

export const { setProducts } = customerProductsSlice.actions;
export default customerProductsSlice.reducer;
