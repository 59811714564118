import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerOrderMerchantPolicy } from '../../../../services/apiFactory';

// 'customerOrderInfo/getCustomerOrderInfo',
export const getCustomerOrderMerchantInfoPolicy = createAsyncThunk(
  'merchantPolicy',
  async (storeId: any) => {
    // console.log('storeId',storeId)
    return getCustomerOrderMerchantPolicy(storeId)
      .then((res: any) => {
        // console.log(res)
        // console.log('hello from slice')
        const data = res.data;
        // console.log(data)
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Policy {
  count: number;
  rows: {
    id: number;
    title: string;
    description: string;
    merchantId: number;
    storeId: number;
    createdAt: string;
    updatedAt: string;
  }[];
}

interface PolicyState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  policy: Policy;
}

const initialState: PolicyState = {
  status: 'idle',
  error: '',
  policy: {
    count: 0,
    rows: [],
  },
};

const customerOrderMerchantInfoPolicySlice = createSlice({
  name: 'customerOrderMerchantInfoPolicy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerOrderMerchantInfoPolicy.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getCustomerOrderMerchantInfoPolicy.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          state.policy = payload.data;
        }
      )
      .addCase(
        getCustomerOrderMerchantInfoPolicy.rejected,
        (state, { error }) => {
          state.status = 'failed';
          state.error =
            error.message ||
            'Could not information on this order. Try again later.';
        }
      );
  },
});

export default customerOrderMerchantInfoPolicySlice.reducer;
