import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizedUser } from 'services/auth';
import { useCookie } from 'next-cookie';
import { adminLogin, getPermissions } from 'services/apiFactory';

const cookie = useCookie();
// Axios Request functions for merchant discount
export const loginAdmin = createAsyncThunk(
  'Admin/Login',
  async (payload: object) => {
    // console.log(payload)
    return adminLogin(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        setAuthorizedUser('token', data);
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

export const fetchPermissions = createAsyncThunk(
  'Admin/Login',
  async (payload: object) => {
    // console.log(payload)
    return getPermissions()
      .then((res: { data: any }) => {
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);
// console.log(user?.token)
// initialState
const user = true;
const initialState = user
  ? {
      isLoggedIn: true,
      statusMessage: '',
      isError: false,
      userAccess: cookie.get('token'),
    }
  : { isLoggedIn: false, statusMessage: '', isError: true };

const adminLoginSlice = createSlice({
  name: 'adminLogin',
  initialState,
  reducers: {
    setStatusMessage: (state: { statusMessage: any }, { payload }: any) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isLoggedIn = true;
          state.isError = false;
        } else {
          state.isLoggedIn = false;
          state.isError = true;
          state.statusMessage = payload;
        }
      })

      .addCase(loginAdmin.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});
export const { setStatusMessage } = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
