import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword } from '../../../services/apiFactory';

export const updatePassword = createAsyncThunk(
  'merchantUpdatePassword',
  async (payload: any) => {
    return changePassword(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

interface passwordDetails {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string;
  isError: boolean;
  merchantPassword: any;
  adminPassword: any;
  customerPassword: any;
}
const initialState: passwordDetails = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  merchantPassword: {
    oldPassword: '',
    newPassword: '',
  },
  adminPassword: {
    oldPassword: '',
    newPassword: '',
  },
  customerPassword: {
    oldPassword: '',
    newPassword: '',
  },
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    setMerchantPassword: (state, { payload }) => {
      // console.log(payload)
      state.merchantPassword = payload;
    },
    setAdminPassword: (state, { payload }) => {
      // console.log(payload)
      state.adminPassword = payload;
    },
    setCustomerPassword: (state, { payload }) => {
      // console.log(payload)
      state.customerPassword = payload;
    },
    clearStatusMsg: (state, { payload }) => {
      (state.isError = payload.isError),
        (state.statusMessage = payload.statusMsg);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload;
        }
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.isError = true;
        state.statusMessage = 'There was an error. Please try again';
      });
  },
});
export const {
  setMerchantPassword,
  setAdminPassword,
  setCustomerPassword,
  clearStatusMsg,
} = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
