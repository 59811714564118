import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllMerchantsStore,
  getSubCategories,
  getUserData,
} from '../../../services/apiFactory';

import { useCookie } from 'next-cookie';
const cookie = useCookie();

export const fetchAllMerchantsStore = createAsyncThunk(
  'merchants/getAllMerchantsStore',
  async () => {
    return getAllMerchantsStore()
      .then((res) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

export interface Merchant {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  nationality: string;
  idDocumentType: string;
  idDocumentNumber: string;
  idDocumentUrl: string | null;
  homeAddress: [
    {
      LGA: string;
      state: string;
      street: string;
      country: string;
    }
  ];
  useHomeAddress: boolean;
  proofOfAddress: null;
  staffSize: number;
  category: number;
  password: string;
  role: string;
  roleId: number;
  confirm: boolean;
  isVerified: boolean;
  store: {
    createdAt: string;
    id: number;
    isRegisteredBusiness: boolean;
    logo: string | null;
    merchantId: string;
    officeAddress: [];
    products: [];
    proofOfAddress: null;
    storeFollowers: [];
    storeName: string;
    storeUrl: string;
    updatedAt: string;
  };
  physicalLocation: boolean;
  pickupLocation: boolean;
  subCategoryIds: null;
  createdAt: boolean;
  updatedAt: boolean;
}

interface MerchantsStoreState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  merchants: Merchant[];
  isError: boolean;
}

const initialState: MerchantsStoreState = {
  status: 'idle',
  merchants: [],
  isError: false,
};

const MerchantsStoreSlice = createSlice({
  name: 'merchantStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMerchantsStore.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllMerchantsStore.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.merchants = payload?.data || [];
      })
      .addCase(fetchAllMerchantsStore.rejected, (state, { error }) => {
        state.status = 'failed';
        state.isError = true;
      });
  },
});

export default MerchantsStoreSlice.reducer;
