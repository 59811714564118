import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import merchantCouponsReducer from './features/merchantCoupons/merchantCouponsSlice';
import themeLayoutReducer from './themes/themeLayoutSlice';
import merchantSignReducer from './features/auth/merchantSignInSlice';
import signUpReducer from './features/auth/signUpSlice';
import customerSignUpReducer from './features/auth/customer/customerSignUpSlice';
import customerLoginReducer from './features/auth/customer/customerLoginSlice';
import onboardingReducer from './features/onboarding/onboardingSlice';
import productsReducer from './features/products/productsSlice';
import customerProductReducer from './features/products/customerProductSlice';
import newProductReducer from './features/products/newProductSlice';
import editProductReducer from './features/products/editProductSlice';
import fixedDiscountedProductsReducer from './features/discountedProducts/fixedDiscountedProductsSlice';
import dailyHotDealsReducer from './features/discountedProducts/dailyHotDealsSlice';
import randomProductsReducer from './features/products/randomProductsSlice';
import userReducer from './features/auth/userSlice';
import cartReducer from './features/cart/cartSlice';
import categoriesReducer from './features/products/categoriesSlice';
import merchantOrdersReducer from './features/orders/merchantOrdersSlice';
import adminOrdersReducer from './features/orders/adminOrdersSlice';
import shippingPartnerOrdersReducer from './features/orders/shippingPartnerOrdersSlice';
import allCategoriesReducer from './features/categories/categoriesSlice';
import topCategoriesReducer from './features/categories/topCategories';
import childrenCategoriesReducer from './features/products/childrenCategoriesSlice';
import customerProductsReducer from './features/customerProducts/customerProductsSlice';
import merchantSearchProductsReducer from './features/merchant/merchantProductsSearchSlice';
import merchantsStoreReducer from './features/merchants/MerchantsStoreSlice';
import customerProfileReducer from './features/customerProfile/customerProfileSlice';
import singleProductsDetailsReducer from './features/customerProducts/singleProductDetailsSlice';
import adminProductsReducer from './features/adminProducts/adminProductsSlice';
import adminSingleProductReducer from './features/adminProducts/adminSingleProductSlice';
import chatReducer from './features/chat/chatSlice';
import merchantFeaturedProductsReducer from 'state/features/products/merchantFeaturedProductsSlice';
import merchantStoreProfileReducer from 'state/features/merchantStoreProfile/merchantStoreProfileSlice';
import allCustomerOrdersReducer from 'state/features/orders/customer/customerOrdersSlice';
import customerOrderInfoReducer from 'state/features/orders/customer/customerOrderInfoSlice';
import customerInfoReducer from 'state/features/orders/customer/customerInfoSlice';
import customerOrderMerchantInfoReducer from 'state/features/orders/customer/customerOrderMerchantInfoPolicySlice';
import { reducer as themesReducer } from 'state/features/themes/themesSlice';
import { reducer as discountReducer } from 'state/features/discount/discountSlice';
import { reducer as vehicleReducer } from 'state/features/shipping/shippingSlice';
import { reducer as zoneReducer } from 'state/features/zones/zoneSlice';
import { reducer as cmsReducer } from 'state/features/cms/cmsSlice';
import allPricingListReducer from 'state/features/pricing/pricingSlice';
import adminLoginSlice from 'state/features/auth/admin/adminLoginSlice';
import adminTransactionsSlice from 'state/features/transactions/adminTransactionsSlice';
import merchantTransactionsSlice from 'state/features/transactions/merchantTransactionsSlice';
import banksReducer from 'state/features/banks/banksSlice';
import childCategoryReducer from 'state/features/products/childCategorySlice';
import filteredProductsReducer from 'state/features/products/filteredProductsSlice';
import subCategoryReducer from 'state/features/products/subCategorySlice';
import allMerchantPoliciesReducer from 'state/features/merchantPolicy/merchantPolicySlice';
import createMerchantPolicyReducer from 'state/features/merchantPolicy/createPolicySlice';
import updateMerchantPolicyReducer from 'state/features/merchantPolicy/updatePolicySlice';
import merchantListReducer from 'state/features/merchant/getAllMerchantsSlice';
import singleMerchantDetailsReducer from 'state/features/merchant/singleMerchantDetailsSlice';
import shippingPartnerReducer from 'state/features/auth/shipping-partner/shippingPartnerAuthSlice';
import merchantInfoForPolicyReducer from 'state/features/merchantPolicy/getMerchantForPolicySlice';
import salesReportReducer from 'state/features/salesReport/salesReportSlice';
import topMerchantProductsReducer from 'state/features/products/topMerchantProductsSlice';
import subscriptionCreateMerchantTrackerReducer from 'state/features/merchant/createMerchantTrackerSlice';
import notificationsReducer from 'state/features/notification/notificationsSlice';
import merchantStaffReducer from 'state/features/merchant/merchantStaffSlice';
import getSchedulerOrdersListReducer from 'state/features/orders/admin/scheduler/getSchedulerOrdersSlice';
import searchSchedulerOrdersListReducer from 'state/features/orders/admin/scheduler/searchSchedulerOrdersSlice';
import searchDispatcherOrdersListReducer from 'state/features/orders/admin/dispatcher/searchDispatcherOrdersSlice';
import assignOrderToDispatcherDataReducer from 'state/features/orders/admin/scheduler/assignOrderToDispatcherSlice';
import assignOrderToPartnerDataReducer from 'state/features/orders/admin/dispatcher/assignOrderToPartnerSlice';
import convertTokenFuncDataReducer from 'state/features/orders/admin/dispatcher/convertTokenFuncSlice';
import contactUsFuncDataReducer from 'state/features/contact/contactUsFuncSlice';
import getDispatcherOrdersListReducer from 'state/features/orders/admin/dispatcher/getDispatcherOrdersSlice';
import getAllDispatchersListReducer from 'state/features/orders/admin/dispatcher/getAllDispatchersSlice';
import getAllShippingPartnersListReducer from 'state/features/orders/admin/dispatcher/getAllShippingPartnersSlice';
import adminStoresReducer from 'state/features/stores/adminStoresSlice';
import adminStoreDetailsReducer from 'state/features/stores/adminStoreDetailsSlice';
import adminStoreProductReducer from 'state/features/stores/adminStoreProductsSlice';
import superAdminAdminsReducer from 'state/features/superAdmin/superAdminAdminsSlice';
import hubsReducer from 'state/features/hubs/hubsSlice';
import approvedHubsReducer from 'state/features/hubs/approvedHubCompaniesSlice';
import hubApplicationReducer from 'state/features/hubs/hubApplicationSlice';
import adminSalesActivityReducer from 'state/features/salesReport/adminSalesActivitySlice';
import onboardingShippingPartnerReducer from 'state/features/onboarding/shippingPartnerSlice';
import { apiSlice } from './features/api/apiSlice';
import startCustomerReturnOrderReducer from './features/customer/initiateCustomerReturnsOrderSlice';
import merchantOrderReturnReducer from './features/orders/merchantOrderReturnSlice';
import { shippingApiSlice } from './features/api/shippingApiSlice';
import allCustomerStoresUnauthenticatedReducer from './features/customer/customerStoresUnauthenticatedSlice';
import singleMerchantPaymentReportReducer from './features/merchant/merchantPaymentReportSlice';
import officialOutletsProductsReducer from 'state/features/products/officialOutletsProductsSlice';
import adminsReducer from 'state/features/admins/adminsSlice';
import storeApproversReducer from 'state/features/storeApprovers/storeApproversSlice';
import merchantProfileReducer from 'state/features/merchant/merchantProfileSlice';
import adminMerchantPaymentsReportReducer from 'state/features/paymentReport/adminMerchantPaymentReportSlice';
import changePasswordSliceReducer from 'state/features/change-password/changePasswordSlice';
import shippingPartnerUpdatePasswordSliceReducer from 'state/features/change-password/shippingPartnerUpdatePasswordSlice';
import createProductReviewsReducer from 'state/features/reviews/createProductReviewSlice';
import createMerchantReviewsReducer from 'state/features/reviews/createMerchantReviewSlice';
import adminSettlementReportReducer from 'state/features/nextDaySettlement/adminSettlementReportSlice';
import forgotPasswordReducer from 'state/features/auth/forgotPasswordSlice';
import resetPasswordReducer from 'state/features/auth/resetPasswordSlice';
import createTestimonialReducer from 'state/features/merchantTestimonial/createTestimonialSlice';
import getMerchantTestimonialReducer from 'state/features/merchantTestimonial/getMerchantAllTestimonialSlice';
import updateTestimonialReducer from 'state/features/merchantTestimonial/updateTestimonialSlice';
import financeReportsReducer from 'state/features/financeReports/financeReportsSlice';
import adminBitMarteHubOrdersReducer from 'state/features/orders//admin/adminBitMarteHubOrdersSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [shippingApiSlice.reducerPath]: shippingApiSlice.reducer,
    user: userReducer,
    merchantCoupons: merchantCouponsReducer,
    cart: cartReducer,
    customerProduct: customerProductReducer,
    customerProducts: customerProductsReducer,
    merchantSearchProducts: merchantSearchProductsReducer,
    filteredProducts: filteredProductsReducer,
    singleProductDetails: singleProductsDetailsReducer,
    adminProducts: adminProductsReducer,
    adminSingleProduct: adminSingleProductReducer,
    themeLayout: themeLayoutReducer,
    onboarding: onboardingReducer,
    merchantSignIn: merchantSignReducer,
    signUp: signUpReducer,
    customerSignUp: customerSignUpReducer,
    customerLogin: customerLoginReducer,
    shippingPartnerLogin: shippingPartnerReducer,
    products: productsReducer,
    fixedDiscountedProducts: fixedDiscountedProductsReducer,
    dailyHotDeals: dailyHotDealsReducer,
    randomProducts: randomProductsReducer,
    newProduct: newProductReducer,
    editProduct: editProductReducer,
    categories: categoriesReducer,
    merchantOrders: merchantOrdersReducer,
    adminOrders: adminOrdersReducer,
    allCategories: allCategoriesReducer,
    topCategories: topCategoriesReducer,
    childrenCategories: childrenCategoriesReducer,
    childCategory: childCategoryReducer,
    subCategory: subCategoryReducer,
    allCustomerOrders: allCustomerOrdersReducer,
    customerOrderInfo: customerOrderInfoReducer,
    customerInfo: customerInfoReducer,
    customerOrderMerchantInfoPolicy: customerOrderMerchantInfoReducer,
    merchantsStore: merchantsStoreReducer,
    customerProfile: customerProfileReducer,
    shippingPartnerOrders: shippingPartnerOrdersReducer,
    chat: chatReducer,
    merchantFeaturedProduct: merchantFeaturedProductsReducer,
    merchantStoreProfile: merchantStoreProfileReducer,
    themes: themesReducer,
    allPricingList: allPricingListReducer,
    adminLogin: adminLoginSlice,
    adminTransactions: adminTransactionsSlice,
    merchantTransactions: merchantTransactionsSlice,
    banks: banksReducer,
    allMerchantPolicies: allMerchantPoliciesReducer,
    createMerchantPolicy: createMerchantPolicyReducer,
    updateMerchantPolicy: updateMerchantPolicyReducer,
    merchantList: merchantListReducer,
    singleMerchantDetails: singleMerchantDetailsReducer,
    merchantInfoForPolicy: merchantInfoForPolicyReducer,
    salesReport: salesReportReducer,
    topMerchants: topMerchantProductsReducer,
    subscriptionCreateMerchantTracker: subscriptionCreateMerchantTrackerReducer,
    notifications: notificationsReducer,
    merchantStaff: merchantStaffReducer,
    getSchedulerOrdersList: getSchedulerOrdersListReducer,
    searchSchedulerOrdersList: searchSchedulerOrdersListReducer,
    searchDispatcherOrdersList: searchDispatcherOrdersListReducer,
    assignOrderToDispatcherData: assignOrderToDispatcherDataReducer,
    assignOrderToPartnerData: assignOrderToPartnerDataReducer,
    convertTokenFuncData: convertTokenFuncDataReducer,
    contactUsFuncData: contactUsFuncDataReducer,
    getDispatcherOrdersList: getDispatcherOrdersListReducer,
    getAllDispatchersList: getAllDispatchersListReducer,
    getAllShippingPartnersList: getAllShippingPartnersListReducer,
    adminStores: adminStoresReducer,
    adminStoreDetails: adminStoreDetailsReducer,
    adminStoreProducts: adminStoreProductReducer,
    superAdminAdmins: superAdminAdminsReducer,
    hubs: hubsReducer,
    hubApplciations: hubApplicationReducer,
    salesActivityReport: adminSalesActivityReducer,
    onboardingShippingPartner: onboardingShippingPartnerReducer,
    discount: discountReducer,
    vehicle: vehicleReducer,
    zone: zoneReducer,
    startCustomerReturnOrder: startCustomerReturnOrderReducer,
    merchantOrderReturn: merchantOrderReturnReducer,
    cms: cmsReducer,
    allCustomerStoresUnauthenticated: allCustomerStoresUnauthenticatedReducer,
    singleMerchantPaymentReport: singleMerchantPaymentReportReducer,
    officialOutletsProducts: officialOutletsProductsReducer,
    admins: adminsReducer,
    storeApprovers: storeApproversReducer,
    merchantProfile: merchantProfileReducer,
    adminMerchantPaymentsReport: adminMerchantPaymentsReportReducer,
    changePassword: changePasswordSliceReducer,
    shippingPartnerUpdatePassword: shippingPartnerUpdatePasswordSliceReducer,
    createProductReviews: createProductReviewsReducer,
    createMerchantReviews: createMerchantReviewsReducer,
    nextDaySettlementReport: adminSettlementReportReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    createTestimonial: createTestimonialReducer,
    getMerchantTestimonial: getMerchantTestimonialReducer,
    updateMerchantTestimonial: updateTestimonialReducer,
    financeReports: financeReportsReducer,
    approvedHubs: approvedHubsReducer,
    adminBitMarteHubOrders: adminBitMarteHubOrdersReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat([apiSlice.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
