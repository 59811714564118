import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useCookie } from 'next-cookie';
import toast from 'react-hot-toast';
import { AppThunk } from 'state/store';

const url = process.env.NEXT_PUBLIC_THEME_BASE_URL;

// eslint-disable-next-line react-hooks/rules-of-hooks
const cookie = useCookie();
const token = cookie.get('token');

export interface HeaderThemeData {
  logo: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
}

export interface BodyThemeData {
  title: string | null;
  subtitle: string | null;
  bannerImage: string | null;
  promoImage: string | null;
}

export interface ColorsThemeData {
  headingsAndLinks: string;
  bodyText: string;
  salePrice: string;
  primaryButton: string;
  primaryLabel: string;
  fieldText: string;
  fieldBorder: string;
  fieldBackground: string;
}

export interface SocialsData {
  twitter: string;
  facebook: string;
  pinterest: string;
  instagram: string;
  tumblr: string;
  snapchat: string;
  youtube: string;
  vimeo: string;
  tiktok: string;
  whatsapp: string;
}

interface themeLayoutState {
  activeThemeId: number;
  header: HeaderThemeData;
  body: BodyThemeData;
  colors: ColorsThemeData;
  socials: SocialsData;
  testimonials: { id: number; author: string; description: string }[];
  loading: boolean;
}

const initialState: themeLayoutState = {
  activeThemeId: 45,
  header: {
    logo: undefined,
    phoneNumber: undefined,
    email: undefined,
  },
  body: {
    title: null,
    subtitle: null,
    bannerImage: null,
    promoImage: null,
  },
  colors: {
    headingsAndLinks: '#f5b042',
    bodyText: '#000000',
    salePrice: '#000000',
    primaryButton: '#000000',
    primaryLabel: '#fff',
    fieldText: '#000000',
    fieldBorder: '#000000',
    fieldBackground: '#000000',
  },
  socials: {
    twitter: '',
    facebook: '',
    pinterest: '',
    instagram: '',
    tumblr: '',
    snapchat: '',
    youtube: '',
    whatsapp: '',
    tiktok: '',
    vimeo: '',
  },
  testimonials: [],
  loading: true,
};

const themeLayoutSlice = createSlice({
  name: 'themeLayout',
  initialState,
  reducers: {
    setHeader: (state, { payload }) => {
      state.header = payload;
    },
    setBody: (state, { payload }) => {
      state.body = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setColors: (state, { payload }) => {
      state.colors = payload;
    },
    setSocials: (state, { payload }) => {
      state.socials = payload;
    },
    setTestimonials: (state, { payload }) => {
      state.testimonials = payload;
    },
    setActiveThemeId: (state, { payload }) => {
      state.activeThemeId = payload;
    },
  },
});

export const {
  setHeader,
  setBody,
  setLoading,
  setColors,
  setSocials,
  setActiveThemeId,
  setTestimonials,
} = themeLayoutSlice.actions;

export const createTheme = (): AppThunk => async (dispatch) => {
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const { data } = await axios.post<{
      message: string;
      data: {
        id: number;
        name: string;
        updatedAt: Date;
        createdAt: Date;
        theme: null;
      };
      success: boolean;
    }>(
      `${url}/api/theme/create`,
      {
        name: 'debut',
      },
      requestConfig
    );

    dispatch(setActiveThemeId(data.data.id));

    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const fetchAllData = (): AppThunk => async (dispatch, getState) => {
  const activeThemeId = getState().themeLayout.activeThemeId;
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${url}/api/merchant/theme/customization/${activeThemeId}`,
      requestConfig
    );

    console.log('data return the api', data);

    if (data.data) {
      if (data?.data?.themeHeader) {
        const header = data?.data?.themeHeader;
        const theme = {
          phoneNumber: header.phoneNumber.toString(),
          email: header.email.toString(),
          logo: header.logo,
        };
        dispatch(setHeader(theme));
      }

      if (data?.data?.themeBody) {
        const body = data?.data.themeBody;

        const theme = {
          title: body.bannerMainText,
          subtitle: body.bannerSubText,
          bannerImage: body.bannerImage,
          promoImage: body.promoImage,
        };

        dispatch(setBody(theme));
      }

      if (data?.data?.themeColor) {
        const colors = data?.data.themeColor;

        const theme = {
          headingsAndLinks: colors.headingsAndLinks,
          bodyText: colors.bodyText,
          salePrice: colors.salePrice,
          primaryButton: colors.primaryButton,
          primaryLabel: colors.primaryLabel,
          fieldText: colors.fieldText,
          fieldBorder: colors.fieldBorder,
          fieldBackground: colors.fieldBackground,
        };

        dispatch(setColors(theme));
      }

      if (data?.data?.themeSocialMedia) {
        const socials = data?.data.themeSocialMedia;

        const theme = {
          ...socials,
        };

        dispatch(setSocials(theme));
      } else {
        dispatch(
          setSocials({
            twitter: '',
            facebook: '',
            pinterest: '',
            instagram: '',
            tumblr: '',
            snapchat: '',
            youtube: '',
            whatsapp: '',
            tiktok: '',
            vimeo: '',
          })
        );
      }
    }

    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

export const fetchHeader = (): AppThunk => async (dispatch, getState) => {
  const activeThemeId = getState().themeLayout.activeThemeId;
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const res = await axios.get<{
      message: string;
      data: {
        id: number;
        themeId: string;
        email: string[];
        phoneNumber: string[];
        merchantId: string;
        updatedAt: Date;
        createdAt: Date;
        logo: null;
      };
      success: boolean;
    }>(`${url}/api/merchant/theme/header/${activeThemeId}`, requestConfig);
    const { phoneNumber, email, logo } = res.data.data;
    const theme = {
      phoneNumber: phoneNumber.toString(),
      email: email.toString(),
      logo,
    };
    dispatch(setHeader(theme));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

export const fetchSocials = (): AppThunk => async (dispatch, getState) => {
  const activeThemeId = getState().themeLayout.activeThemeId;
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const res = await axios.get<{
      message: string;
      data: {
        id: number;
        themeId: string;
        twitter: string;
        facebook: string;
        pinterest: string;
        instagram: string;
        tumblr: string;
        snapchat: string;
        youtube: string;
        vimeo: string;
        merchantId: string;
        updatedAt: Date;
        createdAt: Date;
      };
      success: boolean;
    }>(`${url}/api/merchant/theme/socials/${activeThemeId}`, requestConfig);
    const {
      twitter,
      facebook,
      pinterest,
      instagram,
      tumblr,
      snapchat,
      youtube,
      vimeo,
    } = res.data.data;
    const theme = {
      twitter,
      facebook,
      pinterest,
      instagram,
      tumblr,
      snapchat,
      youtube,
      vimeo,
    };
    dispatch(setHeader(theme));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

export const fetchBody = (): AppThunk => async (dispatch, getState) => {
  const activeThemeId = getState().themeLayout.activeThemeId;
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const res = await axios.get(
      `${url}/api/merchant/theme/body/${activeThemeId}`,
      requestConfig
    );

    const { bannerImage, promoImage, bannerMainText, bannerSubText } =
      res.data.data;
    const themeData = {
      image: bannerImage,
      promo: promoImage,
      hText: bannerMainText,
      sText: bannerSubText,
    };
    dispatch(setBody(themeData));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

export const fetchTestimonials = (): AppThunk => async (dispatch, getState) => {
  const activeThemeId = getState().themeLayout.activeThemeId;
  try {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${url}api/testimonials?merchantThemeId=${activeThemeId}`,
      requestConfig
    );
    dispatch(setTestimonials(data.data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
  }
};

export const updateBody =
  (theme: {
    title?: string;
    subtitle?: string;
    bannerImage?: File;
    promoImage?: File;
  }): AppThunk =>
  async (dispatch, getState) => {
    const activeThemeId = getState().themeLayout.activeThemeId;

    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
        },
      };

      const formData = new FormData();

      console.log({
        bannerImage: theme.bannerImage,
      });

      if (theme.title) {
        formData.append('bannerMainText', JSON.stringify([theme.title]));
      }
      if (theme.subtitle) {
        formData.append('bannerSubText', theme.subtitle.toString());
      }
      if (theme.bannerImage) {
        formData.append('bannerImage', theme.bannerImage);
      }
      if (theme.promoImage) {
        formData.append('promoImage', theme.promoImage);
      }

      formData.append('merchantThemeId', activeThemeId.toString());

      const { data } = await axios.put(
        `${url}/api/merchant/theme/body`,
        formData,
        requestConfig
      );

      dispatch(setLoading(false));

      const response = data.data;

      dispatch(
        setBody({
          ...(response?.bannerMainText && {
            title: response?.bannerMainText.toString(),
          }),
          ...(response?.bannerSubText && {
            subtitle: response.bannerSubText.toString(),
          }),
          ...(response?.bannerImage && {
            bannerImage: response.bannerImage,
          }),
          ...(response?.promoImage && {
            promoImage: response.promoImage,
          }),
        })
      );
    } catch (error) {
      toast.error('Update failed');
      console.log(error);
    }
  };

export const updateHeader =
  (theme: {
    logo?: File | undefined;
    phoneNumber: string | null;
    email: string | null;
  }): AppThunk =>
  async (dispatch, getState) => {
    const activeThemeId = getState().themeLayout.activeThemeId;
    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
        },
      };

      const formData = new FormData();

      if (theme.phoneNumber) {
        formData.append('phoneNumber', JSON.stringify([theme.phoneNumber]));
      }
      if (theme.email) {
        formData.append('email', JSON.stringify([theme.email]));
      }
      if (theme.logo) {
        formData.append('logo', theme.logo);
      }

      formData.append('merchantThemeId', activeThemeId.toString());

      const res = await axios.put(
        `${url}/api/merchant/theme/header`,
        formData,
        requestConfig
      );

      const { phoneNumber, email, logo } = res.data.data;
      const themeData = {
        phoneNumber,
        email,
        logo,
      };
      dispatch(setHeader(themeData));
      dispatch(setLoading(false));
      toast.success('Theme updated');
      console.log({ res });
    } catch (error) {
      console.log(error);
    }
  };

export const updateSocials =
  (theme: SocialsData): AppThunk =>
  async (dispatch, getState) => {
    const activeThemeId = getState().themeLayout.activeThemeId;
    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };

      const { data } = await axios.put(
        `${url}/api/merchant/theme/social`,
        { merchantThemeId: activeThemeId, ...theme },
        requestConfig
      );

      const socials = data.data;

      dispatch(
        setSocials({
          ...socials,
        })
      );
      dispatch(setLoading(false));
      toast.success('Theme updated');
    } catch (error) {
      console.log(error);
    }
  };

export const addTestimonial =
  (testimonial: { author: string; description: string }): AppThunk =>
  async (dispatch, getState) => {
    const activeThemeId = getState().themeLayout.activeThemeId;
    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };

      await axios.post(
        `${url}api/merchant/testimonials`,
        { ...testimonial, merchantThemeId: activeThemeId },
        requestConfig
      );

      dispatch(fetchTestimonials());

      dispatch(setLoading(false));
      toast.success('Theme updated');
    } catch (error) {
      console.log(error);
    }
  };

export const deleteTestimonial =
  (testimonial: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };

      await axios.delete(
        `${url}/api/merchant/testimonials/${testimonial}`,
        requestConfig
      );

      dispatch(fetchTestimonials());

      dispatch(setLoading(false));
      toast.success('Theme updated');
    } catch (error) {
      console.log(error);
    }
  };

export const updateColors =
  (theme: ColorsThemeData): AppThunk =>
  async (dispatch, getState) => {
    const activeThemeId = getState().themeLayout.activeThemeId;
    try {
      dispatch(setLoading(true));
      const requestConfig = {
        headers: {
          Authorization: `bearer ${token}`,
        },
      };

      const res = await axios.put(
        `${url}/api/merchant/theme/color`,
        { ...theme, merchantThemeId: activeThemeId },
        requestConfig
      );

      const { phoneNumber, email, logo } = res.data.data;
      const themeData = {
        phoneNumber,
        email,
        logo,
      };
      dispatch(setHeader(themeData));
      dispatch(setLoading(false));
      toast.success('Theme updated');
    } catch (error) {
      toast.error('Theme updated failed');
      console.log(error);
    }
  };

export default themeLayoutSlice.reducer;
