import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantStoreProfileDetails } from '../../../services/apiFactory';

export const fetchMerchantProfile = createAsyncThunk(
  'merchant-profile/get',
  async () => {
    return getMerchantStoreProfileDetails()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface Address {
  LGA: string;
  state: string;
  street: string;
  country: string;
}

interface Bank {
  isEscrow: boolean;
  bankBranch: string;
  accountName: string;
  bankName: string;
  accountNumber: string;
}

interface Store {
  id: number;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo?: string;
  storePhoneNumber?: string;
  isRegisteredBusiness: boolean;
  registeredBusiness: any;
  shippingLocation: Address[];
  proofOfAddress?: string;
  isPhysicalLocation?: boolean;
  firstApproval: boolean;
  secondApproval: boolean;
  reasonForRejection: string;
  firstApprovalAdminId: string;
  secondApprovalAdminId: string;
  useBitmarteHub: boolean;
  bitmarteHubId?: number;
  createdAt: string;
  updatedAt: string;
  storeFollowers: [];
}

export interface StoreProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  nationality: string;
  idDocumentType: string;
  idDocumentNumber: string;
  idDocumentUrl?: string;
  rcNumber?: string;
  cacCertificateUrl?: string;
  memorandumOfAssociationUrl?: string;
  homeAddress: Address;

  useHomeAddress: boolean;
  proofOfAddress?: string;
  staffSize: number;
  category: number;
  role: string;
  roleId: number;
  confirm: boolean;
  isVerified: boolean;
  physicalLocation: boolean;
  pickupLocation: boolean;
  notificationEndPoint?: string;
  subCategoryIds: string[];
  uniqueId: string;
  createdAt: string;
  updatedAt: string;
  store: Store;
  bank?: Bank;
}

interface MercahntStoreProfileState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  profile: StoreProfile | null;
}

const initialState: MercahntStoreProfileState = {
  status: 'idle',
  error: '',
  profile: null,
};

const merchantStoreProfileSlice = createSlice({
  name: 'merchantStoreProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantProfile.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.profile = payload?.data || null;
      })
      .addCase(fetchMerchantProfile.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get merchant profile. Try again later.';
      });
  },
});

export default merchantStoreProfileSlice.reducer;
