import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Merchant } from 'state/features/merchant/getAllMerchantsSlice';
import { getSingleMerchantStore } from '../../../services/apiFactory';

export const getSingleMerchant = createAsyncThunk(
  'merchants/getSingleMerchantDetails',
  async (id: any) => {
    return getSingleMerchantStore(id)
      .then((res) => {
        const data = res.data;
        console.log(data);
        return data;
      })
      .catch((err) => {
        throw JSON.stringify(err.response);
      });
  }
);

interface SingleMerchantState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  merchant: Merchant;
  error: string;
}

const initialState: SingleMerchantState = {
  status: 'idle',
  merchant: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    nationality: '',
    idDocumentType: '',
    idDocumentNumber: '',
    idDocumentUrl: '',
    homeAddress: [
      {
        LGA: '',
        state: '',
        street: '',
        country: '',
      },
    ],
    useHomeAddress: true,
    proofOfAddress: null,
    staffSize: 0,
    category: 0,
    password: '',
    role: '',
    roleId: 0,
    confirm: false,
    isVerified: false,
    store: {
      createdAt: '',
      id: 0,
      isRegisteredBusiness: false,
      logo: '',
      merchantId: '',
      officeAddress: [],
      products: [],
      proofOfAddress: null,
      storeFollowers: [],
      storeName: '',
      storeUrl: '',
      updatedAt: '',
    },
    physicalLocation: false,
    pickupLocation: false,
    subCategoryIds: null,
    createdAt: false,
    updatedAt: false,
  },
  error: '',
};

const MerchantsListSlice = createSlice({
  name: 'merchantList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleMerchant.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSingleMerchant.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.merchant = payload?.data || null;
      })
      .addCase(getSingleMerchant.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get merchant details. Try again later.';
      });
  },
});

export default MerchantsListSlice.reducer;
