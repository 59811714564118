import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
  getResources,
  addStoreStaff,
  getAllStaff,
  getOneStaff,
  deleteStoreStaff as DS,
  updateStoreStaff as US,
} from '../../../services/apiFactory';

export const fetchAllResources = createAsyncThunk(
  'merchant/fetchAllResources',
  async () => {
    return getResources()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const fetchAllStaff = createAsyncThunk(
  'merchant/fetchAllStaff',
  async () => {
    return getAllStaff()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const deleteStoreStaff = createAsyncThunk(
  'merchant/deleteStoreStaff',
  async (data: { id: number }) => {
    const { id } = data;
    return DS(id)
      .then((res) => {
        const data = res.data;

        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const fetchStaff = createAsyncThunk(
  'merchant/fetchStaff',
  async (data: { id: number }) => {
    const { id } = data;
    return getOneStaff(id)
      .then((res) => {
        const data = res.data;

        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);
export const createStoreStaff = createAsyncThunk(
  'merchant/createStoreStaff',
  async (data: any) => {
    return addStoreStaff(data)
      .then((res) => {
        const data = res.data;
        toast.success('Store staff successfully created');
        return data;
      })
      .catch((err) => {
        // console.log(err.response)
        // console.log(err.response.data.errors[0].msg)
        if (err.response.status === 422) {
          toast.error(err.response.data.errors[0].msg);
        } else {
          toast.error(err.response.data.message);
        }
        // toast.error('Something went wrong');
        throw err.response.data;
      });
  }
);
export const updateStoreStaff = createAsyncThunk(
  'merchant/updateStoreStaff',
  async (data: { staffid: string; payload: object }) => {
    const { staffid, payload } = data;
    return US(staffid, payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        console.log(err, 'error');
        toast.error('Something went wrong');
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Resource {
  id: number;
  name: string;
  access: string;
}

export interface Staff {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface ProfileState {
  status: PromiseStatus;
  resources: Resource[];
  staffs: Staff[];
  staff: any;
}

const initialState: ProfileState = {
  status: 'idle',
  resources: [],
  staffs: [],
  staff: {},
};

const merchantStaffSlice = createSlice({
  name: 'storeStaff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllResources.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllResources.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.resources = data;
      })
      .addCase(fetchAllResources.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(createStoreStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStoreStaff.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(createStoreStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchAllStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllStaff.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.staffs = data;
      })
      .addCase(fetchAllStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStaff.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.staff = data;
      })
      .addCase(fetchStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(deleteStoreStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStoreStaff.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(deleteStoreStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(updateStoreStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStoreStaff.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(updateStoreStaff.rejected, (state, { error }) => {
        state.status = 'failed';
      });
  },
});

export default merchantStaffSlice.reducer;
