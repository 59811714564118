import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMerchantTransaction,
  getMerchantTransactions,
} from '../../../services/apiFactory';

export const fetchMerchantTransactions = createAsyncThunk(
  'merchantTransactions/getTransactions',
  async () => {
    return await getMerchantTransactions()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const fetchMerchantTransaction = createAsyncThunk(
  'merchantTransactions/getTransaction',
  async (data: { id: string }) => {
    const { id } = data;
    return await getMerchantTransaction(id)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export interface MerchantTransactionOrderItem {
  merchantId: number;
  orderName: string;
  total: string;
}
export interface MerchantTransaction {
  id: number;
  orderTrackerId: any;
  transactionId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  amount: string;
  customerName: string;
  reference: string;
  fees: string;
  date: string;
  customerEmail: string;
  cardType: string;
  cardNumber: string;
  bank: string;
  country: string;
  ipAddress: string;
  paymentChannel: string;
  orderName: string;
  orderItems: MerchantTransactionOrderItem[];
}

interface MerchantTransactionsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string | null;
  transactions: MerchantTransaction[];
  transaction: MerchantTransaction;
  isError: boolean;
}

const initialState: MerchantTransactionsState = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  transactions: [],
  transaction: {
    id: 1,
    orderTrackerId: 5,
    transactionId: '',
    amount: '',
    reference: '',
    fees: '',
    date: '',
    customerName: ' ',
    customerEmail: '',
    cardType: ' ',
    cardNumber: '',
    bank: '',
    country: '',
    ipAddress: '',
    paymentChannel: '',
    status: '',
    orderName: '',
    createdAt: '',
    updatedAt: '',
    orderItems: [
      {
        orderName: '',
        merchantId: 0,
        total: '',
      },
    ],
  },
};

const merchantTransactionsSlice = createSlice({
  name: 'merchantTransactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantTransactions.fulfilled, (state, { payload }) => {
        const data = payload?.data;

        state.status = 'succeeded';
        state.transactions = data;
        // state.transactions = data?.map((item: any) => ({
        //   ...item,
        //   // channel: item?.paymentChannel,
        // }));
      })
      .addCase(fetchMerchantTransactions.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchMerchantTransaction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMerchantTransaction.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.status = 'succeeded';
        state.transaction = {
          ...data,
          orderItems: data?.orderTracker?.orderItems,
          orderName: data?.orderTracker?.orderName,
        };
      })
      .addCase(fetchMerchantTransaction.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default merchantTransactionsSlice.reducer;
