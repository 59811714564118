import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getApprovedStores,
  getUnapprovedStores,
} from '../../../services/apiFactory';

export const getAdminUnapprovedStores = createAsyncThunk(
  'admin/unapproved-stores',
  async () => {
    return getUnapprovedStores()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const getAdminApprovedStores = createAsyncThunk(
  'admin/approved-stores',
  async () => {
    return getApprovedStores()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface ShippingLocation {
  state: string;
  street: string;
  country: string;
}

export interface Store {
  id: number;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo?: string;
  storePhoneNumber?: string;
  isRegisteredBusiness: boolean;
  shippingLocation?: ShippingLocation[];
  firstApproval: boolean;
  secondApproval: boolean;
}

interface AdminStoresState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  unapprovedStores: Store[];
  approvedStores: Store[];
}

const initialState: AdminStoresState = {
  status: 'idle',
  error: '',
  unapprovedStores: [],
  approvedStores: [],
};

const adminStoresSlice = createSlice({
  name: 'adminStores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminUnapprovedStores.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAdminUnapprovedStores.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.unapprovedStores = payload.data || [];
      })
      .addCase(getAdminUnapprovedStores.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      })
      .addCase(getAdminApprovedStores.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAdminApprovedStores.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.approvedStores = payload.data || [];
      })
      .addCase(getAdminApprovedStores.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      });
  },
});

export default adminStoresSlice.reducer;
