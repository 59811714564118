import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantTestimonialFunc } from 'services/apiFactory';

// 'customerOrderInfo/getCustomerOrderInfo',
export const getAllMerchantTestimonials = createAsyncThunk(
  'merchantPolicy',
  async () => {
    // console.log('storeId',storeId)
    return getMerchantTestimonialFunc()
      .then((res: any) => {
        // console.log(res)
        // console.log('hello from slice')
        const data = res.data;
        // console.log(data)
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Testimonial {
  data: {
    id: number;
    merchantId: number;
    storeId: number;
    customerName: string;
    title: string;
    description: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

interface TestimonialState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  testimonial: Testimonial[];
}

const initialState: TestimonialState = {
  status: 'idle',
  error: '',
  testimonial: [],
};

const getMerchantTestimonial = createSlice({
  name: 'getMerchantTestimonial',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchantTestimonials.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllMerchantTestimonials.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.testimonial = payload.data;
      })
      .addCase(getAllMerchantTestimonials.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message ||
          'Could not information on this order. Try again later.';
      });
  },
});

export default getMerchantTestimonial.reducer;
