import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getHubs } from '../../../services/apiFactory';

export const fetchBitmarteHubs = createAsyncThunk('hubs/get', async () => {
  return getHubs()
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw err.response.data;
    });
});

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

interface ShippingLocation {
  country: string;
  state: string;
  street: string;
}
export interface Hub {
  id: number;
  designation: string;
  shippingLocation: ShippingLocation[];
}

interface ProfileState {
  status: PromiseStatus;
  hubs: Hub[];
  message: string;
}

const initialState: ProfileState = {
  status: 'idle',
  hubs: [],
  message: '',
};

const hubsSlice = createSlice({
  name: 'hubs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBitmarteHubs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBitmarteHubs.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.hubs = payload?.data || [];
        state.message = 'Successfully fethced bitmarte hubs.';
      })
      .addCase(fetchBitmarteHubs.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export default hubsSlice.reducer;
