import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAdminMerchantPayments,
  getAdminMerchantsStats,
  updateAdminMerchantPayment,
} from 'services/apiFactory';

export const getAdminMerchantPaymentsReport = createAsyncThunk(
  'paymentReport/merchant-payments',
  async () => {
    return getAdminMerchantPayments()
      .then((res) => {
        if (res.data.success) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
export const getAdminMerchantPaymentsStats = createAsyncThunk(
  'paymentReport/payment-stats',
  async () => {
    return getAdminMerchantsStats()
      .then((res) => {
        if (res.data.success) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const updateAdminMerchantPaymentReport = createAsyncThunk(
  'paymentReport/update-merchant-payment',
  async (payload: { orderNo: string; date: string }) => {
    updateAdminMerchantPayment(payload)
      .then((res) => {
        if (res.data.success) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => console.log(err));
  }
);

interface AdminMerchantPayment {
  merchantName: string;
  orderDate: string;
  orderNo: string;
  salesOrderAmount: string;
  deliveryAmount: string;
  totalOrderAmount: string;
  amountDueToMerchant: string;
  paymentStatus: boolean;
  paymentDate: string | null;
}

interface AdminMerchantPaymentReportState {
  paymentsReport: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    adminMerchantPayments: AdminMerchantPayment[];
    error: string;
  };
  paymentStats: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    adminMerchantPaymentsStats: {
      processedPayment: number;
      approvedPayment: number;
      pendingPayment: number;
    };
    error: string;
  };
}
const initialState: AdminMerchantPaymentReportState = {
  paymentsReport: {
    status: 'idle',
    adminMerchantPayments: [],
    error: '',
  },
  paymentStats: {
    status: 'idle',
    adminMerchantPaymentsStats: {
      processedPayment: 0,
      approvedPayment: 0,
      pendingPayment: 0,
    },
    error: '',
  },
};

const AdminMerchantPaymentReportSlice = createSlice({
  name: 'admin/merchantReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminMerchantPaymentsReport.pending, (state) => {
        state.paymentsReport.status = 'loading';
      })
      .addCase(
        getAdminMerchantPaymentsReport.fulfilled,
        (state, { payload }) => {
          state.paymentsReport.status = 'succeeded';
          state.paymentsReport.adminMerchantPayments = payload?.data || [];
        }
      )
      .addCase(getAdminMerchantPaymentsReport.rejected, (state, { error }) => {
        state.paymentsReport.status = 'failed';
        state.paymentsReport.error =
          error.message || 'Error getting payment report';
      })
      .addCase(updateAdminMerchantPaymentReport.pending, (state) => {
        state.paymentsReport.status = 'loading';
      })
      .addCase(
        updateAdminMerchantPaymentReport.fulfilled,
        (state, { payload }) => {
          state.paymentsReport.status = 'succeeded';
        }
      )
      .addCase(
        updateAdminMerchantPaymentReport.rejected,
        (state, { error }) => {
          state.paymentsReport.status = 'failed';
          state.paymentsReport.error =
            error.message || 'Error updating payment report';
        }
      )
      .addCase(getAdminMerchantPaymentsStats.pending, (state) => {
        state.paymentStats.status = 'loading';
      })
      .addCase(
        getAdminMerchantPaymentsStats.fulfilled,
        (state, { payload }) => {
          state.paymentStats.status = 'succeeded';
          state.paymentStats.adminMerchantPaymentsStats = payload?.data;
        }
      )
      .addCase(getAdminMerchantPaymentsStats.rejected, (state, { error }) => {
        state.paymentStats.status = 'failed';
        state.paymentStats.error =
          error.message || 'Error getting payment report';
      });
  },
});

export default AdminMerchantPaymentReportSlice.reducer;
