import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCustomerStoresUnauthenticatedFunc } from 'services/apiFactory';

export const getAllCustomerStoresUnauthenticated = createAsyncThunk(
  'customer/stores/unauthenticated',
  async () => {
    return getAllCustomerStoresUnauthenticatedFunc()
      .then((res: any) => {
        // console.log(res)
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface Store {
  id: number;
  merchantId: string;
  storeName: string;
  storeUrl: string;
  logo: string | null;
  storePhoneNumber?: null;
  isRegisteredBusiness?: boolean;
  shippingLocation?: {
    state: string;
    street: string;
    country: string;
  }[];
  proofOfAddress?: null;
  isPhysicalLocation?: null;
  firstApproval?: boolean;
  secondApproval?: boolean;
  reasonForRejection?: string;
  firstApprovalAdminId?: null;
  secondApprovalAdminId?: null;
  useBitmarteHub?: boolean;
  bitmarteHubId?: null;
  createdAt: string;
  updatedAt: string;
}

interface StoresState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  stores: {
    allStore: Store[];
  };
}

const initialState: StoresState = {
  status: 'idle',
  error: '',
  stores: {
    allStore: [],
  },
};

const customerStoresUnauthenticatedSlice = createSlice({
  name: 'allCustomerStoresUnauthenticated',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomerStoresUnauthenticated.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getAllCustomerStoresUnauthenticated.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          state.stores = payload.data;
        }
      )
      .addCase(
        getAllCustomerStoresUnauthenticated.rejected,
        (state, { error }) => {
          state.status = 'failed';
          state.error =
            error.message || 'Could not get stores. Try again later.';
        }
      );
  },
});

export default customerStoresUnauthenticatedSlice.reducer;
