import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword } from '../../../services/apiFactory';

export const shippingPartnerPassword = createAsyncThunk(
  'shippingPartner/getOrders',
  async (payload: any) => {
    return changePassword(payload)
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => {
        // const errors = err.response.data.message;
        const errors = err.response.data;

        return errors;
      });
  }
);

interface passwordDetails {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusMessage: string;
  isError: boolean;
  shippingPartnerPasswordState: any;
}
const initialState: passwordDetails = {
  status: 'idle',
  statusMessage: '',
  isError: false,
  shippingPartnerPasswordState: {
    oldPassword: '',
    newPassword: '',
  },
};

const shippingPartnerUpdatePasswordSlice = createSlice({
  name: 'shippingPartnerUpdatePassword',
  initialState,
  reducers: {
    setShippingPartnerPasswordState: (state, { payload }) => {
      // console.log(payload)
      state.shippingPartnerPasswordState = payload;
    },
    clearStatusMsg: (state, { payload }) => {
      (state.isError = payload.isError),
        (state.statusMessage = payload.statusMsg);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(shippingPartnerPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(shippingPartnerPassword.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.status = 'succeeded';
          state.statusMessage = payload?.message;
          state.isError = false;
          state.shippingPartnerPasswordState = {
            oldPassword: '',
            newPassword: '',
          };
        } else {
          state.status = 'failed';
          state.isError = true;
          state.statusMessage = payload?.message;
        }
      })
      .addCase(shippingPartnerPassword.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.isError = true;
        state.statusMessage = 'There was an error. Please try again';
      });
  },
});
export const { setShippingPartnerPasswordState, clearStatusMsg } =
  shippingPartnerUpdatePasswordSlice.actions;
export default shippingPartnerUpdatePasswordSlice.reducer;
