import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProducts, updateProduct } from '../../../services/apiFactory';

// Axios Request functions for merchant discount
export const getMerchatProducts = createAsyncThunk(
  'merchant/products',
  async () => {
    return getProducts()
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const updateMerchantProduct = createAsyncThunk(
  'merchant/product/update ',
  async (data: { id: number; payload: any }) => {
    const { id, payload } = data;
    return updateProduct(id, payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Product {
  id: number;
  name: string;
  pricing: { price: number }[];
  quantity: number;
  sku: string;
  contSalesOutOfStock: false;
  createdAt: string;
  updatedAt: string;
  hostedImages: string[];
  whenSoldOut?: string;
  type?: string;
  approvalStatus?: string;
  storeName?: string;
  isApproved?: boolean;
  productVariant?: {
    createdAt: string;
    id: number;
    productId: number;
    properties: {
      type: string;
      options: string[];
    }[];
    length: number;
    updatedAt: string;
  };
}

interface getProductsState {
  status: PromiseStatus;
  products: Product[];
  error: string;
  product: {
    status: PromiseStatus;
    message: string;
  };
}

const initialState: getProductsState = {
  status: 'idle',
  products: [],
  error: '',
  product: {
    status: 'idle',
    message: '',
  },
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setUpdateStatus: (state, action: { payload: PromiseStatus }) => {
      state.product.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchatProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchatProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.products = payload?.data?.products || [];
      })
      .addCase(getMerchatProducts.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || '';
      })
      .addCase(updateMerchantProduct.pending, (state) => {
        state.product.status = 'loading';
      })
      .addCase(updateMerchantProduct.fulfilled, (state) => {
        state.product.status = 'succeeded';
        state.product.message = 'Product updated successfully';
      })
      .addCase(updateMerchantProduct.rejected, (state, { error }) => {
        state.product.status = 'failed';
        console.log(error);
        state.error =
          error.message || 'Could not update product. Try again later.';
      });
  },
});

export const { setProducts, setUpdateStatus } = productsSlice.actions;
export default productsSlice.reducer;
