import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizedUser } from 'services/auth';
import { useCookie } from 'next-cookie';
import {
  shippingPartnerLogin,
  shippingPartnerSignup,
} from 'services/apiFactory';

const cookie = useCookie();
// Axios Request functions for merchant discount
export const loginShippingPartner = createAsyncThunk(
  'shippingPartner/Login',
  async (payload: object) => {
    // console.log(payload)
    return shippingPartnerLogin(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        // console.log('full user', data);
        setAuthorizedUser('token', data);

        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

// data: {
//           firstName: data.data.name,
//           image: data.data.logo,
//           email: data.data.email,
//           companyName: data.data.shippingCompany.companyName,
//         },
export const signupShippingPartner = createAsyncThunk(
  'shippingPartner/Signup',
  async (payload: object) => {
    // console.log(payload)
    return shippingPartnerSignup(payload)
      .then((res: { data: any }) => {
        const data = res.data;
        return data;
      })
      .catch((err: { response: any }) => {
        const errors = err.response.data.message;
        return errors;
      });
  }
);

const user = true;
const initialState = user
  ? {
      isLoggedIn: true,
      statusMessage: '',
      isError: false,
      userAccess: cookie.get('token'),
    }
  : { isLoggedIn: false, statusMessage: '', isError: true };

const shippingPartnerLoginSlice = createSlice({
  name: 'shippingPartnerLogin',
  initialState,
  reducers: {
    setStatusMessage: (state: { statusMessage: any }, { payload }: any) => {
      state.statusMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginShippingPartner.fulfilled, (state, { payload }) => {
        console.log({ payload });
        const user: any = JSON.stringify({
          firstName: payload.data?.name,
          image: payload.data?.logo,
          email: payload.data?.email,
          companyName: payload.data?.shippingCompany,
        });

        localStorage.setItem('user', user);
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isLoggedIn = true;
          state.isError = false;
        } else {
          state.isLoggedIn = false;
          state.isError = true;
          state.statusMessage = payload;
        }
      })
      .addCase(loginShippingPartner.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      })
      .addCase(signupShippingPartner.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statusMessage = payload?.message;
          state.isError = false;
        }
      })
      .addCase(signupShippingPartner.rejected, (state) => {
        state.statusMessage = 'Check network connection';
        state.isError = true;
      });
  },
});
export const { setStatusMessage } = shippingPartnerLoginSlice.actions;
export default shippingPartnerLoginSlice.reducer;
