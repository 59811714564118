import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const $API_HOST = 'https://api.paystack.co';
export const $AuthHeader = {
  Accept: 'application/json',
  Authorization: 'Bearer sk_live_825c089a96cea910e4c9ad23236870cb6042660a',
  // Authorization: `Bearer ${process.env.NEXT_PUBLIC_PAYSTACK_SECRET_KEY}`,
};

export const AxiosHost = axios.create({
  baseURL: $API_HOST,
  headers: $AuthHeader,
});

export const getBanks = createAsyncThunk('banks', async () => {
  const url = `/bank`;
  try {
    const res = await AxiosHost.get(url);
    const data = res.data;
    // console.log(data);
    return data;
  } catch (err: any) {
    throw err.response.data;
  }
});

export const verifyAccount = createAsyncThunk(
  'banks/verifyAccount',
  async (AccData: any) => {
    const url = `/bank/resolve?account_number=${AccData.AccNumber}&bank_code=${AccData.bankCode}`;
    // const url =`/resolveAccount`
    try {
      const res = await AxiosHost.get(url);
      const data = res.data;
      return data;
    } catch (err: any) {
      throw err.response.data;
    }
  }
);

// export const resolveBankAccount = (payload) =>{
//   const url =`/resolveAccount`
//   return AxiosHost.post(url,payload)
// }

export interface Bank {
  name?: string;
  slug?: string;
  code?: string;
  longcode?: string;
  gateway?: string;
  pay_with_bank?: true;
  active?: true;
  is_deleted?: null;
  country?: string;
  currency?: string;
  type?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

interface Account {
  account_number: string;
  account_name: string;
  bank_id: number;
}
interface BanksState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  banks: Bank[];
  account: Account | null;
}

const initialState: BanksState = {
  status: 'idle',
  error: '',
  banks: [],
  account: null,
};

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBanks.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.banks = payload.data;
      })
      .addCase(getBanks.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      })
      .addCase(verifyAccount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.account = payload.data;
      })
      .addCase(verifyAccount.rejected, (state, { error }) => {
        state.status = 'failed';
        state.account = null;
        state.error =
          error.message || 'Could not get user categories. Try again later.';
      });
  },
});

export default banksSlice.reducer;
