import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchDispatcherOrdersFunc } from 'services/apiFactory';
import { orderStatuses } from 'state/features/orders/merchantOrdersSlice';

export const searchDispatcherOrders = createAsyncThunk(
  'admin/search/dispatch/orders',
  async ({ search }: { search: string }) => {
    return searchDispatcherOrdersFunc({ search })
      .then((res: any) => {
        // console.log('res', res);
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface DispatcherOrderProps {
  id: number;
  orderName: string;
  orderNote: string;
  orderStatusId: number;
  partnerAssignOrderId: null;
  dispatcherId: null;
  subtotal: string;
  shippingFee: number;
  total: string;
  customerId: number;
  merchantId: number;
  storeName: string;
  orderItems: {
    quantity: number;
    productId: number;
    storeName: string;
    merchantId: number;
    productName: string;
    shippingFee: number;
    hostedImages: string[];
    productPrice: number;
    productVariants: {
      sku: string;
      size: string;
      color: string;
      discount: number;
      quantity: number;
    }[];
  }[];
  rewardProductsIds: [];
  deliveryAddress: number;
  dispatchPhoneNumber: null;
  orderDeliveryToken: null;
  approvePayout: boolean;
  assignedToBitmarte: boolean;
  assignedToDispatcher: boolean;
  assignedToShippingPartner: boolean;
  withinLagos: boolean;
  createdAt: string;
  updatedAt: string;
  orderStatus: string;
  orderActivities: [];
}

interface DispatcherOrderPropsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  searchDispatcherOrderList: DispatcherOrderProps[];
  selectedOrder: DispatcherOrderProps;
  orderStatuses: orderStatuses[];
  shippingPartners: any[];
}

const initialState: DispatcherOrderPropsState = {
  status: 'idle',
  error: '',
  searchDispatcherOrderList: [],
  orderStatuses: [],
  selectedOrder: {
    id: 1,
    orderName: '',
    orderNote: '',
    orderStatusId: 2,
    partnerAssignOrderId: null,
    dispatcherId: null,
    subtotal: '',
    shippingFee: 3,
    total: '',
    customerId: 1,
    merchantId: 1,
    storeName: '',
    orderItems: [],
    rewardProductsIds: [],
    deliveryAddress: 3,
    dispatchPhoneNumber: null,
    orderDeliveryToken: null,
    approvePayout: false,
    assignedToBitmarte: false,
    assignedToDispatcher: false,
    assignedToShippingPartner: false,
    withinLagos: false,
    createdAt: '',
    updatedAt: '',
    orderStatus: '',
    orderActivities: [],
  },
  shippingPartners: [],
};

const searchDispatcherOrdersSlice = createSlice({
  name: 'searchDispatcherOrdersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchDispatcherOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchDispatcherOrders.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.searchDispatcherOrderList = payload.data;
      })
      .addCase(searchDispatcherOrders.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default searchDispatcherOrdersSlice.reducer;
