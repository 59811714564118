import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addMerchantStoreProfile,
  getMerchantStoreProfile,
} from '../../../services/apiFactory';

export const addStoreProfile = createAsyncThunk(
  'merchant/storeProfile/add',
  async (data: any) => {
    return addMerchantStoreProfile(data)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const getStoreProfile = createAsyncThunk(
  'merchant/storeProfile/get',
  async (data: { id: number }) => {
    return getMerchantStoreProfile(data.id)
      .then((res) => {
        const data = res.data;
        // console.log({ data });
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Profile {
  facebook: string;
  instagram: string;
  pinterest: string;
  snapchat: string;
  tiktok: string;
  tumblr: string;
  twitter: string;
  vimeo: string;
  youtube: string;
  store: {
    bitmarteHubId: string;
    logo: string;
    storeName: string;
    storePhoneNumber: string;
    isPhysicalLocation: boolean;
    shippingLocation: {
      country: string;
      state: string;
      street: string;
      city?: string;
    };
    firstApproval: boolean;
    secondApproval: boolean;
  };
}

interface ProfileState {
  status: PromiseStatus;
  profile: Profile;
  message: string;
}

const initialState: ProfileState = {
  status: 'idle',
  profile: {
    facebook: '',
    instagram: '',
    pinterest: '',
    snapchat: '',
    tiktok: '',
    tumblr: '',
    twitter: '',
    vimeo: '',
    youtube: '',
    store: {
      bitmarteHubId: '',
      logo: '',
      storeName: '',
      storePhoneNumber: '',
      isPhysicalLocation: false,
      shippingLocation: {
        country: '',
        state: '',
        street: '',
        city: '',
      },
      firstApproval: false,
      secondApproval: false,
    },
  },
  message: '',
};

const merchantProductSlice = createSlice({
  name: 'newProduct',
  initialState,
  reducers: {
    setProduct: (state, action: { payload: Profile }) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStoreProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addStoreProfile.fulfilled, (state) => {
        state.status = 'succeeded';
        state.message = 'Successfully added store profile.';
      })
      .addCase(addStoreProfile.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      })
      .addCase(getStoreProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStoreProfile.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload?.data) {
          state.profile = payload.data[payload.data.length - 1];
        }
        state.message = 'Successfully fetched store profile.';
      })
      .addCase(getStoreProfile.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'An Error occured. Please try again later.';
      });
  },
});

export const { setProduct } = merchantProductSlice.actions;
export default merchantProductSlice.reducer;
