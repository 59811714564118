import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getChildrenCategories } from '../../../services/apiFactory';

export const getMerchantChildrenCategories = createAsyncThunk(
  'merchant/childrenCategories',
  async (id: number) => {
    return getChildrenCategories(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export interface ChildrenCategory {
  id: number;
  name: string;
}

interface ChildrenCategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  childrenCategories: ChildrenCategory[];
}

const initialState: ChildrenCategoriesState = {
  status: 'idle',
  error: '',
  childrenCategories: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantChildrenCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        getMerchantChildrenCategories.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          state.childrenCategories = payload.data;
        }
      )
      .addCase(getMerchantChildrenCategories.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message ||
          'Could not get children categories. Try again later.';
      });
  },
});

export default categoriesSlice.reducer;
