import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { VariantsCombination } from 'interfaces/merchant/products/VariantProps';
import {
  approveProduct,
  getAdminSingleProduct,
} from '../../../services/apiFactory';

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Product {
  id?: number;
  name: string;
  approvalStatus?: string;
  description: any[];
  category: string;
  categoryId: number;
  subCategory: string;
  subCategoryId: number;
  childCategory: string;
  childCategoryId: number;
  hostedImages: string[];
  pricing: [
    {
      costPrice: number;
      sellingPrice: number;
      price: number;
    }
  ];
  sku: string;
  quantity: number;
  weight: number;
  dimension: {
    length: number;
    width: number;
    height: number;
  };
  productVariant?: {
    variant: VariantsCombination[];
  };
  merchantId?: number;
  store?: any;
}

interface getProductsState {
  status: PromiseStatus;
  error: string;
  product: Product;
  productApprovalStatus: PromiseStatus;
  productApprovalError: string;
}

interface ApproveProductPayload {
  approvalStatus: 'approved' | 'disapproved';
  dimension: {
    width: number;
    height: number;
    length: number;
  };

  weight: number;
  reasonOfDisapproval: string;
}

export const fetchAdminSingleProduct = createAsyncThunk(
  'product/adminSingleProduct',
  async (id: number) => {
    return getAdminSingleProduct(id)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

export const adminApproveProduct = createAsyncThunk(
  'product/adminApproveProduct',
  async ({
    productId,
    merchantId,
    payload,
  }: {
    productId: number;
    merchantId: number;
    payload: ApproveProductPayload;
  }) => {
    return approveProduct({ productId, merchantId, payload })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

const initialState: getProductsState = {
  status: 'idle',
  product: {
    name: '',
    description: [],
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    childCategory: '',
    childCategoryId: 0,
    hostedImages: [],
    pricing: [
      {
        costPrice: 0,
        sellingPrice: 0,
        price: 0,
      },
    ],
    quantity: 0,
    sku: '',
    weight: 0,
    dimension: {
      length: 0,
      width: 0,
      height: 0,
    },
    productVariant: {
      variant: [],
    },
  },
  error: '',
  productApprovalStatus: 'idle',
  productApprovalError: '',
};

const adminSingleProductSlice = createSlice({
  name: 'newProduct',
  initialState,
  reducers: {
    editProduct: (state, action) => {
      state.product = {
        ...state.product,
        ...action.payload,
      };
    },
    setApprovalStatus: (state, action: { payload: PromiseStatus }) => {
      state.productApprovalStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminSingleProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminSingleProduct.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.product = payload.data;
      })
      .addCase(fetchAdminSingleProduct.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get product. Try again later.';
      })
      .addCase(adminApproveProduct.pending, (state) => {
        state.productApprovalStatus = 'loading';
      })
      .addCase(adminApproveProduct.fulfilled, (state) => {
        state.productApprovalStatus = 'succeeded';
      })
      .addCase(adminApproveProduct.rejected, (state, { error }) => {
        state.productApprovalStatus = 'failed';
        state.productApprovalError =
          error.message || 'Could not approve product. Try again later.';
      });
  },
});

export const { editProduct, setApprovalStatus } =
  adminSingleProductSlice.actions;
export default adminSingleProductSlice.reducer;
