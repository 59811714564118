import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userLogOut } from 'services/auth';
import { getUserData } from '../../../services/apiFactory';
import { useCookie } from 'next-cookie';

const cookie = useCookie();
// Axios Request functions for merchant discount
export const getMerchantUserInfo = createAsyncThunk(
  'merchant/user/info',
  async () => {
    return getUserData()
      .then((res) => {
        const data = res.data;
        // console.log(data)
        return data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
);

interface UserDataState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  isLoggedIn: any;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    category: number;
    role: string;
    storeId: number;
    storeName: string;
    isRegisteredBusiness: boolean;
    image?: string;
    lastLogin: string;
  };
}

const initialState: UserDataState = {
  status: 'idle',
  error: '',
  // isLoggedIn: cookie.get('token') || '',
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    category: 0,
    role: '',
    storeId: 0,
    storeName: '',
    isRegisteredBusiness: false,
    image: '',
    lastLogin: '',
  },
  isLoggedIn: undefined,
};

const getMerchantForPolicySlice = createSlice({
  name: 'merchantInfoForPolicy',
  initialState,
  reducers: {
    // setUserData(state, action) {
    //   state.user = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantUserInfo.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.user = payload.data;
      })
      .addCase(getMerchantUserInfo.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error =
          error.message || 'Could not get user data. Try again later.';
      });
  },
});

// export const { setUserData } = getMerchantForPolicySlice.actions;
export default getMerchantForPolicySlice.reducer;
