import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantPaymentReportFunc } from 'services/apiFactory';

export const getMerchantPaymentReport = createAsyncThunk(
  'single/merchant/payment/report',
  async () => {
    return getMerchantPaymentReportFunc()
      .then((res: any) => {
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface PaymentReport {
  id: number;
  merchantName: string;
  orderDate: string;
  orderNo: string;
  salesOrderAmount: string;
  deliveryAmount: string;
  totalOrderAmount: string;
  amountDueToMerchant: string;
  paymentStatus: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
}

interface PaymentReportState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  reports: PaymentReport[];
  error: string;
}

const initialState: PaymentReportState = {
  status: 'idle',
  reports: [],
  error: '',
};

const merchantPaymentReportSlice = createSlice({
  name: 'singleMerchantPaymentReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantPaymentReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMerchantPaymentReport.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.reports = payload.data;
      })
      .addCase(getMerchantPaymentReport.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default merchantPaymentReportSlice.reducer;
