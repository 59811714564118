/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
// import { getAccessToken } from '../services/auth';
import { useCookie } from 'next-cookie';

const cookie = useCookie();

const $API_HOST = process.env.NEXT_PUBLIC_APP_BASE_URL;

// This is temporary used for the get all orders and get order by id until the customer login and sign up is done.
const $TEMP_API_HOST = process.env.NEXT_PUBLIC_APP_TEMP_BASE_URL;
// This is temporary used for the get all orders and get order by id until the customer login and sign up is done.

export const getAccessToken = (value) => {
  console.log(value);
  return value;
};

console.log(getAccessToken(''));

// console.log(getAccessToken(cookie.get('token')))
export const $AuthHeader = {
  Accept: 'application/json',
  authorization: `Bearer ${cookie.get('token')}`,
};

export const AxiosHost = axios.create({
  baseURL: $API_HOST,
  headers: $AuthHeader,
});
