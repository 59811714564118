import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { start } from 'repl';
import { getODPRD, getONDPRD } from '../../../services/apiFactory';

// ONDPRD - Order Not Delivered Previous Day
export const getONDPRDReport = createAsyncThunk(
  'finance-report/ONDPRD',
  async () => {
    return getONDPRD()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
// ODPRD - Order Delivered Previous Day
export const getODPRDReport = createAsyncThunk(
  'finance-report/ODPRD',
  async () => {
    return getODPRD()
      .then((res) => {
        if (res) {
          console.log(res.data);
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

const totalData = {
  dueToPaystackFromBitmarte: '',
  dueToMerchant: '',
  dueToBitmart: '',
  remitToBitmart: '',
  commissionPercentage: '',
  totalSalesOrderAmount: '',
  totalOrderAmount: '',
  totalDeliveryAmount: '',
};

interface ReportsInitialState {
  status: PromiseStatus;
  totalData: any;
  data: any[];
  error: string;
}

const reportsInitialState: ReportsInitialState = {
  status: 'idle',
  totalData,
  data: [],
  error: '',
};
interface InitialState {
  ondprdReports: ReportsInitialState;
  odprdReports: ReportsInitialState;
}

const initialState: InitialState = {
  ondprdReports: reportsInitialState,
  odprdReports: reportsInitialState,
};
const financeReportsSlice = createSlice({
  name: 'financeReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getONDPRDReport.pending, (state) => {
        state.ondprdReports.status = 'loading';
      })
      .addCase(getONDPRDReport.fulfilled, (state, { payload }) => {
        if (payload?.success === true) {
          state.ondprdReports.status = 'succeeded';
          state.ondprdReports.data = payload?.data?.data;
          state.ondprdReports.totalData = payload?.data?.totalData;
        } else {
          state.ondprdReports.status = 'failed';
          state.ondprdReports.error = payload;
        }
      })
      .addCase(getONDPRDReport.rejected, (state, { error }) => {
        state.ondprdReports.status = 'failed';
        state.ondprdReports.error =
          error.message || 'There was an error. Please try again';
      })
      .addCase(getODPRDReport.pending, (state) => {
        state.odprdReports.status = 'loading';
      })
      .addCase(getODPRDReport.fulfilled, (state, { payload }) => {
        if (payload?.success === true) {
          state.odprdReports.status = 'succeeded';
          state.odprdReports.data = payload?.data?.data;
          state.odprdReports.totalData = payload?.data?.totalData;
        } else {
          state.odprdReports.status = 'failed';
          state.odprdReports.error = payload;
        }
      })
      .addCase(getODPRDReport.rejected, (state, { error }) => {
        state.odprdReports.status = 'failed';
        state.odprdReports.error =
          error.message || 'There was an error. Please try again';
      });
  },
});

export const selectONDPRDReport = (state: any) =>
  state.financeReports.ondprdReports;
export const selectODPRDReport = (state: any) =>
  state.financeReports.odprdReports;
export default financeReportsSlice.reducer;
