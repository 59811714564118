import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllShippingPartnersOnboarded,
  addNewShippingPartnerFunc,
} from 'services/apiFactory';

export const getAllShippingPartners = createAsyncThunk(
  'admin/all/shipping-partners',
  async () => {
    return getAllShippingPartnersOnboarded(true)
      .then((res: any) => {
        console.log('res', res);
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export const addNewShippingPartner = createAsyncThunk(
  'admin/add/shipping-partners',
  async (payload: any) => {
    return addNewShippingPartnerFunc(payload)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((err: any) => {
        throw err.response.data;
      });
  }
);

export interface ShippingPartnerProps {
  id: number;
  name: string;
  logo: string;
  email: string;
  roleId: number;
  role: string;
  alternateEmail: string;
  password: string;
  address: string;
  phoneNumber: string;
  onboarded: boolean;
  alternatePhoneNumber: string;
  secondaryNumber: string;
  deliveryMode: {
    mode: string;
  }[];
  lastLogin: string;
  uniqueId: string;
  createdAt: string;
  updatedAt: string;
  ShippingCompany: {
    companyName: string;
  };
}

interface Permission {
  resourceId: number;
  canView: boolean;
  canAdd: boolean;
  canDelete: boolean;
  canUpdate: boolean;
}

interface ShippingPartnerPropsState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
  message: string;
  shippingPartnersList: ShippingPartnerProps[];
}

const initialState: ShippingPartnerPropsState = {
  status: 'idle',
  error: '',
  message: '',
  shippingPartnersList: [],
};

const getAllShippingPartnersSlice = createSlice({
  name: 'getAllShippingPartnersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllShippingPartners.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllShippingPartners.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.shippingPartnersList = payload.data;
      })
      .addCase(getAllShippingPartners.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message || 'Could not get orders. Try again later.';
      })
      .addCase(addNewShippingPartner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addNewShippingPartner.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.message = 'Partner has been added successfully.';
      })
      .addCase(addNewShippingPartner.rejected, (state, { error }) => {
        state.status = 'failed';
        state.message =
          error.message || 'Could not get orders. Try again later.';
      });
  },
});

export default getAllShippingPartnersSlice.reducer;
