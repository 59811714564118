import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getSalesActivityReport,
  getOrderStatuses,
} from '../../../services/apiFactory';

export const fetchOrderStatuses = createAsyncThunk(
  'merchantOrders/getStatuses',
  async () => {
    return getOrderStatuses('all')
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);

export const getSalesActivity = createAsyncThunk(
  'sales-report/sales',
  async () => {
    return getSalesActivityReport()
      .then((res) => {
        if (res) {
          const data = res.data;
          return data;
        }
      })
      .catch((err) => JSON.stringify(err.response));
  }
);
type PromiseStatus = 'idle' | 'loading' | 'succeeded' | 'failed';
interface SalesReport {
  salesReports: any[];
  orderStatus: any[];
  totalSalesAmount: number;
  totalDeliveryAmount: number;
  totalOrderAmount: number;
  totalOrders: number;
  status: PromiseStatus;
}

const initialState: SalesReport = {
  status: 'idle',
  orderStatus: [],
  totalSalesAmount: 0,
  totalDeliveryAmount: 0,
  totalOrderAmount: 0,
  totalOrders: 0,
  salesReports: [],
};

const salesAcitivityReportSlice = createSlice({
  name: 'salesActivityReport',
  initialState,
  reducers: {
    setTotalSalesAmount: (state, { payload }) => {
      state.totalSalesAmount = payload;
    },
    setTotalDeliveryAmount: (state, { payload }) => {
      state.totalDeliveryAmount = payload;
    },
    setTotalOrderAmount: (state, { payload }) => {
      state.totalOrderAmount = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalesActivity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSalesActivity.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const data = payload?.data?.orders;
        state.salesReports = data;
      })
      .addCase(getSalesActivity.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchOrderStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderStatuses.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        const data = payload.data;
        //  console.log(data)
        state.orderStatus = data && data;
      })
      .addCase(fetchOrderStatuses.rejected, (state) => {
        state.status = 'failed';
      });
  },
});
export const {
  setTotalSalesAmount,
  setTotalDeliveryAmount,
  setTotalOrderAmount,
} = salesAcitivityReportSlice.actions;

export default salesAcitivityReportSlice.reducer;
